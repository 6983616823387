import React, { CSSProperties, useRef } from 'react';
import { IconArrowDown } from '../../aurora/icons';
import { useHover } from 'react-native-web-hooks';
import baseTheme from '../../aurora/theme/baseTheme';
import {
  headerCellWithArrowStyle,
  tableHeaderRowCellStyle,
} from './tableStyle';
import { ColumnConfig, SortConfig } from './tableConfig';
import { useSx } from 'dripsy';

interface Props<T> {
  column: ColumnConfig<T>;
  sortConfig?: SortConfig<T>;
  showData: boolean;
  disabled?: boolean;
}
const HeaderRowCell = <T,>({
  column,
  sortConfig,
  showData,
  disabled,
}: Props<T>) => {
  const { title, property } = column;
  const shouldSort = showData && sortConfig !== undefined && !disabled;

  const sx = useSx();
  const ref = useRef(null);
  const isHovered = useHover(ref);

  const isSorted = property === sortConfig?.sortColumn;
  const showArrow = (isSorted || isHovered) && shouldSort;
  const isSortDirectionDown = sortConfig?.sortDirection === -1;
  const flipArrow: boolean = isSorted
    ? isHovered
      ? isSortDirectionDown
      : !isSortDirectionDown
    : false;

  const arrowColor: string = isHovered
    ? baseTheme.table.th.$hover.color
    : baseTheme.table.th.color;
  const cellWithArrowStyle: CSSProperties = {
    ...sx(headerCellWithArrowStyle),
    ...(shouldSort ? { cursor: 'pointer' } : { cursor: 'default' }),
  };

  const onSort = (columnKey: keyof T) => {
    if (!shouldSort) {
      return;
    }
    sortConfig?.onSortByColumn(columnKey);
  };

  return (
    <th
      style={sx(tableHeaderRowCellStyle)}
      onClick={() => onSort(property)}
      ref={ref}
    >
      <div style={cellWithArrowStyle}>
        {title}
        <div style={{ width: 14 }}>
          {showArrow ? (
            <IconArrowDown
              height={14}
              width={14}
              color={arrowColor}
              rotation={flipArrow ? 180 : undefined}
            />
          ) : null}
        </div>
      </div>
    </th>
  );
};

export default HeaderRowCell;
