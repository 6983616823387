import { AxiosInstance, AxiosResponse } from 'axios';
import { get } from '../hooks/useAxiosPrivate';
import { FeatureFlags } from '../context/FeatureFlagsProvider';

const FeatureFlagsApi = {
  getFeatureFlags: async (axios: AxiosInstance): Promise<FeatureFlags> => {
    const response: AxiosResponse<FeatureFlags> = await get(
      axios,
      '/api/v1/features'
    );
    return response.data;
  },
};

export default FeatureFlagsApi;
