import React from 'react';
import CostCalculatorFormSection from './common/CostCalculatorFormSection';
import PathTextArea from './common/PathTextArea';
import styles from './Form.module.scss';

const AdditionalInfoSection = () => {
  return (
    <CostCalculatorFormSection
      title="Considerations"
      subtitle={`Capture any additional notes or feedback regarding the cost calculations, which can include specific considerations, assumptions, or unique details pertinent to this scenario.`}
    >
      <PathTextArea
        label="Notes"
        path="considerations"
        placeholder={'Enter notes'}
        labelClassName={styles.label}
      />
    </CostCalculatorFormSection>
  );
};

export default AdditionalInfoSection;
