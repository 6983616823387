import React, { ComponentType, ReactNode } from 'react';
import { Text } from '../../aurora/typography/Text/Text';
import { SxProp, useSx } from 'dripsy';
import { IconProps } from '../../aurora/icons/IconBase';

interface Props {
  icon?: ComponentType<IconProps>;
  iconColors?: SxProp;
  title: string;
  body?: ReactNode;
  actions?: ReactNode;
}
const TableNotice = ({
  icon: Icon,
  title,
  body,
  iconColors,
  actions,
}: Props) => {
  const sx = useSx();

  const iconComponent = Icon ? (
    <div
      style={{
        ...sx({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '48px',
          width: '48px',
          borderRadius: '28px',
          margin: '0 auto 16px',
        }),
        ...sx(iconColors || {}),
      }}
    >
      <Icon width={24} height={24} />
    </div>
  ) : null;

  return (
    <div style={{ padding: '40px 32px' }}>
      {iconComponent}
      <Text
        size="lg"
        sx={{
          textAlign: 'center',
          color: 'gray600v1',
          fontWeight: '600',
          paddingBottom: '4px',
        }}
      >
        {title}
      </Text>
      <div style={{ textAlign: 'center' }}>{body}</div>
      {actions ? <div style={{ marginTop: '24px' }}>{actions}</div> : null}
    </div>
  );
};

export default TableNotice;
