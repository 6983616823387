import React from 'react';

import { IconBase, IconProps } from './IconBase';

export const IconCircle: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 12 12">
    <circle cx="6" cy="6" r="6" fill="currentColor" />
  </IconBase>
);
