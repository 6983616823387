import React from 'react';
import Slideout from '../../../aurora/components/Slideout/Slideout';
import { PcbTestEntity } from '../../../state/pcbTests/types';
import styles from './PcbTestSlideout.module.scss';

interface Props {
  pcbTest: PcbTestEntity | undefined;
  open: boolean;
  closeSlideout: () => void;
}
const PcbTestSlideout = ({ pcbTest, open, closeSlideout }: Props) => {
  if (pcbTest === undefined) {
    return null;
  }
  if (Object.keys(pcbTest.data).length === 0) {
    return <div>No data</div>;
  }

  const tests = Object.keys(pcbTest.data).filter((key) => key !== 'uart_logs');
  const logs = pcbTest.data['uart_logs'];

  return (
    <Slideout open={open} onClose={closeSlideout} title="PCB Test">
      {tests.map((key) => (
        <div key={key} className={styles.test}>
          <div>{key}</div>
          <div>{pcbTest.data[key]}</div>
        </div>
      ))}
      {logs === undefined ? null : (
        <div className={styles.logs}>
          <div>Logs:</div>
          <pre>{logs}</pre>
        </div>
      )}
    </Slideout>
  );
};

export default PcbTestSlideout;
