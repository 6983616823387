import React from 'react';
import ModalAction from '../../../../components/table/ModalAction';
import { IconEdit } from '../../../../aurora/icons';
import EditJourneyFormModal from '../journeyForm/EditJourneyFormModal';
import {
  JourneyEntity,
  JourneyTypeEnum,
  JourneyStatus,
} from '../../../../state/journeys/types';
import { loadJourneys } from '../../../../state/journeys';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import JourneysApi from '../../../../api/journeysApi';
import { editFormToBody, EditJourneyFormData } from '../journeyForm/mapData';
import { useSelector } from 'react-redux';
import { isArtyc, isCompanyAdmin, selectAuth } from '../../../../state/auth';
import { selectIsCaas } from '../../../../state/companies';
import { differenceInDays } from 'date-fns';

interface Props {
  journey: JourneyEntity;
  isArtycViewer: boolean;
}
const EditJourneyButton = ({ journey, isArtycViewer }: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const isCaas = useSelector(selectIsCaas);
  const isCompany = isCompanyAdmin(auth) && isCaas;
  const canEdit = isArtyc(auth) || isCompany;

  // customer can't edit after 7 days before expectedStartDate
  const withinLeadTime =
    isCompany && journey.expectedStartDate
      ? differenceInDays(new Date(journey.expectedStartDate), new Date()) >= 6
      : true;
  // it's okay for artyc to edit journeys that have started
  const notStarted = isCompany
    ? journey.status === JourneyStatus.PENDING
    : true;

  // currently we don't want to allow single leg journeys to be edited
  // those are created through segment upload and the edit UX doesn't make sense for them
  const hasMultiLegs = journey.totalLegs > 1;
  // can't edit carrier tracking if it's intra-campus
  const isTrackedJourneyType =
    journey.journeyType !== JourneyTypeEnum.INTRA_CAMPUS;

  const editable =
    isTrackedJourneyType && notStarted && withinLeadTime && hasMultiLegs;

  if (!canEdit || !editable) {
    return null;
  }

  return (
    <ModalAction
      label="Edit Journey"
      modalTitle="Update Journey Details"
      icon={IconEdit}
      stateName="journeys"
      modalContent={(onDataChange, onClose) => (
        <EditJourneyFormModal
          journey={journey}
          isArtycViewer={isArtycViewer}
          onSubmit={async (data: EditJourneyFormData) => {
            const body = editFormToBody(data);
            await JourneysApi.updateJourney(
              axiosPrivate,
              auth,
              journey._id,
              body
            );
            await onDataChange();
          }}
          onClose={onClose}
        />
      )}
      loadDataAction={loadJourneys}
      modalWidth="446px"
    />
  );
};

export default EditJourneyButton;
