import React from 'react';
import { Text } from '../../typography/Text/Text';

export interface InputHintProps {
  hint?: string;
  error?: boolean;
  disabled?: boolean;
}
const InputHint = ({ hint, error, disabled }: InputHintProps) => {
  if (hint === undefined) {
    return null;
  }

  return (
    <div>
      <Text size="xs" color={disabled ? 'disabled300' : 'sub600'} error={error}>
        {hint}
      </Text>
    </div>
  );
};

export default InputHint;
