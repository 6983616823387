import React from 'react';
import { IconSpinner } from '../../aurora/icons/IconSpinner';
import { IconProps } from '../../aurora/icons/IconBase';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner: React.FC<IconProps> = (props) => {
  return (
    <div className={styles.loadingSpinnerContainer}>
      <IconSpinner {...props} className={styles.loadingSpinner} />
    </div>
  );
};

export default LoadingSpinner;
