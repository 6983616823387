import React from 'react';
import { Text } from '../../../../../aurora/typography/Text/Text';

const Subheader = ({ title }: { title: string }) => (
  <Text
    size="lg"
    sx={{
      color: 'gray600',
      lineHeight: '20px',
      fontWeight: '600',
      marginBottom: '8px',
      marginLeft: '8px',
    }}
  >
    {title}
  </Text>
);

export default Subheader;
