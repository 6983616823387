import React, { ReactNode } from 'react';
import { IconLogo } from '../../aurora/icons';
import styles from './Splash.module.scss';

interface Props {
  card: string;
  subtitle?: ReactNode;
  icon?: ReactNode;
  iconType?: 'logo' | 'action';
  children: ReactNode;
}

const Splash = ({
  card,
  subtitle,
  children,
  icon,
  iconType = 'action',
}: Props) => {
  return (
    <div className={styles.splash}>
      <div
        className={
          iconType === 'logo' ? styles.logoContainer : styles.iconContainer
        }
      >
        {icon || <IconLogo width={116} height={32} />}
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.cardHeader}>
          <h1 className={styles.cardTitle}>{card}</h1>
          <h2 className={styles.cardSubtitle}>{subtitle}</h2>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Splash;
