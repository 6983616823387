import React, { ReactNode } from 'react';
import { createContext, useState } from 'react';

export type FeatureFlags = { [flag: string]: boolean };

export interface FeatureFlagContextType {
  featureFlags: FeatureFlags;
  setFeatureFlags: (flags: FeatureFlags) => void;
}

const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: {},
  setFeatureFlags: () => {},
});

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState({});

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, setFeatureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagContext;
