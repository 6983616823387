import React from 'react';
import {
  ColumnsConfig,
  EmptyConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import DataTableContainer from '../../components/table/DataTableContainer';
import PageHeader from '../../components/layout/PageHeader';
import { isArtyc, isCompanyEmployee, selectAuth } from '../../state/auth';
import { useDispatch, useSelector } from 'react-redux';
import { IconUser } from '../../aurora/icons';
import {
  loadUsers,
  setCurrentPage,
  sortUsers,
  setCompanyIdsFilter,
  setRoleIdFilter,
  resetPage,
} from '../../state/users';
import { UserEntity } from '../../state/users/types';
import UserEditButton from './components/UserEditButton';
import InviteUserButton from './components/InviteUserButton';
import { ROLES } from '../../state/auth/types';
import UserSearch from './components/UserSearch';
import CompanyFilter from '../../components/filters/CompanyFilter/CompanyFilter';
import { RootState } from '../../state/store';
import RoleSelect from './components/RoleSelect';
import styles from '../page.module.scss';
import MobileCompanyFilter from '../../components/filters/MobileChecklistFilter/MobileCompanyFilter';

const UsersPage = () => {
  const auth = useSelector(selectAuth);

  const dispatch = useDispatch();

  const { companyIds, rolePid } = useSelector(
    (state: RootState) => state.users.selectedFilters || {}
  );

  const topConfig: TopConfig = {
    additionalBars: [
      <>
        <UserSearch />
        <div className={styles.filterGroup}>
          <CompanyFilter
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <MobileCompanyFilter
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <RoleSelect
            onRoleSelect={(roleId) => dispatch(setRoleIdFilter(roleId))}
            selectedRole={rolePid}
          />
        </div>
      </>,
    ],
  };
  const emptyConfig: EmptyConfig = {
    icon: IconUser,
    title: 'No users found',
  };
  // TODO(test): test that company only shows for artyc admin, role has expected format
  const columnsConfig: ColumnsConfig<UserEntity> = {
    columns: [
      {
        title: 'First Name',
        property: 'firstName',
      },
      {
        title: 'Last Name',
        property: 'lastName',
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtyc(auth),
      },
      {
        title: 'Email',
        property: 'email',
      },
      {
        title: 'Role',
        property: 'role',
        customData: (entity) => {
          const matchingRole = Object.values(ROLES).filter(
            (role) => role.pid === entity.role
          );
          if (matchingRole.length > 0) {
            return matchingRole[0].name;
          }
          return '';
        },
      },
    ],
    actionColumn: (entity: UserEntity) => <UserEditButton user={entity} />,
  };

  if (isCompanyEmployee(auth)) {
    return null;
  }

  return (
    <>
      <PageHeader headingText="Users" action={<InviteUserButton />} />
      <DataTableContainer
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        sortAction={sortUsers}
        loadDataAction={loadUsers}
        pageAction={setCurrentPage}
        stateName="users"
        resetAction={resetPage}
      />
    </>
  );
};

export default UsersPage;
