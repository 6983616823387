import mapboxgl from 'mapbox-gl';
import { FeatureCollection, Feature, Point, GeoJsonProperties } from 'geojson';

export const calculateBoundingBox = (
  geojsonPoints: FeatureCollection<Point>
): [[number, number], [number, number]] => {
  return geojsonPoints.features.reduce(
    (
      acc: [[number, number], [number, number]],
      feature: Feature<Point>
    ): [[number, number], [number, number]] => {
      const [long, lat] = feature.geometry.coordinates;
      return [
        [Math.min(acc[0][0], long), Math.min(acc[0][1], lat)],
        [Math.max(acc[1][0], long), Math.max(acc[1][1], lat)],
      ];
    },
    [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ] as [[number, number], [number, number]]
  );
};

export const mergeBounds = (
  accBounds: [[number, number], [number, number]],
  bounds: [[number, number], [number, number]]
): [[number, number], [number, number]] => {
  return [
    [
      Math.min(accBounds[0][0], bounds[0][0]),
      Math.min(accBounds[0][1], bounds[0][1]),
    ],
    [
      Math.max(accBounds[1][0], bounds[1][0]),
      Math.max(accBounds[1][1], bounds[1][1]),
    ],
  ];
};

export const generateDatasetColors = (datasetIndex: number) => {
  const hueStart = (datasetIndex * 60) % 360;
  const hueEnd = (hueStart + 60) % 360;

  const startColor = `hsl(${hueStart}, 100%, 50%)`;
  const endColor = `hsl(${hueEnd}, 100%, 50%)`;

  return { startColor, endColor };
};

export const addGeoJSONSource = (
  map: mapboxgl.Map,
  sourceId: string,
  geojson: FeatureCollection<Point, GeoJsonProperties>
) => {
  // another reason to use geoJSON is that mapbox can easily add it as a data source
  map.addSource(sourceId, {
    type: 'geojson',
    data: geojson,
  });
};

export const addGeoJSONLayer = (
  map: mapboxgl.Map,
  layerId: string,
  sourceId: string,
  segmentLength: number,
  startColor: string,
  endColor: string
) => {
  // here mapbox can take the geoJSON source added and easily make a layer out of it.
  map.addLayer({
    id: layerId,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-radius': 10,
      'circle-color': [
        'interpolate',
        ['linear'],
        ['get', 'pointIndex'],
        0,
        startColor,
        // 1 when there's just one point, or else we end up in a circular loop
        segmentLength - 1 || 1,
        endColor,
      ],
    },
  });
};
