import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDataAction,
  EntityState,
  DataPayload,
  SortPayloadAction,
} from '../types';
import { AxiosInstance } from 'axios';
import { JourneyFilters, JourneyEntity } from './types';
import { structuredFetch } from '../../api/api';
import { Auth } from '../auth/types';
import { ShipmentEntity } from '../shipments/types';

export interface JourneyState extends EntityState<JourneyEntity> {
  selectedFilters?: JourneyFilters;
  viewAsCustomer: boolean;
  shipmentsByJourney: Record<
    JourneyEntity['_id'],
    ShipmentEntity[] | undefined
  >;
}

const initialState: JourneyState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'createdAt',
  sortDirection: -1,
  selectedFilters: {},
  viewAsCustomer: false,
  shipmentsByJourney: {},
};

const journeySlice = createSlice({
  name: 'journeys',
  initialState,
  reducers: {
    setJourneys: (
      state,
      { payload }: PayloadAction<DataPayload<JourneyEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    sortJourneys: (state, { payload }: SortPayloadAction<JourneyEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setDeviceTypesFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        deviceTypes: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setDateFilter: (
      state,
      { payload }: PayloadAction<[string | undefined, string | undefined]>
    ) => {
      const [startDate, endDate] = payload;
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        startDate,
        endDate,
      });
      state.currentPage = initialState.currentPage;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        searchQuery: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCompanyIdsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        companyIds: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.sortColumn = initialState.sortColumn;
      state.sortDirection = initialState.sortDirection;
      state.currentPage = initialState.currentPage;
    },
    toggleViewAsCustomer: (state) => {
      state.viewAsCustomer = !state.viewAsCustomer;
    },
    setShipmentsForJourney: (
      state,
      {
        payload,
      }: PayloadAction<{ journey: string; shipments: ShipmentEntity[] }>
    ) => {
      state.shipmentsByJourney = {
        ...state.shipmentsByJourney,
        [payload.journey]: payload.shipments,
      };
    },
  },
});

export const loadJourneys: LoadDataAction<JourneyEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: EntityState<JourneyEntity>
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;

  const { entities, totalPages } = await structuredFetch<JourneyEntity>(
    axios,
    auth,
    'journeys',
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters
  );

  return setJourneys({ entities, totalPages });
};

export const {
  setJourneys,
  sortJourneys,
  setDeviceTypesFilter,
  setSearchFilter,
  setCompanyIdsFilter,
  setDateFilter,
  setCurrentPage,
  resetPage,
  toggleViewAsCustomer,
  setShipmentsForJourney,
} = journeySlice.actions;

export default journeySlice.reducer;
