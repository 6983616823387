import React from 'react';
import { Path, G } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconFileOff: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <G id="Icon">
      <Path
        id="Icon_2"
        d="M19 4.875L18.1183 5.75666M5 18.875L5.8894 17.9856M15.675 4H11.2081C10.7354 4 10.2829 4.19121 9.95336 4.53011L6.37136 8.21445C6.05312 8.54179 5.8754 8.98053 5.87611 9.43706L5.88262 13.625M18.1183 5.75666C18.125 5.96856 18.125 6.21852 18.125 6.52V8.725L18.1103 18.175C18.092 18.4839 18.0508 18.703 17.9581 18.89C17.8113 19.1864 17.577 19.4273 17.2889 19.5783C16.9614 19.75 16.5326 19.75 15.675 19.75H8.325C7.46742 19.75 7.03863 19.75 6.71108 19.5783C6.42295 19.4273 6.1887 19.1864 6.0419 18.89C5.94924 18.703 5.90803 18.4839 5.88969 18.175L5.8894 17.9856M18.1183 5.75666L5.8894 17.9856"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </G>
  </IconBase>
);
