import React from 'react';
import PackagingCostsSection from './PackagingCostsSection';
import TrackingCostsSection from './TrackingCostsSection';
import WarehousingCostsSection from './WarehousingCostsSection';
import BloodDrawCostsSection from './BloodDrawCostsSection';
import ShippingSection from './ShippingSection';

const CustomerCostSection = () => {
  return (
    <>
      <PackagingCostsSection />
      <TrackingCostsSection />
      <WarehousingCostsSection />
      <BloodDrawCostsSection />
      <ShippingSection />
    </>
  );
};

export default CustomerCostSection;
