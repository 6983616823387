import React, { useState } from 'react';
import { SegmentEntity } from '../../../../../state/segments/types';
import { useSelector } from 'react-redux';
import SnapshotsGraph, { ExternalTempData } from './SnapshotsGraph';
import {
  ButtonGroup,
  ButtonProps,
} from '../../../../../aurora/components/ButtonGroup/ButtonGroup';
import ArtycPanel from './ArtycPanel';
import SegmentsGraph from './SegmentsGraph';
import {
  selectSnapshotsBySegment,
  SegmentToSnapshotsMap,
} from '../../../../../state/segments';
import styles from './SnapshotsGraphContainer.module.scss';
import GraphDropdown from './GraphDropdown';
import InfoCard from '../InfoCard';

interface Props {
  segments: SegmentEntity[];
  isArtycViewer: boolean;
}
const SnapshotsGraphContainer = ({ segments, isArtycViewer }: Props) => {
  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
  const [tempUnit, setTempUnit] = useState<'C' | 'F'>('C');

  const [tempThresholdsC, setTempThresholdsC] = useState<number[]>([]);
  const [minTempAxisC, setMinTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [maxTempAxisC, setMaxTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [showDateAsDuration, setShowDateAsDuration] = useState<boolean>(true);
  const [showLidOpen, setShowLidOpen] = useState<boolean>(false);
  const [externalTempData, setExternalTempData] = useState<ExternalTempData[]>(
    []
  );

  const loadedSnapshotsBySegment: SegmentToSnapshotsMap = segments.reduce(
    (acc, segment) => {
      if (snapshotsBySegment[segment._id]) {
        acc[segment._id] = snapshotsBySegment[segment._id];
      }
      return acc;
    },
    {} as SegmentToSnapshotsMap
  );

  const graph =
    segments.length === 1 ? (
      <SnapshotsGraph
        snapshots={loadedSnapshotsBySegment[segments[0]._id]}
        externalTempData={externalTempData}
        tempUnit={tempUnit}
        isArtycViewer={isArtycViewer}
        tempThresholdsC={tempThresholdsC}
        minTempAxisC={minTempAxisC}
        maxTempAxisC={maxTempAxisC}
        showLidOpen={showLidOpen}
        showDateAsDuration={showDateAsDuration}
      />
    ) : (
      <SegmentsGraph
        segments={segments}
        snapshotsMap={loadedSnapshotsBySegment}
        tempUnit={tempUnit}
        isArtycViewer={isArtycViewer}
        tempThresholdsC={tempThresholdsC}
        minTempAxisC={minTempAxisC}
        maxTempAxisC={maxTempAxisC}
      />
    );

  const temps: ButtonProps[] = [
    {
      label: '°C',
      onClick: () => setTempUnit('C'),
    },
    {
      label: '°F',
      onClick: () => setTempUnit('F'),
    },
  ];

  return (
    <InfoCard
      title="Temperature Data"
      headerAction={<ButtonGroup buttonProps={temps} initialActiveIdx={0} />}
    >
      <div className={styles.graphDropdown}>
        <GraphDropdown>
          <ArtycPanel
            isArtycViewer={isArtycViewer}
            segments={segments}
            tempThresholdsC={tempThresholdsC}
            setTempThresholdsC={setTempThresholdsC}
            setMinTempAxisC={setMinTempAxisC}
            setMaxTempAxisC={setMaxTempAxisC}
            showDateAsDuration={showDateAsDuration}
            setShowDateAsDuration={setShowDateAsDuration}
            showLidOpen={showLidOpen}
            setShowLidOpen={setShowLidOpen}
            tempUnit={tempUnit}
            setExternalTempData={setExternalTempData}
          />
        </GraphDropdown>
      </div>
      <div className={styles.graphPanel}>
        <div className={styles.graphButtons}></div>
        {graph}
      </div>
    </InfoCard>
  );
};

export default SnapshotsGraphContainer;
