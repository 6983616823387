import React, { CSSProperties } from 'react';
import { ColumnsConfig, SortConfig } from './tableConfig';
import { useSx } from 'dripsy';
import { tableHeaderRowCellStyle } from './tableStyle';
import Checkbox from '../../aurora/components/Checkbox/Checkbox';
import HeaderRowCell from './HeaderRowCell';

interface Props<T> {
  columnsConfig: ColumnsConfig<T>;
  sortConfig?: SortConfig<T>;
  onSelectAll: (checked: boolean) => void;
  allSelected: boolean;
  showData: boolean;
}

const DataTableHeaderRow = <T,>({
  columnsConfig,
  sortConfig,
  onSelectAll,
  allSelected,
  showData,
}: Props<T>) => {
  const { columns, actionColumn, bulkAction } = columnsConfig;
  const sx = useSx();

  const cellStyle: CSSProperties = sx(tableHeaderRowCellStyle);

  const rowData = columns
    .filter((column) => {
      const { showColumn } = column;
      if (showColumn === undefined) {
        return true;
      }
      return showColumn;
    })
    .map((column, i) => (
      <HeaderRowCell
        key={`header-row-cell-${i}`}
        disabled={column.disableSort}
        column={column}
        sortConfig={sortConfig}
        showData={showData}
      />
    ));

  const row = [
    ...(bulkAction
      ? [
          <td style={cellStyle} key="bulk">
            <Checkbox
              onChange={onSelectAll}
              managedChecked={allSelected}
              disabled={!showData}
            />
          </td>,
        ]
      : []),
    ...rowData,
    ...(actionColumn ? [<th key="action" style={cellStyle} />] : []),
  ];

  return <tr>{row}</tr>;
};

export default DataTableHeaderRow;
