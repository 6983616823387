import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconPlus: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase {...props}>
    <Path
      d="M12 4V20M20 12L4 12"
      stroke="currentColor"
      strokeWidth={props.strokeWidth || '2'}
      strokeLinecap="round"
    />
  </IconBase>
);
