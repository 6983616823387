import React from 'react';
import { SegmentEntity } from '../../../../state/segments/types';
import TestingMap from '../../../../components/maps/TestingMap';
import { useSelector } from 'react-redux';
import {
  selectSnapshotsBySegment,
  SegmentToSnapshotsMap,
} from '../../../../state/segments';

interface Props {
  segments: SegmentEntity[];
  isArtycViewer: boolean;
}
const SnapshotsMapContainer = ({ segments, isArtycViewer }: Props) => {
  const hasGeoData = segments.some((segment) => segment.hasGeoData);
  if (!hasGeoData || !isArtycViewer) {
    return null;
  }

  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);

  const filteredSnapshotsMap = segments.reduce((acc, segment) => {
    if (snapshotsBySegment[segment._id]) {
      acc[segment._id] = snapshotsBySegment[segment._id];
    }
    return acc;
  }, {} as SegmentToSnapshotsMap);

  return <TestingMap segments={filteredSnapshotsMap} />;
};

export default SnapshotsMapContainer;
