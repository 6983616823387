import React from 'react';
import { ShipmentEntity } from '../../../../state/shipments/types';
import styles from './JourneyPageContainer.module.scss';
import CarrierTrackingCard from './leftColumn/CarrierTrackingCard';

interface Props {
  shipment: ShipmentEntity;
  isArtycViewer: boolean;
}
const UnregulatedShipmentContainer = ({ shipment }: Props) => {
  return (
    <div className={styles.container}>
      <CarrierTrackingCard shipment={shipment} />
    </div>
  );
};

export default UnregulatedShipmentContainer;
