import React, { ReactNode } from 'react';
import { useSx } from 'dripsy';
import { Text } from '../../../../../../aurora/typography/Text/Text';

export interface CostCalculatorFormSectionProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

const CostCalculatorFormSection: React.FC<CostCalculatorFormSectionProps> = ({
  title,
  subtitle,
  children,
}: CostCalculatorFormSectionProps) => {
  const sx = useSx();

  return (
    <div
      style={sx({
        paddingTop: '16px',
        paddingBottom: '24px',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'neutral700',
      })}
    >
      {title && (
        <Text
          size={'md'}
          sx={{
            fontWeight: '600',
            marginBottom: subtitle === undefined ? '16px' : '4px',
          }}
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text
          size={'xs'}
          sx={{
            fontWeight: '400',
            color: 'gray50v1',
            marginBottom: '16px',
          }}
        >
          {subtitle}
        </Text>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CostCalculatorFormSection;
