import React, { useState, ComponentType, useEffect } from 'react';
import { IconChevronDown } from '../../icons/IconChevronDown';
import { IconChevronUp } from '../../icons/IconChevronUp';
import { Flex, Pressable, Text, useDripsyTheme } from 'dripsy';
import { useSx } from 'dripsy';
import { IconClose } from '../../icons';
import {
  tagStyles,
  placeholderStyles,
  filterInputStyles,
} from '../Filter/filterStyles';
import Slideout from '../Slideout/Slideout';
import styles from './MobileFilter.module.scss';
import { Button } from '../Button/Button';
import { BaseTheme } from '../../theme/baseTheme';

export interface SlideoutContentProps<FilterType> {
  selectedFilters: FilterType;
  onSetFilters: React.Dispatch<React.SetStateAction<FilterType>>;
}

export interface MobileFilterProps<FilterType> {
  variant?: keyof BaseTheme['select'];
  emptyFilters: FilterType;
  appliedFilters: FilterType;
  placeholder: string;
  showPlaceholderFiltered?: boolean;
  slideoutContent: ComponentType<SlideoutContentProps<FilterType>>;
  onApplyFilters: (filter: FilterType) => void;
  onClear: () => void;
  toFiltersSummary: (filter: FilterType) => string;
}
const MobileFilter = <FilterType,>({
  variant = 'filter',
  emptyFilters,
  appliedFilters,
  placeholder,
  showPlaceholderFiltered = true,
  slideoutContent: SlideoutContent,
  onApplyFilters,
  onClear,
  toFiltersSummary,
}: MobileFilterProps<FilterType>) => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const variantSx = theme.select[variant];

  const [open, setOpen] = useState<boolean>(false);
  const [filtersSummary, setFiltersSummary] = useState<null | string>(null);
  // slideout content only affects the filters when onApply is called in the footer
  // so we keep a copy of the selected filters here as a proxy
  const [selectedFilters, setSelectedFilters] =
    useState<FilterType>(emptyFilters);

  // if we close it, reset selected filters to current state
  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [open]);

  const isFiltered = filtersSummary !== null;
  const Chevron = open ? IconChevronUp : IconChevronDown;
  const borderColor = open
    ? variantSx.$active.borderColor
    : variantSx.borderColor;
  const textColor = isFiltered ? 'gray50v1' : 'gray600v1';

  const submitSlideout = () => {
    // assume we're actually clearing if it's empty
    if (JSON.stringify(selectedFilters) === JSON.stringify(emptyFilters)) {
      clearSlideout();
    } else {
      const summary = toFiltersSummary(selectedFilters);
      setFiltersSummary(summary);
      setOpen(false);
      onApplyFilters(selectedFilters);
    }
  };

  const clearSlideout = () => {
    setFiltersSummary(null);
    setSelectedFilters(emptyFilters);
    setOpen(false);
    onClear();
  };

  const slideoutFooter = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        width: '100%',
      }}
    >
      <Button
        label="Clear All"
        variant="ghostPrimary"
        onClick={clearSlideout}
        sx={{ flex: 1 }}
      />
      <Button
        label="Apply"
        variant="primary"
        onClick={submitSlideout}
        sx={{ flex: 1 }}
      />
    </div>
  );

  const summaryTag = isFiltered ? (
    <Flex sx={{ ...tagStyles, ...variantSx.$summary }}>
      <Text sx={{ color: variantSx.$summary.color }}>{filtersSummary}</Text>
      <Pressable
        onPress={clearSlideout}
        sx={{ color: variantSx.$summary.color }}
      >
        <IconClose width={12} height={12} strokeWidth={3} />
      </Pressable>
    </Flex>
  ) : null;

  // if !showPlaceholderFiltered, don't show placeholder when filter is set
  const placeholderComponent =
    isFiltered && !showPlaceholderFiltered ? null : (
      <Text
        sx={{
          ...placeholderStyles,
          color: textColor,
        }}
      >
        {placeholder}
      </Text>
    );

  return (
    <div className={styles.filter}>
      <div
        onClick={() => setOpen(!open)}
        style={sx({
          ...filterInputStyles,
          borderColor,
          color: textColor,
        })}
      >
        {summaryTag}
        {placeholderComponent}
        <Chevron width={20} height={20} />
      </div>
      <Slideout
        title={placeholder}
        open={open}
        onClose={() => setOpen(false)}
        footer={slideoutFooter}
      >
        <SlideoutContent
          selectedFilters={selectedFilters}
          onSetFilters={setSelectedFilters}
        />
      </Slideout>
    </div>
  );
};

export default MobileFilter;
