import React, { CSSProperties } from 'react';
import { useSx, SxProp } from 'dripsy';

interface NavBadgeProps {
  text: string;
  isActive?: boolean;
}

const baseStyle: SxProp = {
  display: 'flex',
  fontSize: '12px',
  fontWeight: '500',
  alignItems: 'center',
  backgroundColor: 'white',
  borderColor: 'neutral700',
  borderWidth: '1px',
  borderStyle: 'solid',
  lineHeight: '20px',
  color: 'gray700',
  marginLeft: 'auto',
  borderRadius: '50%',
  boxSizing: 'border-box',
  height: '20px',
  maxWidth: '100px',
  overflow: 'hidden',
};

const activeStyle: SxProp = {
  backgroundColor: 'primary600',
  color: 'white',
  borderColor: 'primary600',
};

const NavBadge: React.FC<NavBadgeProps> = ({
  text,
  isActive,
}: NavBadgeProps) => {
  const sx = useSx();

  const isShortText = text.length <= 2;
  const badgeStyle: SxProp = {
    ...baseStyle,
    ...(isActive ? activeStyle : {}),
    justifyContent: isShortText ? 'center' : 'flex-start',
    width: isShortText ? '20px' : 'auto',
    borderRadius: isShortText ? '50%' : '12px',
  };
  const textStyle: CSSProperties = {
    margin: isShortText ? '0' : '4px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  return (
    <div style={sx(badgeStyle)}>
      <span style={textStyle}>{text}</span>
    </div>
  );
};

export default NavBadge;
