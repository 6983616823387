import React from 'react';
import { SegmentSnapshotEntity } from '../../../../../state/segments/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  TimeScale,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { htmlLegendPlugin } from './Legend';
import { convertTemp, mkChartOptions, mkDatapoint } from './chartOptions';
import 'chartjs-adapter-date-fns';
import { genColorHex } from './colorUtils';

export interface ExternalTempDatapoint {
  tempC: number;
  timestamp: number;
}

export interface ExternalTempData {
  label: string;
  offsetMinutes: number;
  datapoints: ExternalTempDatapoint[];
}

interface Props {
  snapshots: SegmentSnapshotEntity[];
  externalTempData: ExternalTempData[];
  tempUnit: 'C' | 'F';
  isArtycViewer: boolean;
  tempThresholdsC?: number[];
  minTempAxisC?: number;
  maxTempAxisC?: number;
  showLidOpen: boolean;
  showDateAsDuration: boolean;
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin
);

const SnapshotsGraph = ({
  snapshots,
  externalTempData,
  tempUnit,
  isArtycViewer,
  tempThresholdsC,
  minTempAxisC,
  maxTempAxisC,
  showLidOpen,
  showDateAsDuration,
}: Props) => {
  const startDate = new Date(snapshots[0]?.timestamp);
  const endDate = new Date(snapshots[snapshots.length - 1]?.timestamp);

  const lidOpens = [];
  if (showLidOpen) {
    let lidOpenStart: number | undefined = undefined;
    for (let i = 0; i < snapshots.length; i++) {
      const snapshot = snapshots[i];
      if (snapshot.lidOpen && lidOpenStart === undefined) {
        lidOpenStart = new Date(snapshot.timestamp).valueOf();
      }

      if (
        // last snapshot or lid closed
        (!snapshot.lidOpen || i === snapshots.length - 1) &&
        lidOpenStart !== undefined
      ) {
        lidOpens.push({
          start: lidOpenStart,
          end: new Date(snapshot.timestamp).valueOf(),
        });
        lidOpenStart = undefined;
      }
    }
  }

  const options = mkChartOptions(
    showDateAsDuration ? 'timeDuration' : 'time',
    tempUnit,
    tempThresholdsC,
    lidOpens,
    minTempAxisC,
    maxTempAxisC,
    startDate
  );

  const data: ChartData<'line'> = {
    datasets: [
      {
        label: `${isArtycViewer ? 'Heat Sink' : 'Ambient'} (°${tempUnit})`,
        data: snapshots.map((snapshot) =>
          mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.hs))
        ),
        borderColor: '#eea23e',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: `${isArtycViewer ? 'Sleeve' : 'Internal'} (°${tempUnit})`,
        data: snapshots.map((snapshot) =>
          mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.sleeve))
        ),
        borderColor: '#0c77ff',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: 'Battery (%)',
        data: snapshots.map((snapshot) => mkDatapoint(snapshot, snapshot.soc)),
        borderColor: '#5a6473',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      ...(isArtycViewer
        ? [
            {
              label: `Evap (°${tempUnit})`,
              data: snapshots.map((snapshot) =>
                mkDatapoint(
                  snapshot,
                  convertTemp(tempUnit)(snapshot.temps.evap)
                )
              ),
              borderColor: '#7c31c6',
              yAxisID: 'temperature',
              pointRadius: 0,
              pointHoverRadius: 0,
            },
            {
              label: 'Output (V)',
              data: snapshots.map((snapshot) =>
                mkDatapoint(snapshot, parseFloat(snapshot.outputV))
              ),
              borderColor: '#29a63b',
              yAxisID: 'voltage',
              pointRadius: 0,
              pointHoverRadius: 0,
            },
          ]
        : []),
      ...externalTempData
        // we want to align the external data's timestamps with our segment data's
        // so we want to ensure there are actual timestamps to do offset calculations on
        .filter((data) => data.datapoints.length > 0 && snapshots.length > 0)
        .map((data, i) => {
          const startTime = startDate.valueOf();
          const endTime = endDate.valueOf();
          const offset = startTime - data.datapoints[0].timestamp;

          return {
            label: data.label,
            data: data.datapoints
              // narrow down datapoints to fit within our segment graph
              .filter((point) => {
                const timestamp =
                  point.timestamp + offset + data.offsetMinutes * 60 * 1000;
                return timestamp <= endTime && timestamp >= startTime;
              })
              .map((point) => ({
                x: point.timestamp + offset + data.offsetMinutes * 60 * 1000,
                y: convertTemp(tempUnit)(point.tempC),
              })),
            borderColor: genColorHex(i),
            yAxisID: 'temperature',
            pointRadius: 0,
            pointHoverRadius: 0,
          };
        }),
    ],
  };

  return (
    <>
      <div
        id="legend-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '12px',
        }}
      ></div>
      <Line options={options} data={data} plugins={[htmlLegendPlugin]} />
    </>
  );
};

export default SnapshotsGraph;
