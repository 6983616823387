import React from 'react';
import CostCalculatorForm from './form/CostCalculatorForm';
import CostCalculatorSummaryContainer from './summary/CostCalculatorSummaryContainer';

interface Props {
  adminView: boolean;
}

const CostCalculatorContainer = ({ adminView }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        paddingLeft: '32px',
        paddingRight: '32px',
        background: 'white',
      }}
    >
      <div
        style={{
          flex: 1,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <CostCalculatorForm adminView={adminView} />
      </div>
      <div
        style={{
          overflowY: 'auto',
          height: '100%',
          paddingTop: '32px',
          paddingBottom: '32px',
        }}
      >
        <CostCalculatorSummaryContainer />
      </div>
    </div>
  );
};

export default CostCalculatorContainer;
