import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconArrowRight: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M5 12H18.004M13.0025 6L18.297 11.2929C18.6877 11.6834 18.6877 12.3166 18.297 12.7071L13.0025 18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
