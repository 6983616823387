import React, { ReactNode } from 'react';
import NavBreadcrumbs from './NavBreadCrumbs';
import styles from './PageHeader.module.scss';
import { Label } from '../../aurora/typography/Label/Label';

type Props = {
  headingText: string;
  action?: ReactNode;
};

const PageHeader: React.FC<Props> = ({ headingText, action }) => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.breadcrumbsContainer}>
        <NavBreadcrumbs />
      </div>
      <div className={styles.heading}>
        <Label size="lg" color="strong900" className={styles.headingText}>
          {headingText}
        </Label>
        <div>{action}</div>
      </div>
    </div>
  );
};

export default PageHeader;
