import React, { ReactNode } from 'react';
import { DripsyFinalTheme, DripsyProvider } from 'dripsy';
import baseTheme from './baseTheme';

interface Props {
  children: ReactNode;
  theme?: DripsyFinalTheme;
}

export const AuroraProvider = ({ children, theme }: Props) => {
  const setTheme = theme ?? baseTheme;
  return <DripsyProvider theme={setTheme}>{children}</DripsyProvider>;
};
