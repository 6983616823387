import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconGraph: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <Path
      d="M18 17.675C18.4832 17.675 18.875 17.2832 18.875 16.8C18.875 16.3168 18.4832 15.925 18 15.925V17.675ZM2.875 4C2.875 3.51675 2.48325 3.125 2 3.125C1.51675 3.125 1.125 3.51675 1.125 4H2.875ZM3.74723 16.4512L4.14447 15.6716L3.74723 16.4512ZM2.34878 15.0528L1.56915 15.45L2.34878 15.0528ZM9.48284 8.28284L10.1016 7.66412L9.48284 8.28284ZM12.1172 10.9172L11.4984 11.5359L12.1172 10.9172ZM12.6828 10.9172L13.3016 11.5359L13.3016 11.5359L12.6828 10.9172ZM17.8187 7.01872C18.1604 6.67701 18.1604 6.12299 17.8187 5.78128C17.477 5.43957 16.923 5.43957 16.5813 5.78128L17.8187 7.01872ZM4.58128 11.3813C4.23957 11.723 4.23957 12.277 4.58128 12.6187C4.92299 12.9604 5.47701 12.9604 5.81872 12.6187L4.58128 11.3813ZM8.91716 8.28284L9.53588 8.90156L9.53588 8.90156L8.91716 8.28284ZM18 15.925H7.12V17.675H18V15.925ZM2.875 11.68V4H1.125V11.68H2.875ZM7.12 15.925C6.20948 15.925 5.57921 15.9243 5.0895 15.8843C4.61 15.8451 4.34282 15.7727 4.14447 15.6716L3.34999 17.2309C3.83616 17.4786 4.35928 17.5805 4.94699 17.6285C5.5245 17.6757 6.23835 17.675 7.12 17.675V15.925ZM1.125 11.68C1.125 12.5616 1.12432 13.2755 1.1715 13.853C1.21952 14.4407 1.32143 14.9638 1.56915 15.45L3.12841 14.6555C3.02735 14.4572 2.95487 14.19 2.91569 13.7105C2.87568 13.2208 2.875 12.5905 2.875 11.68H1.125ZM4.14447 15.6716C3.707 15.4487 3.35132 15.093 3.12841 14.6555L1.56915 15.45C1.95983 16.2168 2.58323 16.8402 3.34999 17.2309L4.14447 15.6716ZM8.86412 8.90156L11.4984 11.5359L12.7359 10.2984L10.1016 7.66412L8.86412 8.90156ZM13.3016 11.5359L17.8187 7.01872L16.5813 5.78128L12.0641 10.2984L13.3016 11.5359ZM5.81872 12.6187L9.53588 8.90156L8.29844 7.66412L4.58128 11.3813L5.81872 12.6187ZM11.4984 11.5359C11.9964 12.0338 12.8036 12.0338 13.3016 11.5359L12.0641 10.2984C12.2496 10.1129 12.5504 10.1129 12.7359 10.2984L11.4984 11.5359ZM10.1016 7.66412C9.60364 7.16621 8.79636 7.1662 8.29844 7.66412L9.53588 8.90156C9.35038 9.08706 9.04962 9.08706 8.86412 8.90156L10.1016 7.66412Z"
      fill="currentColor"
    />
  </IconBase>
);
