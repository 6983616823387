import React, { ComponentType } from 'react';
import RNToast, { ToastConfigParams } from 'react-native-toast-message';
import { IconProps } from '../../icons/IconBase';
import {
  IconCircleAlert,
  IconCircleCheck,
  IconCircleInfo,
  IconLoading,
  IconClose,
} from '../../icons';
import { Flex, Pressable, Text } from 'dripsy';
import styles from './Toast.module.scss';

export type ToastType = 'success' | 'loading' | 'error' | 'info';
export interface ToastProps {
  type: ToastType;
  title?: string;
  text?: string;
  icon?: ComponentType<IconProps>;
}

const defaultIcon: Record<ToastType, ComponentType<IconProps>> = {
  success: IconCircleCheck,
  loading: IconLoading,
  error: IconCircleAlert,
  info: IconCircleInfo,
};

export const showToast = ({ title, text, type, icon }: ToastProps) => {
  return RNToast.show({
    type,
    text1: title,
    text2: text,
    props: {
      icon,
    },
  });
};

const Toast = ({
  type,
  text1,
  text2,
  props,
}: ToastConfigParams<{ icon?: ComponentType<IconProps> }>) => {
  const toastType = type as ToastType;
  const Icon = props.icon ?? defaultIcon[toastType];
  const toastTypeStyle =
    toastType === 'success'
      ? styles.success
      : toastType === 'error'
      ? styles.error
      : styles.info;

  return (
    <div className={`${styles.toast} ${toastTypeStyle}`}>
      <Flex>
        {Icon && (
          <div className={styles.toastIcon}>
            <Icon width={20} height={20} />
          </div>
        )}
        <div className={styles.toastBody}>
          <Text
            sx={{
              color: 'inherit',
              fontWeight: '600',
              marginBottom: '5px',
              fontSize: '16px',
            }}
          >
            {text1}
          </Text>
          <Text
            sx={{
              color: 'gray50v1',
              fontWeight: '500',
              fontSize: '14px',
            }}
          >
            {text2}
          </Text>
        </div>
        <Pressable onPress={() => RNToast.hide()} sx={{ color: 'gray50v1' }}>
          <IconClose width={20} height={20} />
        </Pressable>
      </Flex>
    </div>
  );
};

export default Toast;
