import React from 'react';
import { ViewAsCustomerToggle } from '../ViewAsCustomerToggle';
import { useSelector } from 'react-redux';
import { isArtyc, isCompanyAdmin, selectAuth } from '../../../../state/auth';
import { Button } from '../../../../aurora/components/Button/Button';
import { IconPlus } from '../../../../aurora/icons';
import styles from './JourneysPageActions.module.scss';
import { selectIsCaas } from '../../../../state/companies';

interface Props {
  openCreateSlideout: () => void;
}
const JourneysPageActions = ({ openCreateSlideout }: Props) => {
  const auth = useSelector(selectAuth);
  const isCaas = useSelector(selectIsCaas);
  const canCreate = isArtyc(auth) || (isCompanyAdmin(auth) && isCaas);

  return (
    <div className={styles.row}>
      <ViewAsCustomerToggle />
      {canCreate ? (
        <Button
          variant="ghostPrimary"
          label="Create New Journey"
          iconLeft={IconPlus}
          onClick={openCreateSlideout}
        />
      ) : null}
    </div>
  );
};

export default JourneysPageActions;
