import React from 'react';
import { TopConfig } from './tableConfig';
import styles from './DataTableTopBar.module.scss';
interface Props {
  topConfig: TopConfig;
}
const DataTableTopBar = ({ topConfig }: Props) => {
  const { additionalBars } = topConfig;

  return (
    <>
      {additionalBars?.map((bar, i) => (
        <div className={`${styles.tableHeader} `} key={`top-bar-${i}`}>
          {bar}
        </div>
      ))}
    </>
  );
};

export default DataTableTopBar;
