import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconDownload: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 14 16" {...props}>
    <Path
      d="M13.125 15.875C13.6099 15.875 14 15.4988 14 15.0312C14 14.5637 13.6099 14.1875 13.125 14.1875H0.875C0.390104 14.1875 0 14.5637 0 15.0312C0 15.4988 0.390104 15.875 0.875 15.875H13.125ZM6.36198 11.1078C6.52604 11.2766 6.75937 11.375 7 11.375C7.24062 11.375 7.47031 11.2801 7.63802 11.1078L12.3047 6.32656C12.6365 5.98555 12.6182 5.45469 12.2682 5.13477C11.9182 4.81484 11.3641 4.83242 11.0323 5.16992L7.875 8.4043V5.46875V0.96875C7.875 0.501172 7.4849 0.125 7 0.125C6.5151 0.125 6.125 0.501172 6.125 0.96875V5.46875V8.4043L2.97135 5.17344C2.63958 4.83242 2.08542 4.81836 1.73542 5.13828C1.38542 5.4582 1.36719 5.99258 1.69896 6.33008L6.36562 11.1113L6.36198 11.1078Z"
      fill="currentColor"
    />
  </IconBase>
);
