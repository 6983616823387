import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconLock: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase viewBox="0 0 19 23" {...props}>
    <Path
      d="M4.5 8V5.66667C4.5 3.08934 6.58934 1 9.16667 1C11.744 1 13.8333 3.08934 13.8333 5.66667V8M4.73333 22H13.6C14.9068 22 15.5602 22 16.0593 21.7457C16.4984 21.522 16.8553 21.165 17.079 20.726C17.3333 20.2269 17.3333 19.5735 17.3333 18.2667V11.7333C17.3333 10.4265 17.3333 9.77315 17.079 9.27402C16.8553 8.83498 16.4984 8.47802 16.0593 8.25432C15.5602 8 14.9068 8 13.6 8H4.73333C3.42654 8 2.77315 8 2.27402 8.25432C1.83498 8.47802 1.47802 8.83498 1.25432 9.27402C1 9.77315 1 10.4265 1 11.7333V18.2667C1 19.5735 1 20.2269 1.25432 20.726C1.47802 21.165 1.83498 21.522 2.27402 21.7457C2.77315 22 3.42654 22 4.73333 22Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
