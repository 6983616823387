import React from 'react';
import { setSearchFilter } from '../../../state/events';
import { useDispatch } from 'react-redux';
import TableSearch from '../../../aurora/components/TableSearch/TableSearch';

const EventsSearch = () => {
  const dispatch = useDispatch();

  return (
    <TableSearch
      placeholder="Search by event/shipment ID, serial #"
      onChange={(text) => {
        dispatch(setSearchFilter(text));
      }}
    />
  );
};

export default EventsSearch;
