import { useSx } from 'dripsy';
import React, { useRef } from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import { IconPlus } from '../../../aurora/icons';
import styles from './cardStyle.module.scss';

interface Props {
  onCreate: () => void;
}
const CreateProfileCard = ({ onCreate }: Props) => {
  const sx = useSx();
  const cardRef = useRef(null);

  return (
    <div
      className={styles.createCardContainer}
      onClick={onCreate}
      ref={cardRef}
    >
      <div className={styles.createCardContent}>
        <IconPlus
          width={19}
          height={19}
          strokeWidth={4}
          style={sx({
            backgroundColor: 'primary600',
            color: 'white',
            borderRadius: 11,
            padding: '3px',
          })}
        />
        <Text size="lg" className={styles.cardText}>
          Create Profile
        </Text>
      </div>
    </div>
  );
};

export default CreateProfileCard;
