import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { useDripsyTheme, useSx } from 'dripsy';
import { Text } from '../../typography/Text/Text';
import {
  useFloating,
  shift,
  autoUpdate,
  autoPlacement,
  offset,
  Placement,
  useHover,
  useInteractions,
} from '@floating-ui/react';

interface Props {
  label: ReactNode;
  placement?: Placement;
}

export const Tooltip = ({
  children,
  label,
  placement,
}: PropsWithChildren<Props>) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();
  const tooltipSx = sx(theme.tooltip);

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    middleware: [
      shift(),
      offset(8),
      ...(placement === undefined ? [autoPlacement()] : []),
    ],
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <div style={{ position: 'relative' }}>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          style={{
            zIndex: 99,
            borderRadius: 4,
            padding: 8,
            ...tooltipSx,
            ...floatingStyles,
          }}
          {...getFloatingProps()}
        >
          <Text size="xs" sx={{ color: tooltipSx.color, minWidth: '150px' }}>
            {label}
          </Text>
        </div>
      ) : null}
      <div
        ref={refs.setReference}
        style={{ width: 'fit-content' }}
        {...getReferenceProps()}
      >
        {children}
      </div>
    </div>
  );
};
