import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconSettings: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M8.51559 3.7583C8.51559 2.78722 9.30265 2 10.2735 2H12.7564C13.7273 2 14.5144 2.78722 14.5144 3.75831C14.5144 5.11186 15.9793 5.95781 17.1513 5.28104C17.9921 4.79548 19.0673 5.08362 19.5528 5.92462L20.7942 8.07525C21.2796 8.91625 20.9915 9.99162 20.1507 10.4772C18.9787 11.154 18.9787 12.8459 20.1507 13.5227C20.9915 14.0082 21.2796 15.0836 20.7942 15.9246L19.5528 18.0752C19.0673 18.9162 17.9922 19.2044 17.1513 18.7188C15.9793 18.0421 14.5144 18.8881 14.5144 20.2416C14.5144 21.2127 13.7273 22 12.7564 22H10.2736C9.30267 22 8.51559 21.2127 8.51559 20.2416C8.51559 18.8881 7.05063 18.0421 5.87865 18.7188C5.03783 19.2044 3.96268 18.9162 3.47724 18.0753L2.23582 15.9246C1.75037 15.0836 2.03845 14.0082 2.87928 13.5227C4.05126 12.8459 4.05126 11.154 2.87928 10.4772C2.03846 9.99162 1.75037 8.91624 2.23582 8.07525L3.47724 5.9246C3.96268 5.08361 5.03785 4.79547 5.87866 5.28102C7.05062 5.95779 8.51559 5.11184 8.51559 3.7583Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <Path
      d="M15.5142 12C15.5142 14.2091 13.7237 16 11.515 16C9.30629 16 7.5158 14.2091 7.5158 12C7.5158 9.79086 9.30629 8 11.515 8C13.7237 8 15.5142 9.79086 15.5142 12Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </IconBase>
);
