import React from 'react';
import TextArea from '../../../../../../aurora/components/TextArea/TextArea';
import { updateFormInputs } from '../../../../../../state/customerCostReports';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import {
  FormInputs,
  FormInputPaths,
} from '../../../../../../state/customerCostReports/types';

const getValueByPath = (object: FormInputs, path: FormInputPaths): any => {
  return path
    .split('.')
    .reduce((acc: any, part: string) => acc?.[part], object);
};

interface PathTextAreaProps {
  label: string;
  path: FormInputPaths;
  placeholder: string;
  labelClassName?: string;
}

const PathTextArea = ({
  label,
  path,
  placeholder,
  labelClassName,
}: PathTextAreaProps) => {
  const dispatch = useDispatch();
  const formInputs = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );

  const value = getValueByPath(formInputs, path) || '';

  const handleChange = (value: string) => {
    dispatch(updateFormInputs({ path, value }));
  };

  return (
    <TextArea
      label={label}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      labelClassName={labelClassName}
    />
  );
};

export default PathTextArea;
