import React from 'react';
import Splash from '../../components/layout/Splash';
import { IconCircleCheck } from '../../aurora/icons';
import { Button } from '../../aurora/components/Button/Button';
import styles from '../../components/layout/Splash.module.scss';
import { useNavigate } from 'react-router-dom';

const PasswordResetConfirmationPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/portal/home');
  };

  return (
    <Splash
      card="Password reset"
      subtitle="Your password has been successfully reset. Click below to log in."
      icon={<IconCircleCheck width={28} height={28} />}
    >
      <div className={styles.form}>
        <Button
          variant="primary"
          className={styles.formButton}
          onClick={handleButtonClick}
          label={'Continue to dashboard'}
        />
      </div>
    </Splash>
  );
};

export default PasswordResetConfirmationPage;
