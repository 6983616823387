import React, { useState } from 'react';
import Splash from '../../components/layout/Splash';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordEmailConfirmation from './components/ForgotPasswordEmailConfirmation';
import { IconKey, IconLetter } from '../../aurora/icons';
import styles from '../../components/layout/Splash.module.scss';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  return !successfulSubmit ? (
    <Splash
      card="Forgot Password?"
      subtitle={"No worries, we'll send you reset instructions."}
      icon={<IconKey width={28} height={28} />}
    >
      <ForgotPassword
        email={email}
        setEmail={setEmail}
        setSuccessfulSubmit={setSuccessfulSubmit}
      />
    </Splash>
  ) : (
    <Splash
      card="Check your email"
      subtitle={
        <>
          We sent a password reset link to{' '}
          <span className={styles.boldSubtitle}>{email}</span>
        </>
      }
      icon={<IconLetter width={28} height={28} />}
    >
      <ForgotPasswordEmailConfirmation email={email} />
    </Splash>
  );
};

export default ForgotPasswordPage;
