import React, { useState, useEffect } from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import { Tooltip } from '../../../aurora/components/Tooltip/Tooltip';
import { IconCircleInfo } from '../../../aurora/icons';
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import styles from './DashboardCard.module.scss';

export type FetchDataFunction = (
  startDate?: string,
  endDate?: string,
  companyIdsFilter?: string[],
  forCompanyId?: string
) => Promise<string>;

export type DashboardCardProps = {
  title: string;
  tooltipText: string;
  fetchDataFunction: FetchDataFunction;
  forCompanyId?: string;
  rangeStartDate?: string;
  rangeEndDate?: string;
  companyIdsFilter?: string[];
};

const DashboardCard = ({
  title,
  tooltipText,
  forCompanyId,
  fetchDataFunction,
  rangeStartDate,
  rangeEndDate,
  companyIdsFilter,
}: DashboardCardProps) => {
  const [metric, setMetric] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setMetric('');
      setError(undefined);
      setIsLoading(true);

      try {
        const data = await fetchDataFunction(
          rangeStartDate,
          rangeEndDate,
          companyIdsFilter,
          forCompanyId
        );
        if (data) {
          setMetric(data);
        } else {
          throw new Error();
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [rangeStartDate, rangeEndDate, forCompanyId, companyIdsFilter]);

  const content = isLoading ? (
    <LoadingSpinner width={32} height={32} />
  ) : error !== undefined ? (
    error
  ) : (
    parseInt(metric, 10).toLocaleString()
  );

  return (
    <div className={styles.dashboardCard}>
      <div className={styles.header}>
        <Text className={styles.heading}>{title}</Text>
        <Tooltip label={tooltipText}>
          <IconCircleInfo color="neutral950" height={16} width={16} />
        </Tooltip>
      </div>
      <Text className={styles.metric}>{content}</Text>
    </div>
  );
};

export default DashboardCard;
