import React from 'react';
import {
  ShipmentEntity,
  ShipmentTypeEnum,
} from '../../../../state/shipments/types';
import { Text } from '../../../../aurora/typography/Text/Text';
import styles from './JourneyPageContainer.module.scss';
import { IconSnow } from '../../../../aurora/icons';

interface Props {
  isSelected: boolean;
  shipment: ShipmentEntity;
  setSelectedShipment: (shipment: ShipmentEntity) => void;
}
const LegSelector = ({ isSelected, shipment, setSelectedShipment }: Props) => {
  const isActiveLeg = shipment.shipmentType === ShipmentTypeEnum.REGULATED;
  const selectedClassName = isSelected ? styles.selectedLeg : undefined;

  return (
    <div
      onClick={() => setSelectedShipment(shipment)}
      className={`${selectedClassName} ${styles.leg}`}
    >
      <Text size="sm">Leg {shipment.leg}</Text>
      {isActiveLeg ? <IconSnow width={16} height={16} color="blue500" /> : null}
    </div>
  );
};

export default LegSelector;
