import React from 'react';
import { useSx } from 'dripsy';
import Subheader from './Subheader';
import SubtotalSection from './SubtotalSection';
import TotalSection from './TotalSection';

export interface CustomerCostDollarsProps {
  packaging?: number;
  tracking?: number;
  warehousing?: number;
  bloodDraw?: number;
  shipping?: number;
  totalPerShipment: number;
  total: number;
}

export interface ArtycPriceDollarsProps {
  totalPerShipment: number;
  total: number;
}

export interface CostCalculatorSummaryProps {
  header: string;
  customerCostDollars: CustomerCostDollarsProps;
  packagingTitle?: string;
}
const CostCalculatorSummary = ({
  customerCostDollars,
  header,
  packagingTitle,
}: CostCalculatorSummaryProps) => {
  const sx = useSx();

  const customerSubtotals = [
    {
      title: packagingTitle || 'Packaging Costs',
      costDollars: customerCostDollars.packaging || 0,
    },
    { title: 'Tracking Costs', costDollars: customerCostDollars.tracking || 0 },
    {
      title: 'Warehousing Costs',
      costDollars: customerCostDollars.warehousing || 0,
    },
    {
      title: 'Blood Draw Costs',
      costDollars: customerCostDollars.bloodDraw || 0,
    },
    { title: 'Shipping Costs', costDollars: customerCostDollars.shipping || 0 },
  ];

  return (
    <div
      style={sx({
        width: '280px',
        border: '1px solid',
        borderColor: 'neutral500',
        padding: '14px',
        borderRadius: '6px',
        background: 'white',
      })}
    >
      <Subheader title={header} />
      {customerSubtotals.map((subtotal) => (
        <SubtotalSection key={subtotal.title} {...subtotal} />
      ))}
      <TotalSection
        subsections={[
          {
            label: 'Total per Shipment',
            value: `$${customerCostDollars.totalPerShipment.toFixed(2)}`,
          },
          {
            label: 'Total',
            value: `$${customerCostDollars.total.toFixed(2)}`,
          },
        ]}
      />
    </div>
  );
};

export default CostCalculatorSummary;
