import React from 'react';
import ReactSwitch from 'react-switch';
import { useSx } from 'dripsy';

export interface SwitchProps {
  isSwitchOn: boolean;
  onChange: (newValue: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({ isSwitchOn, onChange }) => {
  const sx = useSx();
  return (
    <ReactSwitch
      onChange={() => onChange(!isSwitchOn)}
      checked={isSwitchOn}
      handleDiameter={12}
      width={28}
      height={16}
      offHandleColor={sx({ color: 'white' }).color}
      onHandleColor={sx({ color: 'white' }).color}
      offColor={sx({ color: 'neutral700' }).color}
      onColor={sx({ color: 'primary600' }).color}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="none"
      activeBoxShadow="none"
    />
  );
};

export default Switch;
