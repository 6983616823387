import React from 'react';
import { EventStatusesEnum } from '../../../state/events/types';
import { SxProp, useSx } from 'dripsy';
import { newStatusStyle, reviewedStatusStyle } from './eventTableStyle';

interface Props {
  status: EventStatusesEnum;
  onClick?: () => void;
}
const EventStatusLabel = ({ status, onClick }: Props) => {
  const sx = useSx();
  const label = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const styles: SxProp = label === 'New' ? newStatusStyle : reviewedStatusStyle;

  return (
    <div style={sx(styles)} onClick={onClick}>
      {label}
    </div>
  );
};

export default EventStatusLabel;
