import React from 'react';
import {
  ButtonGroup,
  ButtonProps,
} from '../../../aurora/components/ButtonGroup/ButtonGroup';
import { useDispatch } from 'react-redux';
import { setStatusFilter } from '../../../state/pcbTests';

const PcbTestStatusFilter = () => {
  const dispatch = useDispatch();

  const statuses: ButtonProps[] = [
    {
      label: 'View all',
      onClick: () => dispatch(setStatusFilter('ALL')),
    },
    {
      label: 'Success',
      onClick: () => dispatch(setStatusFilter('success')),
    },
    {
      label: 'Failure',
      onClick: () => dispatch(setStatusFilter('failure')),
    },
  ];

  return <ButtonGroup buttonProps={statuses} initialActiveIdx={0} />;
};

export default PcbTestStatusFilter;
