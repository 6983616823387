import React, { ReactNode } from 'react';
import styles from './Report.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  printVersion?: boolean;
}
const PrintOnly = ({ children, className, printVersion }: Props) => {
  return (
    <div className={`${printVersion ? '' : styles.printOnly} ${className}`}>
      {children}
    </div>
  );
};

export default PrintOnly;
