import React from 'react';
import { EmptyConfig } from './tableConfig';
import TableNotice from './TableNotice';

interface Props {
  emptyConfig: EmptyConfig;
}
const EmptyTable = ({ emptyConfig }: Props) => {
  const { icon, title, body } = emptyConfig;

  return (
    <TableNotice
      icon={icon}
      iconColors={{
        color: 'gray600v1',
        backgroundColor: 'neutral100',
      }}
      title={title}
      body={body}
    />
  );
};

export default EmptyTable;
