import mapboxgl from 'mapbox-gl';
import { SegmentToSnapshotsMap } from '../../state/segments';
import { LegendData } from './mapCreation';
import {
  addGeoJSONLayer,
  addGeoJSONSource,
  calculateBoundingBox,
  generateDatasetColors,
  mergeBounds,
} from './mapUtils';
import { SegmentSnapshotEntity } from '../../state/segments/types';
import {
  groupSnapshotsByCoords,
  convertSnapshotGroupToGeoJSON,
} from './locationUtils';
import { handleMouseEnter, handleMouseLeave } from './mapHandlers';

export const renderSegmentsOnMap = (
  map: mapboxgl.Map,
  segments: SegmentToSnapshotsMap,
  legendDataTemp: LegendData[],
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  let accBounds: [[number, number], [number, number]] = [
    [Infinity, Infinity],
    [-Infinity, -Infinity],
  ];

  Object.entries(segments).forEach(([segmentId, segment], segmentIndex) => {
    const bounds = renderSegment(
      map,
      segment,
      segmentIndex,
      segmentId,
      legendDataTemp,
      popup
    );
    accBounds = mergeBounds(accBounds, bounds);
  });

  return accBounds;
};

const renderSegment = (
  map: mapboxgl.Map,
  segment: SegmentSnapshotEntity[],
  segmentIndex: number,
  segmentId: string,
  legendDataTemp: LegendData[],
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  const cleanedData = groupSnapshotsByCoords(segment);
  const geojsonFeatures = convertSnapshotGroupToGeoJSON(cleanedData);
  if (geojsonFeatures.features.length === 0) {
    return [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ];
  }
  const sourceId = `points${segmentIndex}`;
  const layerId = `points${segmentIndex}`;
  const { startColor, endColor } = generateDatasetColors(segmentIndex);

  addGeoJSONSource(map, sourceId, geojsonFeatures);
  addGeoJSONLayer(
    map,
    layerId,
    sourceId,
    geojsonFeatures.features.length,
    startColor,
    endColor
  );
  legendDataTemp.push({ segmentId, startColor, endColor });

  map.on('mouseenter', layerId, (e) =>
    handleMouseEnter(map, e, segmentId, segmentIndex, popup)
  );
  map.on('mouseleave', layerId, () => handleMouseLeave(map, popup));

  return calculateBoundingBox(geojsonFeatures);
};
