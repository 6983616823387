import React, { MouseEvent, useRef } from 'react';
import { ColumnData, ColumnsConfig } from './tableConfig';
import { useSx } from 'dripsy';
import { tableRowStyle } from './tableStyle';
import Checkbox from '../../aurora/components/Checkbox/Checkbox';
import { useHover } from 'react-native-web-hooks';

interface Props<T> {
  entity: T;
  columnsConfig: ColumnsConfig<T>;
  onSelectRow: (checked: boolean) => void;
  rowSelected: boolean;
  onClick?: () => void;
}

const DataTableRow = <T,>({
  entity,
  columnsConfig,
  onSelectRow,
  rowSelected,
  onClick,
}: Props<T>) => {
  const { columns, actionColumn, bulkAction } = columnsConfig;
  const sx = useSx();
  const rowRef = useRef(null);
  const isHovered = useHover(rowRef);

  const click = (event: MouseEvent<any>) => {
    if (event.currentTarget !== event.target) {
      return;
    }
    onClick && onClick();
  };
  const clickStyle = onClick
    ? sx({
        cursor: 'pointer',
      })
    : undefined;
  const clickHoverStyle =
    onClick && isHovered ? sx({ backgroundColor: 'neutral100' }) : undefined;

  const rowData = columns
    .filter((column) => {
      const { showColumn } = column;
      if (showColumn === undefined) {
        return true;
      }
      return showColumn;
    })
    .map((column, i) => {
      const { customData, customComponent, property } = column;
      const data = customData
        ? customData(entity)
        : ((entity[property] ?? '-') as ColumnData);

      const component = customComponent
        ? customComponent(data, entity, onClick)
        : data;

      return (
        <td
          key={`row-${i}`}
          style={{ ...sx(tableRowStyle), ...clickStyle, ...clickHoverStyle }}
          onClick={click}
        >
          {component}
        </td>
      );
    });

  const row = [
    ...(bulkAction
      ? [
          <td style={{ ...sx(tableRowStyle), ...clickHoverStyle }} key="bulk">
            <Checkbox onChange={onSelectRow} managedChecked={rowSelected} />
          </td>,
        ]
      : []),
    ...rowData,
    ...(actionColumn
      ? [
          <td
            style={{
              ...sx(tableRowStyle),
              ...clickHoverStyle,
            }}
            key="action"
          >
            {actionColumn(entity)}
          </td>,
        ]
      : []),
  ];

  return <tr ref={rowRef}>{row}</tr>;
};

export default DataTableRow;
