import React, { useState, ComponentType } from 'react';
import { Flex, Pressable, Text, useDripsyTheme } from 'dripsy';
import { IconClose } from '../../icons';
import { tagStyles, placeholderStyles } from './filterStyles';
import InputHint, { InputHintProps } from '../form/InputHint';
import InputLabel, { InputLabelProps } from '../form/InputLabel';
import { BaseTheme } from '../../theme/baseTheme';
import Dropdown from './Dropdown';

// functions that can be used by the popover content
export interface PopoverContentProps<FilterType> {
  onSubmit: (filter: FilterType) => void;
  onCancel: () => void;
  onClear: () => void;
}

export type FilterProps<FilterType> = {
  variant?: keyof BaseTheme['select'];
  placeholder: string;
  showPlaceholderFiltered?: boolean;
  popoverContent: ComponentType<PopoverContentProps<FilterType>>;
  onSubmit: (filter: FilterType) => void;
  onClear: () => void;
  toFiltersSummary: (filter: FilterType) => string;
  className?: string;
  initialFilter?: FilterType;
} & InputLabelProps &
  InputHintProps;

const Filter = <FilterType,>({
  variant = 'filter',
  placeholder,
  showPlaceholderFiltered = true,
  popoverContent: PopoverContent,
  onSubmit,
  onClear,
  toFiltersSummary,
  className,
  label,
  tooltip,
  error,
  showRequired,
  showOptional,
  hint,
  initialFilter,
}: FilterProps<FilterType>) => {
  const { theme } = useDripsyTheme();
  const variantSx = theme.select[variant];

  const [open, setOpen] = useState(false);
  const [filtersSummary, setFiltersSummary] = useState<null | string>(
    initialFilter ? toFiltersSummary(initialFilter) : null
  );

  const isFiltered = filtersSummary !== null;
  const textColor = isFiltered ? 'gray50v1' : 'gray600v1';

  const submitPopover = (filter: FilterType) => {
    const summary = toFiltersSummary(filter);
    setFiltersSummary(summary);
    setOpen(false);
    onSubmit(filter);
  };
  const clearPopover = () => {
    setFiltersSummary(null);
    setOpen(false);
    onClear();
  };

  const popover = (
    <PopoverContent
      onSubmit={submitPopover}
      onCancel={() => setOpen(false)}
      onClear={clearPopover}
    />
  );

  // tag that shows up on the input when a filter is applied
  const summaryTag = isFiltered ? (
    <Flex sx={{ ...tagStyles, ...variantSx.$summary }}>
      <Text sx={{ color: variantSx.$summary.color }}>{filtersSummary}</Text>
      <Pressable
        onPress={clearPopover}
        sx={{ color: variantSx.$summary.color }}
      >
        <IconClose width={12} height={12} strokeWidth={3} />
      </Pressable>
    </Flex>
  ) : null;

  // if !showPlaceholderFiltered, don't show placeholder when filter is set
  const placeholderComponent =
    isFiltered && !showPlaceholderFiltered ? null : (
      <Text
        sx={{
          ...placeholderStyles,
          color: textColor,
        }}
      >
        {placeholder}
      </Text>
    );

  return (
    <div>
      <InputLabel
        label={label}
        showOptional={showOptional}
        showRequired={showRequired}
        tooltip={tooltip}
      />
      <Dropdown
        variant={variant}
        className={className}
        text={
          <>
            {summaryTag}
            {placeholderComponent}
          </>
        }
        textSx={{ color: textColor }}
        popoverContent={popover}
        open={open}
        setOpen={setOpen}
      />
      <InputHint hint={hint} error={error} />
    </div>
  );
};

export default Filter;
