import React from 'react';
import { Field } from 'react-final-form';
import TextInput, {
  TextInputProps,
} from '../../aurora/components/TextInput/TextInput';
import { FieldValidator } from 'final-form';

type TextFieldProps = Omit<TextInputProps, 'variant'> & {
  name: string;
  validate?: FieldValidator<string>;
};
const TextField = (props: TextFieldProps) => {
  return (
    <Field
      name={props.name}
      validate={props.validate}
      render={({ input, meta }) => {
        const hasError = meta.touched && meta.error !== undefined;

        return (
          <TextInput
            variant="form"
            {...input}
            {...props}
            managedText={input.value}
            hint={hasError ? meta.error : undefined}
            error={hasError}
          />
        );
      }}
    />
  );
};

export default TextField;
