import React from 'react';
import { Path, Circle } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconSpinner: React.FunctionComponent<IconProps> = (props) => (
  <IconBase viewBox="0 0 28 28" {...props}>
    <Circle cx="14" cy="14" r="12" stroke="#ECF5FF" strokeWidth="4" />
    <Path
      d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14"
      stroke="#0A77FF"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </IconBase>
);
