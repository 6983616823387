import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

// TODO: make this animated
export const IconLoading: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M11.9999 3V7M18.3639 5.63604L15.5355 8.46447M21 12.0002H17M18.3639 18.3639L15.5355 15.5355M11.9999 17V21M8.46439 15.5355L5.63595 18.364M7.00002 12.0002H3M8.46449 8.46451L5.63605 5.63608"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
