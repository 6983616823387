import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Text } from '../../aurora/typography/Text/Text';
import SegmentsApi from '../../api/segmentsApi';
import { isArtyc, selectAuth } from '../../state/auth';
import JourneyPageContainer from './components/journeyPage/JourneyPageContainer';
import { setSegment } from '../../state/segments';
import { RootState } from '../../state/store';
import JourneysApi from '../../api/journeysApi';
import { JourneyEntity } from '../../state/journeys/types';
import { setShipmentsForJourney } from '../../state/journeys';

const JourneyPage = () => {
  const { journey: journeyId } = useParams();

  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [journey, setJourney] = useState<JourneyEntity | null>(null);

  const viewAsCustomer = useSelector(
    (state: RootState) => state.journeys.viewAsCustomer
  );
  const isArtycViewer = isArtyc(auth) && !viewAsCustomer;

  const shipments = useSelector((root: RootState) =>
    journey !== null ? root.journeys.shipmentsByJourney[journey._id] : undefined
  );

  // TODO: condense into one request so that it's more performant?
  const fetchData = async () => {
    setJourney(null);

    if (journeyId === undefined) {
      return;
    }

    let loadedJourney: JourneyEntity | undefined = undefined;
    loadedJourney = await JourneysApi.getJourney(axios, auth, journeyId);
    if (!loadedJourney) {
      throw new Error(`journey ${journeyId} not found`);
    }
    setJourney(loadedJourney);

    const shipments = await JourneysApi.getShipmentsForJourney(
      axios,
      auth,
      loadedJourney._id
    );
    dispatch(setShipmentsForJourney({ journey: loadedJourney._id, shipments }));

    if (
      loadedJourney.segmentId !== undefined &&
      loadedJourney.segmentId !== null
    ) {
      const loadedSegment = await SegmentsApi.getSegment(
        axios,
        auth,
        loadedJourney.segmentId
      );
      if (loadedSegment) {
        dispatch(setSegment(loadedSegment));
      }
    }
  };

  const loadData = async () => {
    setLoading(true);
    setError(false);

    try {
      await fetchData();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [journeyId]);

  if (loading) {
    return (
      <Text size="md" sx={{ margin: '12px' }}>
        Loading...
      </Text>
    );
  }

  if (
    journeyId === undefined ||
    journey === null ||
    error ||
    shipments === undefined ||
    shipments.length === 0
  ) {
    return (
      <Text size="md" sx={{ margin: '12px' }}>
        An error has occurred, please try again
      </Text>
    );
  }

  return (
    <JourneyPageContainer
      isArtycViewer={isArtycViewer}
      journey={journey}
      shipments={shipments}
    />
  );
};

export default JourneyPage;
