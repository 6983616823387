import React, { CSSProperties } from 'react';
import { useSx, SxProp } from 'dripsy';

const ShippingCostsTable = () => {
  const sx = useSx();
  const shippingRates = [
    { weight: '<4lbs', overnight: '$87', twoDay: '$49' },
    { weight: '5 lbs', overnight: '$127', twoDay: '$75' },
    { weight: '6 lbs', overnight: '$135', twoDay: '$80' },
    { weight: '7 lbs', overnight: '$147', twoDay: '$85' },
  ];

  const tableStyle: CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const headerStyle: CSSProperties = sx({
    backgroundColor: 'neutral100',
    fontWeight: '500',
    lineHeight: '20px',
    color: 'gray50v1',
    padding: '16px',
    textAlign: 'left',
  });

  const cellStyle: CSSProperties = sx({
    padding: '16px',
    textAlign: 'left',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'neutral500',
    color: 'gray50v1',
  });

  return (
    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={headerStyle}>Carrier</th>
          <th style={headerStyle}>Weight (lbs)</th>
          <th style={headerStyle}>Overnight (by 5pm)</th>
          <th style={headerStyle}>2 Day</th>
        </tr>
      </thead>
      <tbody>
        {shippingRates.map((rate, index) => (
          <tr key={index}>
            <td
              style={sx({
                ...cellStyle,
                fontWeight: '600',
                color: 'gray600v1',
              } as SxProp)}
            >
              <b>FedEx</b>
            </td>
            <td style={cellStyle}>{rate.weight}</td>
            <td style={cellStyle}>{rate.overnight}</td>
            <td style={cellStyle}>{rate.twoDay}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ShippingCostsTable;
