import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconTemperature: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 16 18">
    <Path
      d="M9.6 3C9.6 1.89543 8.70457 1 7.6 1C6.49543 1 5.6 1.89543 5.6 3V10.4062C4.63521 11.052 4 12.1518 4 13.4C4 15.3882 5.61177 17 7.6 17C9.58823 17 11.2 15.3882 11.2 13.4C11.2 12.1518 10.5648 11.052 9.6 10.4062V3Z"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.6 14.2C8.04183 14.2 8.4 13.8418 8.4 13.4C8.4 12.9582 8.04183 12.6 7.6 12.6C7.15817 12.6 6.8 12.9582 6.8 13.4C6.8 13.8418 7.15817 14.2 7.6 14.2Z"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);
