import React from 'react';
import { SlideoutContentProps } from '../../../aurora/components/MobileFilter/MobileFilter';
import ChecklistSection, { ChecklistSectionProp } from './ChecklistSection';
import styles from './MobileChecklistFilter.module.scss';

export type CheckedIdsSectionMap = Record<number, string[]>;

interface Props extends SlideoutContentProps<CheckedIdsSectionMap> {
  sections: ChecklistSectionProp[];
}

const ChecklistContent = ({
  sections,
  selectedFilters,
  onSetFilters,
}: Props) => {
  return (
    <div className={styles.checklistContent}>
      {sections.map((section, idx) => {
        return (
          <ChecklistSection
            key={`section-${idx}`}
            onChange={(selectedIds) => {
              const newFilters = Object.assign({}, selectedFilters, {
                [idx]: selectedIds,
              });
              onSetFilters(newFilters);
            }}
            {...section}
            selectedIds={selectedFilters[idx] || []}
          />
        );
      })}
    </div>
  );
};

export default ChecklistContent;
