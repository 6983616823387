import {
  CreateJourneyRequestBody,
  UpdateJourneyRequestBody,
  UpdateJourneyShipmentBody,
} from '../../../../api/journeysApi';
import { JourneyEntity } from '../../../../state/journeys/types';
import {
  AddressEntity,
  AddressTypeEnum,
  ShipmentEntity,
  ShipmentTypeEnum,
  ShippoCarrierEnum,
} from '../../../../state/shipments/types';

export interface CreateJourneyFormData {
  company: string;
  shippingProfile: string;
  device?: string;
  expectedStartDate?: string;
  poNumber?: string;
  customId?: string;
  user: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    trackingNumber?: string;
    carrier?: ShippoCarrierEnum;
  };
  lab: {
    addressName?: string;
    attn?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    trackingNumber?: string;
    carrier?: ShippoCarrierEnum;
  };
}

export const createFormToBody = (
  formData: CreateJourneyFormData
): CreateJourneyRequestBody => {
  const userAddress: AddressEntity = {
    addressName: undefined,
    attn: undefined,
    addressLine1: formData.user.addressLine1,
    addressLine2: formData.user.addressLine2,
    city: formData.user.city,
    state: formData.user.state,
    postalCode: formData.user.postalCode,
    country: 'US',
    addressType: AddressTypeEnum.USER,
  };

  const labAddress: AddressEntity = {
    addressName: formData.lab.addressName,
    attn: formData.lab.attn,
    addressLine1: formData.lab.addressLine1,
    addressLine2: formData.lab.addressLine2,
    city: formData.lab.city,
    state: formData.lab.state,
    postalCode: formData.lab.postalCode,
    country: 'US',
    addressType: AddressTypeEnum.LAB,
  };

  return {
    ...formData,
    // for now we assume the shipments have the same structure
    // first leg is to user + unregulated, second is to lab + regulated
    shipments: [
      {
        leg: 1,
        shipmentType: ShipmentTypeEnum.UNREGULATED,
        carrier: formData.user.carrier,
        trackingNumber: formData.user.trackingNumber,
        // TODO: should we hard code the warehouse as the origin?
        destination: userAddress,
      },
      {
        leg: 2,
        shipmentType: ShipmentTypeEnum.REGULATED,
        carrier: formData.lab.carrier,
        trackingNumber: formData.lab.trackingNumber,
        origin: userAddress,
        destination: labAddress,
      },
    ],
  };
};

export interface EditJourneyFormData {
  device?: string;
  expectedStartDate?: string;
  shipments: {
    shipmentId: string;
    trackingNumber?: string;
    carrier?: ShippoCarrierEnum;
    addressName?: string;
    attn?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
  }[];
}

export const editFormToBody = (
  formData: EditJourneyFormData
): UpdateJourneyRequestBody => {
  const shipments = formData.shipments.reduce((acc, shipment) => {
    const shipmentBody: UpdateJourneyShipmentBody = {
      trackingNumber: shipment.trackingNumber,
      carrier: shipment.carrier,
      destination: {
        addressName: shipment.addressName,
        attn: shipment.attn,
        addressLine1: shipment.addressLine1,
        addressLine2: shipment.addressLine2,
        city: shipment.city,
        state: shipment.state,
        postalCode: shipment.postalCode,
      },
    };

    const newAcc = {
      ...acc,
      [shipment.shipmentId]: shipmentBody,
    };

    return newAcc;
  }, {});

  return {
    ...formData,
    shipments,
  };
};

export const journeyToEditFormData = (
  journey: JourneyEntity,
  shipments: ShipmentEntity[]
): EditJourneyFormData => {
  return {
    device: journey.device || undefined,
    expectedStartDate: journey.expectedStartDate || undefined,
    shipments: shipments.map((shipment) => {
      const destination = shipment.destination;

      return {
        shipmentId: shipment._id,
        trackingNumber: shipment.trackingNumber ?? undefined,
        carrier: shipment.carrier ?? undefined,
        addressName: destination?.addressName ?? undefined,
        attn: destination?.attn ?? undefined,
        addressLine1: destination?.addressLine1 ?? '',
        addressLine2: destination?.addressLine2 ?? undefined,
        city: destination?.city ?? '',
        state: destination?.state ?? '',
        postalCode: destination?.postalCode ?? '',
      };
    }),
  };
};
