import React from 'react';
import CostCalculatorSummary from './CostCalculatorSummary';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { mkCustomerCostDollars } from '../../../../../state/customerCostReports/utils/summaryUtils';

const CostCalculatorSummaryContainer = () => {
  const customerCostDollars = useSelector((state: RootState) =>
    mkCustomerCostDollars(
      state.customerCostReports.formInputs.estimateInputs,
      1
    )
  );

  return (
    <CostCalculatorSummary
      customerCostDollars={customerCostDollars}
      header={'Summary Preview'}
    />
  );
};

export default CostCalculatorSummaryContainer;
