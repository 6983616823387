import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { Role } from '../state/auth/types';
import { selectAuth } from '../state/auth';
import { useSelector } from 'react-redux';

interface Props {
  allowedRoles: Role[];
}

const RequireAuth = ({ allowedRoles }: Props) => {
  const auth = useSelector(selectAuth);
  const location = useLocation();

  return allowedRoles.map((role) => role.pid).includes(auth?.role || '') ? (
    <Outlet />
  ) : auth !== null ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
