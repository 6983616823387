import { useSx } from 'dripsy';
import React from 'react';
import { Svg } from 'react-native-svg';

type SvgProps = React.ComponentProps<typeof Svg>;

export type IconProps = SvgProps & {
  width?: number | '100%' | 'auto';
  height?: number | '100%' | 'auto';
  color?: string;
  viewBox?: string;
  fill?: string;
  className?: string;
};

export const IconBase = ({
  color,
  width,
  height,
  viewBox,
  fill,
  children,
  className,
  ...props
}: IconProps) => {
  const sx = useSx();
  const setColor = color ?? 'currentColor';
  const setViewBox = viewBox ?? '0 0 24 24';
  const setFill = fill ?? 'none';

  return (
    <Svg
      style={sx({
        color: setColor,
        display: 'block',
      })}
      width={width}
      height={height}
      viewBox={setViewBox}
      fill={setFill}
      /* @ts-ignore */
      className={className}
      {...props}
    >
      {children}
    </Svg>
  );
};
