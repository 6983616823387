import React, { useEffect, useState } from 'react';
import { ShipmentEntity } from '../../../../state/shipments/types';
import { SegmentEntity } from '../../../../state/segments/types';
import styles from './JourneyPageContainer.module.scss';
import ShipmentInfo from './leftColumn/ShipmentInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSnapshotsBySegment,
  setSnapshots,
} from '../../../../state/segments';
import TempManagementSection from './leftColumn/TempManagementSection';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { EventEntity } from '../../../../state/events/types';
import SegmentsApi from '../../../../api/segmentsApi';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import EventsApi from '../../../../api/eventsApi';
import SegmentDataSection from './leftColumn/SegmentDataSection';
import { JourneyEntity } from '../../../../state/journeys/types';
import CarrierTrackingCard from './leftColumn/CarrierTrackingCard';
import ShipmentDetailsCard from './leftColumn/ShipmentDetailsCard';
import InfoCard from './InfoCard';
import ShipperInfoCard from './leftColumn/ShipperInfoCard';

interface Props {
  journey: JourneyEntity;
  shipment: ShipmentEntity;
  segment?: SegmentEntity;
  isArtycViewer: boolean;
}
const RegulatedShipmentContainer = ({
  journey,
  shipment,
  segment,
  isArtycViewer,
}: Props) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [shippingProfile, setShippingProfile] = useState<
    ShippingProfileEntity | undefined
  >(undefined);
  const [events, setEvents] = useState<EventEntity[]>([]);

  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
  const snapshots = snapshotsBySegment[segment?._id || ''] || [];

  // TODO: condense into one request so that it's more performant?
  const fetchData = async () => {
    setShippingProfile(undefined);
    setEvents([]);

    setIsLoading(true);
    setIsError(false);

    try {
      const shippingProfileId =
        segment?.shippingProfile || journey.shippingProfile;
      const shippingProfileResp = shippingProfileId
        ? await ShippingProfilesApi.getShippingProfile(
            axios,
            auth,
            shippingProfileId
          )
        : undefined;
      setShippingProfile(shippingProfileResp);

      if (segment !== undefined) {
        const snapshots = await SegmentsApi.getSegmentSnapshots(
          axios,
          auth,
          isArtycViewer,
          segment._id
        );
        dispatch(setSnapshots({ [segment._id]: snapshots }));

        const eventResp = await EventsApi.getEventsForSegment(
          axios,
          auth,
          segment._id
        );
        setEvents(eventResp);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [shipment, segment, isArtycViewer]);

  // TODO: do we want any loading states for sections of it?
  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <ShipmentInfo
          shipment={shipment}
          segment={segment}
          snapshots={snapshots}
          isArtycViewer={isArtycViewer}
        />
        <ShipmentDetailsCard shipment={shipment} />
        <CarrierTrackingCard shipment={shipment} />
        <ShipperInfoCard segment={segment} />
        <InfoCard title="Shipping Profile" accordion>
          <TempManagementSection
            segment={segment}
            shippingProfile={shippingProfile}
          />
        </InfoCard>
      </div>
      <div className={styles.rightColumn}>
        <SegmentDataSection
          segment={segment}
          isArtycViewer={isArtycViewer}
          isLoading={isLoading}
          isError={isError}
          events={events}
        />
      </div>
    </div>
  );
};

export default RegulatedShipmentContainer;
