import React from 'react';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../state/auth';
import { DeviceType } from '../../../state/devices/types';
import { RootState } from '../../../state/store';
import MobileChecklistFilter from './MobileChecklistFilter';

interface CompanyFilterProps {
  setCompanyIdsAction: (companyIds: string[] | undefined) => void;
  filteredCompanyIds: string[] | undefined;
  setDeviceTypesAction: (deviceTypes?: DeviceType[]) => any;
  filteredDeviceTypes?: DeviceType[];
}

const MobileCompanyDeviceFilter = ({
  setCompanyIdsAction,
  filteredCompanyIds,
  setDeviceTypesAction,
  filteredDeviceTypes,
}: CompanyFilterProps) => {
  const auth = useSelector(selectAuth);

  const deviceTypes = useSelector(
    (state: RootState) => state.devices.deviceTypeList
  );

  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;

  const isArtycUser = isArtyc(auth);

  return (
    <MobileChecklistFilter
      sections={[
        {
          title: 'Companies',
          selectedIds: filteredCompanyIds || [],
          hasSearch: true,
          searchPlaceholder: 'Search by company name',
          options: companies.map((company) => ({
            id: company._id,
            label: company.companyName,
          })),
          show: isArtycUser,
          onApplyFilters: (companyIds) => setCompanyIdsAction(companyIds),
          onClear: () => setCompanyIdsAction(undefined),
        },
        {
          title: 'Shippers',
          selectedIds: filteredDeviceTypes || [],
          options: deviceTypes
            ? deviceTypes.map((type) => ({
                id: type,
                label: type,
              }))
            : [],
          show: true,
          onApplyFilters: (eventIds) =>
            setDeviceTypesAction(eventIds as DeviceType[]),
          onClear: () => setDeviceTypesAction(undefined),
        },
      ]}
    />
  );
};

export default MobileCompanyDeviceFilter;
