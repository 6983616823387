import { AxiosInstance, AxiosResponse } from 'axios';
import { post } from '../hooks/useAxiosPrivate';

const SUPPORT_URL = '/api/v1/support';

const SupportApi = {
  createSupportRequest: async (axios: AxiosInstance, message: string) => {
    const response: AxiosResponse<{ message: string }> = await post(
      axios,
      SUPPORT_URL,
      {
        message,
      }
    );
    return response.data;
  },
};

export default SupportApi;
