import React, { useEffect } from 'react';
import { useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { IconLogo, IconClose } from '../../../aurora/icons';
import { useDispatch, useSelector } from 'react-redux';
import { isCompanyEmployee, selectAuth } from '../../../state/auth';
import EventsApi from '../../../api/eventsApi';
import { setNewEventCount } from '../../../state/events';
import Slideout from '../../../aurora/components/Slideout/Slideout';
import styles from './NavBar.module.scss';
import NavBarHeader from './NavBarHeader';
import NavBarBody from './NavBarBody';

const NavBar = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);

  const toggleSlideout = () => {
    setIsSlideoutOpen(!isSlideoutOpen);
  };

  // get event count
  useEffect(() => {
    const fetchEventCount = async () => {
      const eventCount = await EventsApi.getNewEventCount(axios, auth);
      dispatch(setNewEventCount(eventCount));
    };

    if (!isCompanyEmployee(auth)) {
      fetchEventCount();
    }
  }, []);

  return (
    <nav className={styles.navBar}>
      <NavBarHeader toggleSlideout={toggleSlideout} />
      <div className={[styles.sidebar, styles.desktopNavBar].join(' ')}>
        <NavBarBody />
      </div>
      <div className={styles.mobileNavBar}>
        <Slideout
          direction="left"
          open={isSlideoutOpen}
          onClose={toggleSlideout}
          variant="nav"
        >
          <div className={styles.slideoutHeader}>
            <div className={styles.slideoutLogo}>
              <IconLogo width={142} height={36} />
            </div>
            <div
              className={styles.slideoutCloseButtonContainer}
              onClick={toggleSlideout}
            >
              <IconClose width={24} height={24} />
            </div>
          </div>
          <div className={styles.sidebar}>
            <NavBarBody toggleSlideout={toggleSlideout} />
          </div>
        </Slideout>
      </div>
    </nav>
  );
};

export default NavBar;
