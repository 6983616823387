import React from 'react';
import PathNumberInput from '../common/PathNumberInput';
import FormInputContainer from '../common/FormInputContainer';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import styles from '../Form.module.scss';

const WarehousingCostsSection = () => {
  const reportVersion = useSelector(
    (state: RootState) => state.customerCostReports.selectedReport?.version
  );

  const isMultiUse = useSelector(
    (state: RootState) =>
      state.customerCostReports.formInputs.estimateInputs.packagingInputs
        .isMultiUse
  );

  return (
    <CostCalculatorFormSection
      title="Warehousing Costs"
      subtitle="Calculate the costs of warehousing - particularly for reusable packaging."
    >
      <FormInputContainer
        title="Kitting Materials"
        inputs={() => (
          <PathNumberInput
            path="estimateInputs.warehousingInputs.kittingCostDollars"
            label="Cost"
            showIncrement
            labelClassName={styles.label}
            type="dollar"
            clearToZero
            minNum={0}
          />
        )}
      />
      <FormInputContainer
        title="Kitting Time"
        inputs={() => (
          <PathNumberInput
            path="estimateInputs.warehousingInputs.kittingTimeMinutes"
            label="Minutes"
            showIncrement
            labelClassName={styles.label}
            type="number"
            clearToZero
            minNum={0}
          />
        )}
      />
      <FormInputContainer
        title="Warehouse Burdened Hourly"
        inputs={() => (
          <PathNumberInput
            path="estimateInputs.warehousingInputs.warehouseHourlyCostDollars"
            label="Cost"
            showIncrement
            labelClassName={styles.label}
            type="dollar"
            clearToZero
            minNum={0}
          />
        )}
      />
      {reportVersion !== '1' && isMultiUse && (
        <FormInputContainer
          title="Cleaning and Refurb Time"
          inputs={() => (
            <PathNumberInput
              path="estimateInputs.warehousingInputs.cleaningRefurbMinutes"
              label="Minutes"
              showIncrement
              labelClassName={styles.label}
              type="number"
              clearToZero
              minNum={0}
            />
          )}
        />
      )}
    </CostCalculatorFormSection>
  );
};

export default WarehousingCostsSection;
