import { Plugin } from 'chart.js';

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexWrap = 'wrap';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin: Plugin = {
  id: 'htmlLegend',
  afterUpdate(chart) {
    const ul = getOrCreateLegendList('legend-container');

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    //@ts-ignore
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexWrap = 'wrap';
      li.style.flexDirection = 'row';
      li.style.marginRight = '24px';
      li.style.fontSize = '13px';

      li.onclick = () => {
        chart.setDatasetVisibility(
          item.datasetIndex!,
          !chart.isDatasetVisible(item.datasetIndex!)
        );
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      //@ts-ignore
      boxSpan.style.background = item.strokeStyle;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = '0';
      boxSpan.style.height = '10px';
      boxSpan.style.marginRight = '6px';
      boxSpan.style.width = '10px';
      boxSpan.style.borderRadius = '3px';

      // Text
      const textContainer = document.createElement('p');
      // @ts-ignore
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};
