import RNToast, { ToastConfig } from 'react-native-toast-message';
import React from 'react';
import Toast from './Toast';

const toastConfig: ToastConfig = {
  success: (props) => {
    return <Toast {...props} type="success" />;
  },
  loading: (props) => {
    return <Toast {...props} type="loading" />;
  },
  info: (props) => {
    return <Toast {...props} type="info" />;
  },
  error: (props) => {
    return <Toast {...props} type="error" />;
  },
};

const ToastContainer = () => {
  return <RNToast config={toastConfig} />;
};

export default ToastContainer;
