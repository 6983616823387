import React from 'react';
import TextInput from '../../../../../../aurora/components/TextInput/TextInput';
import { updateFormInputs } from '../../../../../../state/customerCostReports';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import {
  FormInputs,
  FormInputPaths,
} from '../../../../../../state/customerCostReports/types';

const getValueByPath = (object: FormInputs, path: FormInputPaths): any => {
  return path
    .split('.')
    .reduce((acc: any, part: string) => acc?.[part], object);
};

interface PathTextInputProps {
  label: string;
  path: FormInputPaths;
}

const PathTextInput = ({ label, path }: PathTextInputProps) => {
  const dispatch = useDispatch();
  const formInputs = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );

  const value = getValueByPath(formInputs, path) || '';

  const handleChange = (value: string) => {
    dispatch(updateFormInputs({ path, value }));
  };

  return (
    <TextInput
      variant="form"
      label={label}
      sx={{ borderColor: 'neutral700' }}
      managedText={value}
      onChange={handleChange}
    />
  );
};

export default PathTextInput;
