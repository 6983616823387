import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconMinus: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <Path d="M4.75 9.25H15.25V10.75H4.75V9.25Z" fill="currentColor" />
  </IconBase>
);
