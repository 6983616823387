import { DeviceType } from '../devices/types';

export enum PayloadTypeEnum {
  BloodSample = 'Blood Sample',
}

export enum PricingModel {
  CaaS = 'Cooling as a Service',
  Lease = 'Lease',
}

export enum BloodDrawMethod {
  Phlebotomist = 'Phlebotomist',
  CollectionDevice = 'CollectionDevice',
}

export enum ShippingType {
  Overnight = 'Overnight',
  TwoDay = 'TwoDay',
  None = '',
}

interface ContactInfoInputs {
  companyName: string;
  imageId?: string;
  contactEmail?: string;
  contactFirstName?: string;
  contactLastName?: string;
}

interface PackagingInputs {
  isMultiUse?: boolean;
  costPerBoxDollars?: number;
  usesPerBox?: number;
  gelPackPriceDollars?: number;
  gelPackCount?: number;
  dryIcePriceDollars?: number;
  dryIcePounds?: number;
}

export interface CostItem<T> {
  name: T;
  costDollars: number;
}

interface TrackingInputs {
  sensors: CostItem<string>[];
}

interface WarehousingInputs {
  warehouseHourlyCostDollars?: number;
  cleaningRefurbMinutes?: number;
  kittingTimeMinutes?: number;
  kittingCostDollars?: number;
}

interface BloodDrawInputs {
  methods: CostItem<BloodDrawMethod>[];
}

interface ShippingInputs {
  trips: CostItem<string>[];
}

export interface EstimateFormInputs {
  packagingInputs: PackagingInputs;
  trackingInputs: TrackingInputs;
  warehousingInputs: WarehousingInputs;
  bloodDrawInputs: BloodDrawInputs;
  shippingInputs: ShippingInputs;
}

// ones that don't need to be saved into the db, just used for interactions in the form
export interface DynamicFormInputs {
  numShipments: number;
  numShippers: number;
}

export interface ArtycComparison {
  name: string;
  pricingModel: PricingModel;
  priceDollars: number;
}

export interface FormInputs {
  contactInfoInputs: ContactInfoInputs;
  estimateInputs: EstimateFormInputs;
  dynamicInputs: DynamicFormInputs;
  artycComparisons: ArtycComparison[];
  industryComparisons: string[];
  considerations?: string;
}

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType &
    (string | number)]: ObjectType[Key] extends infer InnerType
    ? InnerType extends object
      ? InnerType extends Array<any>
        ? `${Key}`
        : `${Key}` | `${Key}.${NestedKeyOf<InnerType>}`
      : `${Key}`
    : never;
}[keyof ObjectType & (string | number)];

export type FormInputPaths = NestedKeyOf<FormInputs>;

export interface CustomerCostReportFormBody {
  version: string;
  companyName: string;
  imageId?: string;
  deviceType: DeviceType;
  payload: PayloadTypeEnum;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  estimateInputs?: object;
  dynamicInputs?: object;
  artycComparisons: ArtycComparison[];
  industryComparisons: string[];
  considerations?: string;
  // only set for industry estimates
  industryEstimateName?: string;
}

export type CustomerCostReportEntity = CustomerCostReportFormBody & {
  _id: string;
  createdAt: string;
  updatedAt: string;
  contactFullName?: string;
  companyLogoUrl?: string;
};
