import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberInput from '../../../../../aurora/components/NumberInput/NumberInput';
import { updateFormInputs } from '../../../../../state/customerCostReports';
import { PricingModel } from '../../../../../state/customerCostReports/types';
import { RootState } from '../../../../../state/store';
import styles from './Report.module.scss';

interface Props {
  printVersion?: boolean;
}

const NumberToggles = ({ printVersion }: Props) => {
  const dispatch = useDispatch();

  const setNumShipments = (num: number) => {
    dispatch(
      updateFormInputs({
        path: 'dynamicInputs.numShipments',
        value: num,
      })
    );
  };

  const setNumShippers = (num: number) => {
    dispatch(
      updateFormInputs({
        path: 'dynamicInputs.numShippers',
        value: num,
      })
    );
  };

  const { numShipments, numShippers } = useSelector(
    (state: RootState) => state.customerCostReports.formInputs.dynamicInputs
  );

  const artycComparisons = useSelector(
    (state: RootState) =>
      state.customerCostReports.selectedReport?.artycComparisons
  );
  const hasLease =
    (artycComparisons || []).filter(
      (comparison) => comparison.pricingModel === PricingModel.Lease
    ).length > 0;

  if (printVersion) {
    return null;
  }

  return (
    <div className={styles.toggleContainer}>
      <NumberInput
        label="Total Shipments"
        managedNum={numShipments}
        onChange={(num) => {
          if (num !== null) {
            setNumShipments(num);
          }
        }}
        showIncrement
        minNum={1}
      />
      {hasLease ? (
        <NumberInput
          label="Total Shippers"
          managedNum={numShippers}
          onChange={(num) => {
            if (num !== null) {
              setNumShippers(num);
            }
          }}
          showIncrement
          minNum={1}
        />
      ) : null}
    </div>
  );
};

export default NumberToggles;
