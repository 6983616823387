import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShippingProfileEntity } from './types';

interface ShippingProfileState {
  profiles: ShippingProfileEntity[];
}

const initialState: ShippingProfileState = {
  profiles: [],
};

const shippingProfileSlice = createSlice({
  name: 'shippingProfiles',
  initialState,
  reducers: {
    setProfiles: (
      state,
      { payload }: PayloadAction<ShippingProfileEntity[]>
    ) => {
      state.profiles = payload;
    },
  },
});

export const { setProfiles } = shippingProfileSlice.actions;

export default shippingProfileSlice.reducer;
