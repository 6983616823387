import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { initializeMap, LegendData, setupMap } from './mapCreation';
import { SegmentToSnapshotsMap } from '../../state/segments';
import styles from './TestingMap.module.scss';

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

export interface TestingMapProps {
  segments: SegmentToSnapshotsMap;
}

const TestingMap = ({ segments }: TestingMapProps) => {
  const [legendData, setLegendData] = useState<
    { segmentId: string; startColor: string; endColor: string }[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      const { map, popup } = initializeMap(mapContainerRef.current);

      const legendDataTemp: LegendData[] = [];

      setupMap(map, popup, segments, legendDataTemp, setLegendData);

      map.on('error', (error) => {
        setError(`Error loading map:', ${JSON.stringify(error)}`);
      });

      return () => {
        if (map) {
          map.remove();
        }
      };
    }
  }, [segments]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.mapContainer}>
      <div ref={mapContainerRef} className={styles.map}></div>
      <div className={styles.mapLegend}>
        <ul className={styles.legendUnorderedList}>
          {legendData.map(({ segmentId, startColor, endColor }) => (
            <li key={segmentId} className={styles.legendListItem}>
              <div>{`Segment ID: ${segmentId}`}</div>
              <div
                className={styles.legendGradient}
                style={{
                  background: `linear-gradient(to right, ${startColor}, ${endColor})`,
                }}
              ></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TestingMap;
