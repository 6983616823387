import React, { useState } from 'react';
import DateRangeCalendar, {
  DateRangeType,
  DateType,
} from './DateRangeCalendar';
import Filter, { PopoverContentProps } from '../Filter/Filter';
import { formatDateMonthDay } from '../../../utils/dateUtil';

export interface DateRangePickerProps {
  maxDate?: Date;
  onSetRange?: (startDate: DateType, endDate: DateType) => void;
  // if a parent component wants to manage the range themselves, they can define this
  managedRange?: DateRangeType;
}

export const DateRangePicker = ({
  maxDate,
  onSetRange,
  managedRange,
}: DateRangePickerProps) => {
  // maintain state within this component so that managedRange can work properly
  const [range, setRange] = useState<DateRangeType>([undefined, undefined]);
  const usedRange = managedRange || range;

  const toFiltersSummary = (range: DateRangeType) =>
    `${formatDateMonthDay(range[0])} - ${formatDateMonthDay(range[1])}`;
  const onSubmit = (range: DateRangeType) => {
    setRange(range);
    onSetRange && onSetRange(range[0], range[1]);
  };
  const onClear = () => {
    onSubmit([undefined, undefined]);
  };

  const calendarPopover = ({
    onSubmit,
    onCancel,
  }: PopoverContentProps<DateRangeType>) => {
    return (
      <DateRangeCalendar
        initialRange={usedRange}
        onClose={onCancel}
        onSetRange={onSubmit}
        maxDate={maxDate}
      />
    );
  };

  return (
    <Filter
      placeholder="Select Dates"
      showPlaceholderFiltered={false}
      popoverContent={calendarPopover}
      onClear={onClear}
      onSubmit={onSubmit}
      toFiltersSummary={toFiltersSummary}
    />
  );
};
