import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconChartArrow: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <Path
      d="M3 14.4L7.65251 9.74749C7.7892 9.6108 8.0108 9.6108 8.14749 9.74749L10.4525 12.0525C10.5892 12.1892 10.8108 12.1892 10.9475 12.0525L16.3 6.7M12.8 6H16.65C16.8433 6 17 6.1567 17 6.35V10.2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </IconBase>
);
