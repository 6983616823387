import React from 'react';
import StatusBadge from '../../../../aurora/components/StatusBadge/StatusBadge';
import { JourneyStatus } from '../../../../state/journeys/types';

interface Props {
  status: JourneyStatus;
}
const JourneyStatusBadge = ({ status }: Props) => {
  if (status === JourneyStatus.PENDING) {
    return <StatusBadge content="Not Started" variant="gray" />;
  } else if (status === JourneyStatus.PRECOOLING) {
    return <StatusBadge content="Pre-Cooling" variant="blue" />;
  } else if (status === JourneyStatus.READY_TO_SHIP) {
    return <StatusBadge content="Ready To Ship" variant="orange" />;
  } else if (status === JourneyStatus.IN_PROGRESS) {
    return <StatusBadge content="In Transit" variant="purple" />;
  } else {
    return <StatusBadge content="Complete" variant="green" />;
  }
};

export default JourneyStatusBadge;
