import React, { PropsWithChildren } from 'react';
import { useSx } from 'dripsy';
import baseTheme, { BaseTheme } from '../../theme/baseTheme';

interface Props {
  size?: keyof BaseTheme['text']['heading'];
  color?: keyof BaseTheme['colors']['textColor'];
  className?: string;
}

export const Heading = ({
  children,
  size,
  color,
  className,
}: PropsWithChildren<Props>) => {
  const sxProp = useSx();
  let combinedStyles = {};

  if (size !== undefined) {
    const textSx = baseTheme['text']['heading'][size];
    combinedStyles = { ...combinedStyles, ...textSx };
  }

  if (color !== undefined) {
    const colorSx = baseTheme['colors']['textColor'][color];
    combinedStyles = { ...combinedStyles, color: colorSx };
  }

  const sxStyles = sxProp(combinedStyles);

  return (
    <div className={className} style={sxStyles}>
      {children}
    </div>
  );
};
