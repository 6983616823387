import React from 'react';
import { useDispatch } from 'react-redux';
import { setSearchFilter } from '../../../state/companies';
import TableSearch from '../../../aurora/components/TableSearch/TableSearch';

const CompanySearch = () => {
  const dispatch = useDispatch();

  return (
    <TableSearch
      placeholder="Search by name"
      onChange={(text) => {
        dispatch(setSearchFilter(text));
      }}
    />
  );
};

export default CompanySearch;
