import React from 'react';
import {
  SegmentEntity,
  SegmentSnapshotEntity,
} from '../../../../../state/segments/types';
import { Text } from '../../../../../aurora/typography/Text/Text';
import {
  formatDate,
  formatGraphTime,
  secondsToDuration,
} from '../../../../../utils/dateUtil';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import { Heading } from '../../../../../aurora/typography/Heading/Heading';
import { Label } from '../../../../../aurora/typography/Label/Label';
import styles from '../JourneyPageContainer.module.scss';

interface Tag {
  label: string;
  value: string | number | undefined;
  artycOnly?: boolean;
}

interface Props {
  shipment: ShipmentEntity;
  segment: SegmentEntity | undefined;
  snapshots: SegmentSnapshotEntity[];
  isArtycViewer: boolean;
}
const ShipmentInfoCard = ({ segment, snapshots, isArtycViewer }: Props) => {
  // TODO: remove this once we actually use shipment entity
  if (!segment) {
    return null;
  }

  // TODO: use shipment start time and end time instead of segment
  const tags: Tag[] = [
    {
      label: 'Last Updated',
      value:
        snapshots.length > 0
          ? formatGraphTime(snapshots[snapshots.length - 1].createdAt)
          : undefined,
      artycOnly: true,
    },
    { label: 'Start Date', value: segment && formatDate(segment.startTime) },
    { label: 'End Date', value: segment && formatDate(segment.endTime) },
    {
      label: 'Duration',
      value: segment && secondsToDuration(segment.lengthSec),
    },
    {
      label: 'Snapshots',
      value: snapshots.length > 0 ? snapshots.length : undefined,
      artycOnly: true,
    },
    {
      label: 'Remaining Battery',
      value:
        snapshots.length > 0
          ? `${snapshots[snapshots.length - 1].soc}%`
          : undefined,
      artycOnly: true,
    },
  ];

  // TODO: give shipment a pid and remove the segment one
  return (
    <div>
      {segment && (
        <>
          <Heading size="h4" color="strong900">
            {segment.pid}
          </Heading>
          <div className={styles.divider} />
        </>
      )}
      <div className={styles.textSection}>
        {tags
          .filter(
            (tag) =>
              (!tag.artycOnly || (tag.artycOnly && isArtycViewer)) &&
              tag.value !== undefined
          )
          .map((tag, idx) => (
            <div key={`tag-${idx}`}>
              <Label span size="sm" color="strong900">
                {tag.label}:&nbsp;
              </Label>
              <Text span size="sm" color="sub600">
                {tag.value}
              </Text>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShipmentInfoCard;
