import React from 'react';
import DeviceTypePopover from './DeviceTypePopover';
import { useDispatch, useSelector } from 'react-redux';
import Filter, {
  PopoverContentProps,
} from '../../../aurora/components/Filter/Filter';
import { DeviceType } from '../../../state/devices/types';
import styles from './DeviceTypeFilter.module.scss';
import { RootState } from '../../../state/store';
interface DeviceTypeFilterProps {
  setDeviceTypesAction: (deviceTypes?: DeviceType[]) => any;
  filteredDeviceTypes?: DeviceType[];
}

const DeviceTypeFilter = ({
  setDeviceTypesAction,
  filteredDeviceTypes = [],
}: DeviceTypeFilterProps) => {
  const dispatch = useDispatch();
  const deviceTypes = useSelector(
    (state: RootState) => state.devices.deviceTypeList
  );

  const onClear = () => {
    if (filteredDeviceTypes?.length !== 0) {
      dispatch(setDeviceTypesAction(undefined));
    }
  };

  const onFilter = (deviceTypes: DeviceType[]) =>
    dispatch(setDeviceTypesAction(deviceTypes));

  const toFiltersSummary = (deviceTypes: DeviceType[]) =>
    deviceTypes.length.toString();

  const popover = ({
    onSubmit,
    onCancel,
    onClear,
  }: PopoverContentProps<DeviceType[]>) => {
    return (
      <DeviceTypePopover
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClear={onClear}
        deviceTypes={deviceTypes}
        filteredDeviceTypes={filteredDeviceTypes || []}
      />
    );
  };

  return (
    <Filter
      placeholder="Shipper"
      popoverContent={popover}
      onSubmit={onFilter}
      onClear={onClear}
      toFiltersSummary={toFiltersSummary}
      className={styles.deviceTypeFilter}
    />
  );
};

export default DeviceTypeFilter;
