import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCompany: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M3 21H4M21 21H20M4 21V7.3009C4 5.84322 4 5.11439 4.30408 4.62793C4.5704 4.2019 4.98731 3.89171 5.4719 3.75904C6.02521 3.60756 6.72331 3.81699 8.11951 4.23585L11.7195 5.31585C12.5378 5.56133 12.9469 5.68407 13.2502 5.92697C13.5179 6.1414 13.726 6.42109 13.8545 6.73916C14 7.09948 14 7.52662 14 8.3809V10M4 21H9M14 21V10M14 21H20M14 21H9M14 10L17.894 8.70199C18.6051 8.46498 18.9606 8.34648 19.2435 8.41753C19.4912 8.47973 19.7058 8.63435 19.8431 8.84968C20 9.09566 20 9.4704 20 10.2199V21M9 9V9.01M9 11.99V12M17 12.99V13M17 16V16.01M9 21V16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
