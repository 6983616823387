import React from 'react';
import { ColumnsConfig } from './tableConfig';
import { useSx } from 'dripsy';
import { bulkActionBarSectionStyle, bulkActionBarStyle } from './tableStyle';
import { Button } from '../../aurora/components/Button/Button';
import { IconClose } from '../../aurora/icons';
import { Text } from '../../aurora/typography/Text/Text';

interface Props<T> {
  selected: T[];
  columnsConfig: ColumnsConfig<T>;
  clearAll: () => void;
}
const BulkActionBar = <T,>({ selected, columnsConfig, clearAll }: Props<T>) => {
  const sx = useSx();
  const { bulkAction } = columnsConfig;
  if (bulkAction === undefined) {
    return null;
  }

  if (selected.length === 0) {
    return null;
  }

  const itemsText = `${selected.length} item${
    selected.length === 1 ? '' : 's'
  }`;

  return (
    <div style={sx(bulkActionBarStyle)}>
      <div style={sx(bulkActionBarSectionStyle)}>
        <Button
          iconLeft={IconClose}
          variant="primary"
          onClickIcon={clearAll}
          disableHover={true}
        />
        <Text size="md" sx={{ color: 'white', fontWeight: '500' }}>
          {itemsText} selected
        </Text>
      </div>
      <div style={sx(bulkActionBarSectionStyle)}>{bulkAction(selected)}</div>
    </div>
  );
};

export default BulkActionBar;
