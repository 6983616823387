import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconArrowLeft: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M18.59 12H5.58597M10.5875 6L5.29298 11.2929C4.90234 11.6834 4.90234 12.3166 5.29298 12.7071L10.5875 18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
