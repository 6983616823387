import { EntityState } from '../types';

export enum ShipperEnvSwitchStatus {
  PENDING = 'pending',
  SHIPPER_UPDATED = 'shipperUpdated',
  SWITCH_COMPLETE = 'switchComplete',
}

export enum EnvEnum {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export interface SelfHealthTestEntity {
  device: string;
  pass: boolean;
  unitTempChangeC: number;
  hsTempC: number;
  unitTempChangeCTS?: number;
  tempSensorStatus?: boolean;
  createdAt: string;
}

export interface DeviceEntity {
  _id: string;
  deviceType: string;
  serialNumber: string;
  companyName: string;
  companyId: string;
  pcbNumber: string;
  boardVersion: string;
  macAddress: string;
  firmwareVersion?: string;
  currentEnvironment: EnvEnum;
  envSwitchStatus: ShipperEnvSwitchStatus;
  newEnvironment?: EnvEnum | null;
  isInCurrentEnv: boolean;
  latestSelfHealthTest?: SelfHealthTestEntity;
}

export enum DeviceType {
  MedstowMicro = 'Medstow Micro',
  Medstow5LI = 'Medstow 5L-I',
}

export interface DeviceFilters {
  search?: string;
  companyIds?: string[];
  deviceTypes?: DeviceType[];
}

export interface DeviceState extends EntityState<DeviceEntity> {
  selectedFilters?: DeviceFilters;
  deviceTypeList: DeviceType[] | null;
}
