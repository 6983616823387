import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../../aurora/components/Button/Button';
import styles from './CompanyForm.module.scss';
import { CompanyEntity, SalesModelEnum } from '../../../state/companies/types';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import { showToast } from '../../../aurora/components/Toast/Toast';
import Select from '../../../aurora/components/Select/Select';
import { JourneyTypeEnum } from '../../../state/journeys/types';

interface Props {
  company?: CompanyEntity;
  onSubmit: (
    companyName: string,
    salesModel: SalesModelEnum,
    defaultJourneyType: JourneyTypeEnum
  ) => Promise<any>;
  onClose: () => void;
  successMessage: string;
  errorMessage: string;
}
const CompanyForm = ({
  company,
  onSubmit,
  onClose,
  successMessage,
  errorMessage,
}: Props) => {
  const [companyName, setCompanyName] = useState(company?.companyName || '');
  const [salesModel, setSalesModel] = useState(
    company?.salesModel || SalesModelEnum.CaaS
  );
  const [defaultJourneyType, setJourneyType] = useState<JourneyTypeEnum>(
    company?.defaultJourneyType || JourneyTypeEnum.SHIPPABLE
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef<HTMLInputElement>(null);
  // set form focus
  useEffect(() => {
    formRef.current?.focus();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(companyName, salesModel, defaultJourneyType);

      onClose();
      showToast({
        type: 'success',
        title: 'Success!',
        text: successMessage,
      });
    } catch (err) {
      showToast({
        type: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const salesModelOptions = Object.values(SalesModelEnum).map((type) => ({
    value: type,
    label: type,
  }));
  const defaultJourneyTypeOptions = Object.values(JourneyTypeEnum).map(
    (type) => ({
      value: type,
      label: type,
    })
  );

  return (
    <>
      <form className={styles.form}>
        <TextInput
          label="Company Name"
          onChange={setCompanyName}
          variant="form"
          managedText={companyName}
        />
        <Select
          label="Sales Model"
          placeholder="Select Sales Model"
          options={salesModelOptions}
          onChange={(option) => setSalesModel(option.value as SalesModelEnum)}
          variant="form"
          managedValue={salesModel}
        />
        <Select
          label="Default Journey Type"
          placeholder="Select Default Journey Type"
          options={defaultJourneyTypeOptions}
          onChange={(option) => setJourneyType(option.value as JourneyTypeEnum)}
          variant="form"
          managedValue={defaultJourneyType}
        />
        <div className={styles.buttonContainer}>
          <Button
            variant="secondary"
            disabled={isSubmitting}
            label="Cancel"
            onClick={onClose}
          />
          <Button
            variant="primary"
            disabled={isSubmitting || companyName === ''}
            label="Save"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default CompanyForm;
