import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCalculator: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M12 2L12 22M2 12H22M7.45455 5.63636V9.27273M17.8311 15.2598L15.2598 17.8311M9.27273 15.6364L6.86687 18.0422C6.68936 18.2197 6.40155 18.2197 6.22404 18.0422L5.63636 17.4545M9.27273 7.45455H5.63636M17.8311 17.8311L15.2598 15.2598M18.3636 7.45455H14.7273M9.27273 22H14.7273C17.273 22 18.5458 22 19.5181 21.5046C20.3734 21.0688 21.0688 20.3734 21.5046 19.5181C22 18.5458 22 17.273 22 14.7273V9.27273C22 6.72703 22 5.45419 21.5046 4.48186C21.0688 3.62658 20.3734 2.93121 19.5181 2.49542C18.5458 2 17.273 2 14.7273 2H9.27273C6.72703 2 5.45419 2 4.48186 2.49542C3.62658 2.93121 2.93121 3.62658 2.49542 4.48186C2 5.45419 2 6.72703 2 9.27273V14.7273C2 17.273 2 18.5458 2.49542 19.5181C2.93121 20.3734 3.62658 21.0688 4.48186 21.5046C5.45419 22 6.72703 22 9.27273 22Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
