import React, { useState } from 'react';
import Checkbox from '../../../aurora/components/Checkbox/Checkbox';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import { IconSearch } from '../../../aurora/icons';
import { Text } from '../../../aurora/typography/Text/Text';
import styles from './MobileChecklistFilter.module.scss';

export interface ChecklistOption {
  label: string;
  id: string;
}

export interface ChecklistSectionProp {
  title: string;
  options: ChecklistOption[];
  selectedIds: string[];
  hasSearch?: boolean;
  searchPlaceholder?: string;
  show?: boolean;
}

type Props = ChecklistSectionProp & {
  onChange: (selectedIds: string[]) => void;
};

const ChecklistSection = ({
  title,
  hasSearch,
  searchPlaceholder,
  onChange,
  options,
  selectedIds,
  show,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  if (show !== undefined && !show) {
    return null;
  }

  const onSearch = (text: string) => setSearchQuery(text);

  const searchBar = hasSearch ? (
    <section className={styles.search}>
      <TextInput
        variant="filter"
        placeholder={searchPlaceholder}
        iconLeft={IconSearch}
        onChange={onSearch}
      />
    </section>
  ) : null;

  const mkSelectedIds = (selectingId: string, isChecked: boolean) => {
    const without = selectedIds.filter((id) => id !== selectingId);
    if (isChecked) {
      return [...without, selectingId];
    }
    return without;
  };

  const filteredOptions = options.filter((option) =>
    searchQuery === '' ? true : option.label.includes(searchQuery)
  );
  const optionComponents = filteredOptions.map((option) => (
    <Checkbox
      key={option.id}
      label={option.label}
      onChange={(isChecked) => onChange(mkSelectedIds(option.id, isChecked))}
      managedChecked={selectedIds.includes(option.id)}
      labelClassName={styles.checkbox}
    />
  ));

  return (
    <>
      {searchBar}
      <section>
        <Text size="md" className={styles.titleText}>
          {title}
        </Text>
        {optionComponents}
      </section>
    </>
  );
};

export default ChecklistSection;
