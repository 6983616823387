import { useCallback } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { clearAuth } from '../state/auth';
import { useDispatch } from 'react-redux';

const useLogout = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const logout = useCallback(async () => {
    try {
      await axiosPrivate.post('/auth/logout');
      dispatch(clearAuth());
    } catch (err) {
      console.log('Error logging out:', err);
    }
  }, [axiosPrivate]);

  return logout;
};

export default useLogout;
