import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconHamburger: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <Path
      d="M3 6H21M3 12H21M3 18H21"
      stroke="#383D43"
      strokeWidth="2"
      strokeLinecap="round"
      fill="currentColor"
    />
  </IconBase>
);
