import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './calendar.scss';
import { Button } from '../Button/Button';
import { useSx } from 'dripsy';
import { DateType } from './DateRangeCalendar';
import styles from './DateCalendar.module.scss';

interface Props {
  initialDate: DateType;
  onSetDate: (date: DateType) => void;
  onClose?: () => void;
  excludeButtons?: boolean;
  minDate?: Date;
}

const DateCalendar = ({
  onSetDate,
  onClose,
  initialDate,
  minDate,
  excludeButtons = false,
}: Props) => {
  const [date, setDate] = useState<DateType>(initialDate);

  useEffect(() => {
    if (excludeButtons && date !== initialDate) {
      onSetDate(date);
    }
  }, [date, onSetDate]);

  const onContinue = () => onSetDate(date);

  const sx = useSx();
  const selectedDatesColor = sx({
    color: 'gray600v1',
    backgroundColor: 'primary25',
  });

  return (
    <>
      <Calendar
        editableDateInputs={true}
        onChange={(item) => setDate(item)}
        date={date}
        color={selectedDatesColor.color}
        rangeColors={[selectedDatesColor.backgroundColor]}
        minDate={minDate}
        className={styles.calendarContainer}
      />
      {!excludeButtons && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button variant="secondary" label="Cancel" onClick={onClose} />
          <Button variant="primary" label="Apply" onClick={onContinue} />
        </div>
      )}
    </>
  );
};

export default DateCalendar;
