import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconArrowDown: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M12 5L12 18.004M18 13.0025L12.7071 18.297C12.3166 18.6877 11.6834 18.6877 11.2929 18.297L6 13.0025"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
