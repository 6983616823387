import React, { useState } from 'react';
import Checkbox from '../../../aurora/components/Checkbox/Checkbox';
import { CompanyEntity } from '../../../state/companies/types';
import styles from './CompanyFilter.module.scss';

interface Props {
  companies: CompanyEntity[];
  onChange: (selectedCompanyIds: string[]) => void;
  selectedCompanyIds: string[];
}
const CompanyCheckboxList = ({
  companies,
  onChange,
  selectedCompanyIds,
}: Props) => {
  const initialCompanyMap = selectedCompanyIds.reduce((map, id) => {
    map[id] = true;
    return map;
  }, {} as Record<string, boolean>);
  const [companyMap, setCompanyMap] = useState(initialCompanyMap);

  const setCompany = (company: CompanyEntity, isChecked: boolean) => {
    const newCompanyMap = {
      ...companyMap,
      [company._id]: isChecked,
    };

    setCompanyMap(newCompanyMap);

    const selectedCompanies = Object.keys(newCompanyMap).filter(
      (companyId) => newCompanyMap[companyId]
    );
    onChange(selectedCompanies);
  };

  const companiesList = companies.map((company) => (
    <Checkbox
      key={company._id}
      label={company.companyName}
      onChange={(isChecked) => setCompany(company, isChecked)}
      defaultChecked={selectedCompanyIds.includes(company._id)}
      labelClassName={styles.checkbox}
    />
  ));

  return <div className={styles.companiesList}>{companiesList}</div>;
};

export default CompanyCheckboxList;
