import React, { useState } from 'react';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import FormInputContainer from '../common/FormInputContainer';
import PathNumberInput from '../common/PathNumberInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import { CostItem } from '../../../../../../state/customerCostReports/types';
import { updateFormInputs } from '../../../../../../state/customerCostReports';
import { IconPlus } from '../../../../../../aurora/icons';
import { Text } from '../../../../../../aurora/typography/Text/Text';
import CreateNewTrackingSensor, {
  NewSensorState,
} from './CreateNewTrackingSensor';
import { showToast } from '../../../../../../aurora/components/Toast/Toast';
import styles from '../Form.module.scss';

const TrackingCostsSection = () => {
  const [addingSensor, setAddingSensor] = useState(false);
  const [newSensor, setNewSensor] = useState<NewSensorState>({
    name: '',
    costDollars: null,
  });
  const dispatch = useDispatch();
  const formInputs = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );
  const initialData = useSelector(
    (state: RootState) =>
      state.customerCostReports.selectedReport?.estimateInputs
  ) as any;

  const handleAddSensor = () => {
    setAddingSensor(true);
  };

  const handleSubmitNewSensor = () => {
    if (newSensor.name && newSensor.costDollars !== null) {
      const updatedSensors: CostItem<string>[] = [
        ...formInputs.estimateInputs.trackingInputs.sensors,
        newSensor as CostItem<string>,
      ];
      dispatch(
        updateFormInputs({
          path: 'estimateInputs.trackingInputs.sensors',
          value: updatedSensors,
        })
      );
      setAddingSensor(false);
      setNewSensor({ name: '', costDollars: null });
    } else {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Sensor name and cost are required',
      });
    }
  };

  const handleCancelNewSensor = () => {
    setAddingSensor(false);
    setNewSensor({ name: '', costDollars: null });
  };

  const getToggleSwitchProps = (sensorType: string) => ({
    defaultValue: initialData?.trackingInputs.sensors.some(
      (sensor: CostItem<string>) => sensor.name === sensorType
    ),
    onSwitchOff: () => {
      const updatedSensors =
        formInputs.estimateInputs.trackingInputs.sensors.filter(
          (sensor: CostItem<string>) => sensor.name !== sensorType
        );
      dispatch(
        updateFormInputs({
          path: 'estimateInputs.trackingInputs.sensors',
          value: updatedSensors,
        })
      );
    },
  });

  const fixedSensors = ['Temptail', 'GPS'];

  const renderSensorInputContainer = (sensorType: string) => (
    <FormInputContainer
      key={sensorType}
      title={sensorType}
      toggleSwitch={getToggleSwitchProps(sensorType)}
      inputs={(disabled: boolean) => (
        <PathNumberInput
          path="estimateInputs.trackingInputs.sensors"
          label="Cost"
          showIncrement
          disabled={disabled}
          labelClassName={styles.label}
          type="dollar"
          arrayItemName={sensorType}
          placeholder="$0"
          minNum={0}
        />
      )}
    />
  );

  const renderAdditionalSensors = () => {
    const addtionalSensors = formInputs.estimateInputs.trackingInputs.sensors
      .filter((sensor) => !fixedSensors.includes(sensor.name))
      .map((sensor) => renderSensorInputContainer(sensor.name));

    return addtionalSensors;
  };
  return (
    <CostCalculatorFormSection
      title="Tracking Costs"
      subtitle="Calculate expenses associated with tracking technologies tailored to the specifics of your shipment."
    >
      <div
        style={{
          display: 'flex',
          gap: '24px',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {fixedSensors.map((sensorType) =>
          renderSensorInputContainer(sensorType)
        )}
        {renderAdditionalSensors()}
        {addingSensor ? (
          <CreateNewTrackingSensor
            sensor={newSensor}
            onNameChange={(name: string) =>
              setNewSensor((prev) => ({ ...prev, name }))
            }
            onCostChange={(costDollars: number | null) =>
              setNewSensor((prev) => ({ ...prev, costDollars }))
            }
            onSubmit={handleSubmitNewSensor}
            onCancel={handleCancelNewSensor}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleAddSensor}
            >
              <IconPlus width={16} height={16} color="primary600" />
              <Text
                size="sm"
                sx={{
                  color: 'primary600',
                  fontWeight: '500',
                  lineHeight: '16px',
                }}
              >
                Add Other
              </Text>
            </div>
          </div>
        )}
      </div>
    </CostCalculatorFormSection>
  );
};

export default TrackingCostsSection;
