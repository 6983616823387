import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconSnow: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase {...props} viewBox="0 0 16 16">
    <Path
      d="M12.1954 5.55L3.70838 10.45M12.1954 5.55L12.9641 2.68135M12.1954 5.55L15.0641 6.31865M3.70838 10.45L0.839722 9.68135M3.70838 10.45L2.93972 13.3187M12.1954 10.4499L3.70832 5.5499M12.1954 10.4499L15.0641 9.68126M12.1954 10.4499L12.9641 13.3186M3.70832 5.5499L2.93982 2.68142M3.70832 5.5499L0.839821 6.31873M7.9519 3.1L7.9519 12.9M7.9519 3.1L5.8519 1M7.9519 3.1L10.0519 1M7.9519 12.9L5.8519 15M7.9519 12.9L10.0519 15"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);
