import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCheck: React.FunctionComponent<IconProps> = ({
  strokeWidth,
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M20 7L9.70711 17.297C9.31658 17.6877 8.68342 17.6877 8.29289 17.297L4 13.0024"
      stroke="currentColor"
      strokeWidth={strokeWidth || 2}
      strokeLinecap="round"
    />
  </IconBase>
);
