import { SxProp } from 'dripsy';

export const newStatusStyle: SxProp = {
  backgroundColor: 'primary600',
  color: 'white',
  width: 'fit-content',
  padding: '2px 10px',
  borderRadius: '128px',
  margin: 'auto',
  fontSize: '15px',
};

export const reviewedStatusStyle: SxProp = {
  backgroundColor: 'neutral100',
  color: 'gray600',
  width: 'fit-content',
  padding: '2px 10px',
  borderRadius: '128px',
  margin: 'auto',
  fontSize: '15px',
};

export const actionStyle: SxProp = {
  display: 'flex',
  justifyContent: 'center',
  color: 'gray600',
};
