import { AxiosInstance, AxiosResponse } from 'axios';
import { get, post, patch } from '../hooks/useAxiosPrivate';
import {
  CustomerCostReportEntity,
  CustomerCostReportFormBody,
} from '../state/customerCostReports/types';

const OPEN_CUSTOMER_COST_REPORT_URL = '/api/v1/customer-cost-reports';
const ARTYC_CUSTOMER_COST_REPORT_URL = '/artyc/customer-cost-reports';

const CustomerCostReportApi = {
  getOpenCustomerCostReport: async (
    axios: AxiosInstance,
    id: string
  ): Promise<CustomerCostReportEntity> => {
    const response: AxiosResponse<CustomerCostReportEntity> = await get(
      axios,
      `${OPEN_CUSTOMER_COST_REPORT_URL}/${id}`
    );

    return response.data;
  },
  getArtycCustomerCostReport: async (
    axios: AxiosInstance,
    id: string
  ): Promise<CustomerCostReportEntity> => {
    const response: AxiosResponse<CustomerCostReportEntity> = await get(
      axios,
      `${ARTYC_CUSTOMER_COST_REPORT_URL}/${id}`
    );

    return response.data;
  },
  createCustomerCostReport: async (
    axios: AxiosInstance,
    data: CustomerCostReportFormBody
  ): Promise<CustomerCostReportEntity> => {
    const response: AxiosResponse<CustomerCostReportEntity> = await post(
      axios,
      ARTYC_CUSTOMER_COST_REPORT_URL,
      data
    );
    return response.data;
  },
  updateCustomerCostReport: async (
    axios: AxiosInstance,
    reportId: string,
    data: CustomerCostReportFormBody
  ): Promise<CustomerCostReportEntity> => {
    const response: AxiosResponse<CustomerCostReportEntity> = await patch(
      axios,
      `${ARTYC_CUSTOMER_COST_REPORT_URL}/${reportId}`,
      data
    );
    return response.data;
  },
  uploadImage: async (
    axios: AxiosInstance,
    file: File
  ): Promise<{ imageUrl: string }> => {
    const formData = new FormData();
    formData.append('image', file);

    const response: AxiosResponse<{ imageUrl: string }> = await axios.post(
      `${ARTYC_CUSTOMER_COST_REPORT_URL}/image`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
      }
    );

    return response.data;
  },
  getIndustryEstimates: async (
    axios: AxiosInstance
  ): Promise<CustomerCostReportEntity[]> => {
    const response: AxiosResponse<CustomerCostReportEntity[]> = await get(
      axios,
      `${OPEN_CUSTOMER_COST_REPORT_URL}/industry-estimates`
    );

    return response.data;
  },
};

export default CustomerCostReportApi;
