import React, { PropsWithChildren } from 'react';
import { SxProp, useSx } from 'dripsy';
import baseTheme, { BaseTheme } from '../../theme/baseTheme';

interface Props {
  size?: keyof BaseTheme['text']['text'];
  color?: keyof BaseTheme['colors']['textColor'];
  error?: boolean;
  className?: string;
  span?: boolean;

  // legacy, move towards deprecating
  sx?: SxProp;
}

export const Text = ({
  children,
  size,
  color,
  error,
  className,
  sx,
  span,
}: PropsWithChildren<Props>) => {
  const sxProp = useSx();
  let combinedStyles = { ...sx };

  if (size !== undefined) {
    const textSx = baseTheme['text']['text'][size];
    combinedStyles = { ...combinedStyles, ...textSx };
  }

  if (color !== undefined) {
    const colorSx = baseTheme['colors']['textColor'][color];
    combinedStyles = { ...combinedStyles, color: colorSx };
  }
  if (error) {
    const colorSx = baseTheme['colors']['error']['base'];
    combinedStyles = { ...combinedStyles, color: colorSx };
  }

  const sxStyles = sxProp(combinedStyles);
  const Component = span ? 'span' : 'div';

  return (
    <Component className={className} style={sxStyles}>
      {children}
    </Component>
  );
};
