import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import {
  DatePicker,
  DatePickerProps,
} from '../../aurora/components/CalendarPicker/DatePicker';

type DatePickerFieldProps = DatePickerProps & {
  name: string;
  validate?: FieldValidator<string>;
};
const DatePickerField = ({
  name,
  validate,
  ...props
}: DatePickerFieldProps) => {
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta }) => {
        const hasError = meta.touched && meta.error !== undefined;

        return (
          <DatePicker
            {...props}
            variant="form"
            managedDate={input.value === '' ? undefined : new Date(input.value)}
            onSetDate={(date) => input.onChange(date)}
            hint={hasError ? meta.error : undefined}
            error={hasError}
          />
        );
      }}
    />
  );
};

export default DatePickerField;
