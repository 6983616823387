import { ShippoCarrierEnum } from '../state/shipments/types';

export const createTrackingUrl = (
  carrier: ShippoCarrierEnum,
  trackingNumber: string
): string => {
  const urlMap = {
    [ShippoCarrierEnum.FEDEX]: `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`,
    [ShippoCarrierEnum.UPS]: `https://www.ups.com/track?InquiryNumber1=${trackingNumber}`,
  };

  return urlMap[carrier];
};
