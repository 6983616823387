import React, { ComponentType } from 'react';
import { IconProps } from '../../aurora/icons/IconBase';
import { Button } from '../../aurora/components/Button/Button';
import { Tooltip } from '../../aurora/components/Tooltip/Tooltip';

interface Props {
  onClick: () => void;
  icon: ComponentType<IconProps>;
  disabled?: boolean;
  label: string;
}

const ActionButton = ({ onClick, icon, disabled, label }: Props) => {
  return (
    <>
      <Tooltip label={label} placement={'top'}>
        <Button
          variant="ghostSecondaryHoverBackground"
          round
          iconLeft={icon}
          onClick={onClick}
          disabled={disabled}
        />
      </Tooltip>
    </>
  );
};

export default ActionButton;
