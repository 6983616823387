import React from 'react';
import { Heading } from '../../../../../aurora/typography/Heading/Heading';
import { useSx } from 'dripsy';
import SaveButton from './SaveButton';
import CustomerCostSection from './customerCostSection/CustomerCostSection';
import Considerations from './Considerations';
import Comparisons from './comparisons/Comparisons';
import ContactInfoSection from './ContactInfoSection';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { useNavigate } from 'react-router-dom';

interface Props {
  adminView: boolean;
}
const CostCalculatorForm = ({ adminView }: Props) => {
  const sx = useSx();
  const navigate = useNavigate();
  const industryEstimateName = useSelector(
    (state: RootState) =>
      state.customerCostReports.selectedReport?.industryEstimateName
  );
  const isIndustryEstimate = industryEstimateName !== undefined;

  return (
    <div style={sx({ padding: '32px', color: 'gray900' })}>
      <Heading size="h3">
        {isIndustryEstimate
          ? `${industryEstimateName} Estimate`
          : 'New Estimate'}
      </Heading>

      {isIndustryEstimate ? null : <ContactInfoSection adminView={adminView} />}
      <CustomerCostSection />
      {isIndustryEstimate ? null : (
        <>
          <Comparisons />
          <Considerations />
        </>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <SaveButton
          industryEstimateName={industryEstimateName}
          variant="primary"
          label="Continue"
          onSuccess={(isCreate, reportId) => {
            navigate(`/cost-report/${reportId}`, { replace: isCreate });
          }}
        />
      </div>
    </div>
  );
};

export default CostCalculatorForm;
