import React from 'react';
import { useSelector } from 'react-redux';
import {
  CustomerCostReportEntity,
  PricingModel,
} from '../../../../../state/customerCostReports/types';
import { mkCustomerCostDollars } from '../../../../../state/customerCostReports/utils/summaryUtils';
import { RootState } from '../../../../../state/store';
import CostCalculatorSummary from '../summary/CostCalculatorSummary';
import ArtycCaasSummary from './ArtycCaasSummary';
import ArtycLeaseSummary from './ArtycLeaseSummary';
import IndustryEstimateSummary from './IndustryEstimateSummary';
import styles from './Report.module.scss';

interface Props {
  report: CustomerCostReportEntity;
}
const SummaryComparison = ({ report }: Props) => {
  const { numShipments, numShippers } = useSelector(
    (state: RootState) => state.customerCostReports.formInputs.dynamicInputs
  );
  const customerCostDollars = useSelector((state: RootState) =>
    mkCustomerCostDollars(
      state.customerCostReports.formInputs.estimateInputs,
      numShipments
    )
  );

  return (
    <div className={styles.summaryContainer}>
      <CostCalculatorSummary
        customerCostDollars={customerCostDollars}
        header={'Current Costs'}
      />
      {report.artycComparisons.map((comparison) =>
        comparison.pricingModel === PricingModel.CaaS ? (
          <ArtycCaasSummary
            key={comparison.name}
            customerCostDollars={customerCostDollars}
            priceDollars={comparison.priceDollars}
            name={comparison.name}
            numShipments={numShipments}
          />
        ) : (
          <ArtycLeaseSummary
            key={comparison.name}
            customerCostDollars={customerCostDollars}
            priceDollars={comparison.priceDollars}
            name={comparison.name}
            numShipments={numShipments}
            numShippers={numShippers}
          />
        )
      )}
      {report.industryComparisons.map((id) => (
        <IndustryEstimateSummary
          estimateId={id}
          key={id}
          numShipments={numShipments}
        />
      ))}
    </div>
  );
};

export default SummaryComparison;
