import React, { useEffect, ReactNode } from 'react';
import { IconChevronDown } from '../../icons/IconChevronDown';
import { IconChevronUp } from '../../icons/IconChevronUp';
import { SxProp, useDripsyTheme } from 'dripsy';
import {
  useFloating,
  shift,
  autoUpdate,
  flip,
  offset,
} from '@floating-ui/react';
import { useSx } from 'dripsy';
import { popoverStyles, filterInputStyles } from './filterStyles';
import { BaseTheme } from '../../theme/baseTheme';
import styles from './Filter.module.scss';

export type DropdownProps = {
  variant?: keyof BaseTheme['select'];
  text: ReactNode;
  popoverContent: ReactNode;
  className?: string;
  textSx?: SxProp;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const Dropdown = ({
  variant = 'filter',
  text,
  className,
  popoverContent,
  textSx,
  open,
  setOpen,
}: DropdownProps) => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const variantSx = theme.select[variant];

  const { refs, floatingStyles } = useFloating({
    middleware: [shift(), flip(), offset(10)],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        refs.floating.current &&
        !refs.floating.current.contains(event.target) &&
        refs.reference.current &&
        //@ts-ignore
        !refs.reference.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs.floating]);

  const Chevron = open ? IconChevronUp : IconChevronDown;
  const borderColor = open
    ? variantSx.$active.borderColor
    : variantSx.borderColor;

  const popover = open ? (
    <div
      ref={refs.setFloating}
      style={{
        ...floatingStyles,
        ...sx(popoverStyles),
      }}
    >
      {popoverContent}
    </div>
  ) : null;

  return (
    <div className={`${styles.filter} ${className}`}>
      <div
        ref={refs.setReference}
        onClick={() => setOpen(!open)}
        style={sx({
          ...filterInputStyles,
          backgroundColor: variantSx.backgroundColor,
          borderColor,
          ...textSx,
        })}
      >
        {text}
        <Chevron width={20} height={20} />
      </div>

      {popover}
    </div>
  );
};

export default Dropdown;
