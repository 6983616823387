import React, { ComponentType } from 'react';
import { IconProps } from '../../../../../aurora/icons/IconBase';
import { Text } from '../../../../../aurora/typography/Text/Text';
import InfoCard from '../InfoCard';
import styles from './GraphPlaceholder.module.scss';

interface Props {
  icon: ComponentType<IconProps>;
  title: string;
  subtitle: string;
}
const GraphPlaceholder = ({ icon: Icon, title, subtitle }: Props) => {
  return (
    <InfoCard title="Temperature Data">
      <div className={styles.container}>
        <div className={styles.icon}>
          <Icon width={20} height={20} />
        </div>
        <Text size="md" color="strong900">
          {title}
        </Text>
        <Text size="sm" color="sub600">
          {subtitle}
        </Text>
      </div>
    </InfoCard>
  );
};

export default GraphPlaceholder;
