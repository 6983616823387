import React, { useState } from 'react';
import Checkbox from '../../../aurora/components/Checkbox/Checkbox';
import { DeviceType } from '../../../state/devices/types';
import styles from './DeviceTypeFilter.module.scss';

interface Props {
  deviceTypes: DeviceType[] | null;
  onChange: (selectedDeviceTypes: DeviceType[]) => void;
  selectedDeviceTypes: DeviceType[];
}
const DeviceTypeCheckboxList = ({
  deviceTypes,
  onChange,
  selectedDeviceTypes,
}: Props) => {
  const initialDeviceTypeMap = selectedDeviceTypes.reduce((map, deviceType) => {
    map[deviceType] = true;
    return map;
  }, {} as Record<DeviceType, boolean>);
  const [deviceTypeMap, setDeviceTypeMap] = useState(initialDeviceTypeMap);

  const setDeviceType = (deviceType: DeviceType, isChecked: boolean) => {
    const newDeviceTypeMap = {
      ...deviceTypeMap,
      [deviceType]: isChecked,
    };

    setDeviceTypeMap(newDeviceTypeMap);

    const selectedTypes = Object.keys(newDeviceTypeMap).filter(
      (deviceType) => newDeviceTypeMap[deviceType as DeviceType]
    ) as DeviceType[];
    onChange(selectedTypes);
  };

  const deviceTypesList = deviceTypes ? (
    deviceTypes.map((deviceType) => (
      <Checkbox
        key={deviceType}
        label={deviceType}
        onChange={(isChecked) => setDeviceType(deviceType, isChecked)}
        defaultChecked={selectedDeviceTypes.includes(deviceType)}
        labelClassName={styles.checkbox}
      />
    ))
  ) : (
    <p>Loading...</p>
  );

  return <div className={styles.deviceTypesList}>{deviceTypesList}</div>;
};

export default DeviceTypeCheckboxList;
