import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '../../../aurora/components/Switch/Switch';
import { Text } from '../../../aurora/typography/Text/Text';
import { isArtyc, selectAuth } from '../../../state/auth';
import { toggleViewAsCustomer } from '../../../state/journeys';
import { RootState } from '../../../state/store';
import styles from './ViewAsCustomer.module.scss';

export const ViewAsCustomerToggle = () => {
  const dispatch = useDispatch();
  const viewAsCustomer = useSelector(
    (state: RootState) => state.journeys.viewAsCustomer
  );
  const auth = useSelector(selectAuth);

  if (!isArtyc(auth)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Switch
        isSwitchOn={viewAsCustomer}
        onChange={() => {
          dispatch(toggleViewAsCustomer());
        }}
      />
      <Text size="sm" color="strong900">
        View as Customer
      </Text>
    </div>
  );
};
