import React from 'react';
import FormInputContainer from '../common/FormInputContainer';
import TextInput from '../../../../../../aurora/components/TextInput/TextInput';
import NumberInput from '../../../../../../aurora/components/NumberInput/NumberInput';
import { Text } from '../../../../../../aurora/typography/Text/Text';
import styles from '../Form.module.scss';

export interface NewSensorState {
  name: string;
  costDollars: number | null;
}

interface Props {
  sensor: NewSensorState;
  onNameChange: (name: string) => void;
  onCostChange: (cost: number | null) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const CreateNewTrackingSensor = ({
  sensor,
  onNameChange,
  onCostChange,
  onSubmit,
  onCancel,
}: Props) => {
  const { name, costDollars } = sensor;
  return (
    <FormInputContainer
      title={'New Sensor'}
      inputs={() => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {' '}
          <TextInput
            variant="form"
            label="Sensor Name"
            placeholder="Enter Sensor Name"
            managedText={name}
            onChange={onNameChange}
            labelClassName={styles.label}
          />
          <NumberInput
            label={'Cost'}
            labelClassName={styles.label}
            managedNum={costDollars}
            onChange={onCostChange}
            showIncrement
            type="dollar"
            placeholder="$0"
            minNum={0}
          />
          <div style={{ alignSelf: 'flex-end', display: 'flex', gap: '12px' }}>
            <span onClick={onCancel}>
              <Text
                size="sm"
                sx={{
                  color: 'error600',
                  fontWeight: '500',
                  lineHeight: '16px',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </Text>
            </span>
            <span onClick={onSubmit}>
              <Text
                size="sm"
                sx={{
                  color: 'primary600',
                  fontWeight: '500',
                  lineHeight: '16px',
                  cursor: 'pointer',
                }}
              >
                Save
              </Text>
            </span>
          </div>
        </div>
      )}
    />
  );
};

export default CreateNewTrackingSensor;
