import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerCostReportApi from '../../../../../api/customerCostReportApi';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { setIndustryEstimates } from '../../../../../state/customerCostReports';
import { reportToFormInputs } from '../../../../../state/customerCostReports/utils/reportToFormInputs';
import { mkCustomerCostDollars } from '../../../../../state/customerCostReports/utils/summaryUtils';
import { RootState } from '../../../../../state/store';
import CostCalculatorSummary from '../summary/CostCalculatorSummary';

interface Props {
  estimateId: string;
  numShipments: number;
}
const IndustryEstimateSummary = ({ estimateId, numShipments }: Props) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();

  const industryEstimates = useSelector(
    (state: RootState) => state.customerCostReports.industryEstimates
  );

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        const estimates = await CustomerCostReportApi.getIndustryEstimates(
          axios
        );
        dispatch(setIndustryEstimates(estimates));
      } catch (error) {
        console.error('Error fetching report:', error);
      }
    };

    if (industryEstimates.length === 0) {
      fetchEstimates();
    }
  }, []);

  const estimate = industryEstimates.find(
    (estimate) => estimate._id === estimateId
  );
  if (
    industryEstimates.length === 0 ||
    estimate === undefined ||
    estimate.industryEstimateName === undefined
  ) {
    return null;
  }

  const customerCostDollars = mkCustomerCostDollars(
    reportToFormInputs(estimate).estimateInputs,
    numShipments
  );
  return (
    <CostCalculatorSummary
      header={estimate.industryEstimateName}
      customerCostDollars={customerCostDollars}
    />
  );
};

export default IndustryEstimateSummary;
