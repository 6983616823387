import React, { ComponentType } from 'react';
import { IconProps } from '../../icons/IconBase';
import { Label } from '../../typography/Label/Label';
import styles from './Badge.module.scss';

export interface BadgeProps {
  content: string;
  color: 'gray' | 'blue' | 'orange' | 'green' | 'red' | 'purple';
  style: 'filled' | 'lighter';
  iconLeft?: ComponentType<IconProps>;
}

const Badge = ({ content, color, style, iconLeft: IconLeft }: BadgeProps) => {
  const colorClassName = styles[color];
  const styleClassName = styles[style];

  const iconLeft = IconLeft ? (
    <IconLeft width={12} height={12} className={colorClassName} />
  ) : null;

  return (
    <div className={`${colorClassName} ${styleClassName} ${styles.badge}`}>
      {iconLeft}
      <Label size="xs">{content}</Label>
    </div>
  );
};

export default Badge;
