export type Validator = (value: string | undefined) => string | undefined;

export const required: Validator = (value) => (value ? undefined : 'Required');

export const composeValidators =
  (...validators: Validator[]) =>
  (value: string | undefined) =>
    validators.reduce(
      (error: string | undefined, validator) => error || validator(value),
      undefined
    );
