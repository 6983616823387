import React from 'react';
import TextInput from '../TextInput/TextInput';
import { IconSearch } from '../../icons';
import styles from './TableSearch.module.scss';

interface Props {
  onChange: (filter: string) => void;
  placeholder: string;
}

const TableSearch = ({ onChange, placeholder }: Props) => {
  return (
    <TextInput
      className={styles.tableSearch}
      variant="filter"
      placeholder={placeholder}
      iconLeft={IconSearch}
      onChange={(text) => onChange(text)}
    />
  );
};

export default TableSearch;
