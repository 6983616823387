import React from 'react';
import CostCalculatorFormSection from './common/CostCalculatorFormSection';
import PathTextInput from './common/PathTextInput';
// import FileUpload from '../../../../../../aurora/components/FileUpload/FileUpload';
// import { useDispatch } from 'react-redux';
// import { updateFormInputs } from '../../../../../../state/customerCostReports';
// import CustomerCostReportApi from '../../../../../../api/customerCostReportApi';
// import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';

interface Props {
  adminView: boolean;
}

const ContactInfoSection = ({ adminView }: Props) => {
  // const dispatch = useDispatch();
  // const axios = useAxiosPrivate();

  // const uploadImage = async (files: File[]) => {
  //   if (files.length === 0) {
  //     dispatch(
  //       updateFormInputs({
  //         path: 'contactInfoInputs.imageId',
  //         value: undefined,
  //       })
  //     );
  //   } else {
  //     console.log(files[0]);
  //     const imageUrl = await CustomerCostReportApi.uploadImage(axios, files[0]);

  //     dispatch(
  //       updateFormInputs({
  //         path: 'contactInfoInputs.imageId',
  //         value: imageUrl,
  //       })
  //     );
  //   }
  // };

  // TOOD(image_upload): if there's an image, show it in non-admin view
  // in admin view, set it in preview initial state
  return (
    <CostCalculatorFormSection>
      {adminView ? (
        <>
          <PathTextInput
            label="Company Name *"
            path="contactInfoInputs.companyName"
          />
          {/* <FileUpload label="Company Logo" onSetFiles={uploadImage} /> */}
        </>
      ) : null}
      <PathTextInput
        label="Contact First Name"
        path="contactInfoInputs.contactFirstName"
      />
      <PathTextInput
        label="Contact Last Name"
        path="contactInfoInputs.contactLastName"
      />
      <PathTextInput
        label="Contact Email"
        path="contactInfoInputs.contactEmail"
      />
    </CostCalculatorFormSection>
  );
};

export default ContactInfoSection;
