import React from 'react';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';
import { SegmentEntity } from '../../../../../state/segments/types';
import InfoCard from '../InfoCard';
import styles from '../JourneyPageContainer.module.scss';

interface Props {
  segment?: SegmentEntity;
}
const ShipperInfoCard = ({ segment }: Props) => {
  if (segment === undefined) {
    return null;
  }

  return (
    <InfoCard title="Shipper Information" accordion>
      <div className={styles.textSection}>
        <div>
          <Label span size="sm" color="strong900">
            Type:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {segment.deviceType}
          </Text>
        </div>

        <div>
          <Label span size="sm" color="strong900">
            Serial Number:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {segment.serialNumber}
          </Text>
        </div>

        <div>
          <Label span size="sm" color="strong900">
            Firmware Version:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {segment.firmwareVersion}
          </Text>
        </div>
      </div>
    </InfoCard>
  );
};

export default ShipperInfoCard;
