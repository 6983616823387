import React from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { IconClose } from '../../icons';
import { Pressable, useSx } from 'dripsy';
import styles from './Slideout.module.scss';

interface SlideoutProps {
  open: boolean;
  onClose: () => void;
  direction?: 'left' | 'right';
  title?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  variant?: 'nav' | 'entity';
}

const Slideout: React.FC<SlideoutProps> = ({
  open,
  onClose,
  title,
  children,
  footer,
  direction = 'right',
  variant = 'entity',
}) => {
  const sx = useSx();

  const bodyClassNames = [styles.body];
  if (variant === 'entity') {
    bodyClassNames.push(styles.bodyForElement);
  }

  return (
    <Drawer
      direction={direction}
      enableOverlay={false}
      className={styles.drawer}
      open={open}
      onClose={onClose}
    >
      {title && (
        <div className={styles.title}>
          {title}
          <Pressable onPress={onClose}>
            <IconClose
              width={20}
              height={20}
              style={sx({ color: 'gray50v1' })}
            />
          </Pressable>
        </div>
      )}
      <div className={bodyClassNames.join(' ')}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </Drawer>
  );
};

export default Slideout;
