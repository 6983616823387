import { axiosPrivate } from '../api/axios';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import { AxiosInstance } from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../state/auth';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const auth = useSelector(selectAuth);

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh, axiosPrivate]);

  return axiosPrivate;
};

export const get = (
  axiosPrivate: AxiosInstance,
  url: string,
  params?: any,
  config?: object
) => {
  return axiosPrivate.get(url, {
    headers: { 'Content-Type': 'application/json' },
    params,
    withCredentials: true,
    ...config,
  });
};

export const post = (
  axiosPrivate: AxiosInstance,
  url: string,
  data?: any,
  config?: object
) => {
  return axiosPrivate.post(url, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    ...config,
  });
};

export const patch = (axiosPrivate: AxiosInstance, url: string, data?: any) => {
  return axiosPrivate.patch(url, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  });
};

export const del = (axiosPrivate: AxiosInstance, url: string) => {
  return axiosPrivate.delete(url, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  });
};

export default useAxiosPrivate;
