import { SxProp } from 'dripsy';
import { CSSProperties } from 'react';

export const dropzoneStyle: SxProp = {
  backgroundColor: 'neutral100',
  borderColor: 'neutral500',
  borderWidth: '1px',
  borderStyle: 'dashed',
  cursor: 'pointer',
  padding: '12px',
};

export const thumbsContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

export const thumbStyle: SxProp = {
  position: 'relative',
  display: 'inline-flex',
  borderRadius: 2,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'gray50v1',
  padding: '10px',
  boxSizing: 'border-box',
  marginTop: '12px',
};

export const imgContainerStyle: CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width: 'auto',
};

export const imgStyle: CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

export const closeStyle: SxProp = {
  position: 'absolute',
  right: '-15px',
  top: '-12px',
};
