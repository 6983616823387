import React from 'react';
import { Label } from '../../../../aurora/typography/Label/Label';
import SelectField from '../../../../components/forms/SelectField';
import TextField from '../../../../components/forms/TextField';
import { required } from '../../../../components/forms/validators';
import { carrierOptions, states } from './formOptions';
import styles from './AddressSection.module.scss';
import formStyles from '../../../../components/forms/Form.module.scss';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';

interface Props {
  heading: string;
  isArtycViewer: boolean;
  isLab?: boolean;
  shipmentPrefix: string;
  addressAccordion?: boolean;
}

const AddressSection = ({
  heading,
  isArtycViewer,
  isLab,
  shipmentPrefix,
  addressAccordion,
}: Props) => {
  const addressFragment = (
    <>
      {isLab ? (
        <>
          <TextField
            label="Lab Name"
            name={`${shipmentPrefix}.addressName`}
            showOptional
          />
          <TextField
            label="Attn"
            name={`${shipmentPrefix}.attn`}
            showOptional
          />
        </>
      ) : null}
      <TextField
        label="Address Line 1"
        name={`${shipmentPrefix}.addressLine1`}
        validate={required}
      />

      <TextField
        label="Address Line 2"
        name={`${shipmentPrefix}.addressLine2`}
        showOptional
      />

      <TextField
        label="City"
        name={`${shipmentPrefix}.city`}
        validate={required}
      />

      <div className={formStyles.formRow}>
        <SelectField
          label="State"
          name={`${shipmentPrefix}.state`}
          options={states}
          validate={required}
        />

        <TextField
          label="Postal Code"
          name={`${shipmentPrefix}.postalCode`}
          validate={required}
        />
      </div>
    </>
  );
  return (
    <>
      <Label size="sm" className={styles.heading}>
        {heading}
      </Label>

      <div className={formStyles.form}>
        {!addressAccordion ? addressFragment : null}

        {isArtycViewer ? (
          <>
            <div className={formStyles.formRow}>
              <TextField
                label="Tracking Number"
                name={`${shipmentPrefix}.trackingNumber`}
                showOptional
                tooltip="If available, enter the tracking number now. Otherwise, you can add it later"
              />
              <SelectField
                label="Carrier"
                name={`${shipmentPrefix}.carrier`}
                options={carrierOptions}
                showOptional
                clearable
              />
            </div>
          </>
        ) : null}

        {addressAccordion ? (
          <Accordion
            className={styles.addressAccordion}
            sections={[
              {
                header: 'Address Information',
                body: <div className={formStyles.form}>{addressFragment}</div>,
              },
            ]}
          />
        ) : null}
      </div>
    </>
  );
};

export default AddressSection;
