import { CustomerCostDollarsProps } from '../../../pages/customerCostReports/components/costCalculator/summary/CostCalculatorSummary';
import { EstimateFormInputs } from '../types';
import {
  calcCustomerBloodDrawCostDollars,
  calcCustomerCostDollars,
  calcCustomerPackagingCostDollars,
  calcCustomerShippingCostDollars,
  calcCustomerTrackingCostDollars,
  calcCustomerWarehouseCostDollars,
} from './customerCostUtils';

// TODO(cost-calc): write tests
export const mkCustomerCostDollars = (
  estimateInputs: EstimateFormInputs,
  numShipments: number
): CustomerCostDollarsProps => {
  const {
    packagingInputs,
    trackingInputs,
    warehousingInputs,
    bloodDrawInputs,
    shippingInputs,
  } = estimateInputs;

  const packaging = calcCustomerPackagingCostDollars(
    packagingInputs.costPerBoxDollars,
    packagingInputs.usesPerBox,
    packagingInputs.gelPackPriceDollars,
    packagingInputs.gelPackCount,
    packagingInputs.dryIcePriceDollars,
    packagingInputs.dryIcePounds
  );
  const tracking = calcCustomerTrackingCostDollars(trackingInputs.sensors);
  const warehousing = calcCustomerWarehouseCostDollars(
    warehousingInputs.warehouseHourlyCostDollars,
    warehousingInputs.cleaningRefurbMinutes,
    warehousingInputs.kittingCostDollars,
    warehousingInputs?.kittingTimeMinutes
  );
  const bloodDraw = calcCustomerBloodDrawCostDollars(bloodDrawInputs.methods);
  const shipping = calcCustomerShippingCostDollars(shippingInputs.trips);
  const total = calcCustomerCostDollars(
    packaging,
    tracking,
    warehousing,
    bloodDraw,
    shipping,
    numShipments
  );
  const totalPerShipment = total / numShipments;

  return {
    packaging,
    tracking,
    warehousing,
    bloodDraw,
    shipping,
    totalPerShipment,
    total,
  };
};
