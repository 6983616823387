import React from 'react';
import { IconCircleInfo } from '../../icons';
import { Label } from '../../typography/Label/Label';
import { Text } from '../../typography/Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './InputLabel.module.scss';

interface Props {
  id?: string;
  label?: string;
  showRequired?: boolean;
  showOptional?: boolean;
  tooltip?: string;
  labelClassName?: string;
}
export type InputLabelProps = Omit<Props, 'id'>;

const InputLabel = ({
  id,
  label,
  showRequired,
  showOptional,
  tooltip,
  labelClassName,
}: Props) => {
  if (label === undefined) {
    return null;
  }

  return (
    <label htmlFor={id} className={styles.label}>
      <Label size="sm" color="strong900" className={labelClassName}>
        {label}
      </Label>
      {showRequired && (
        <Label size="sm" className={styles.required}>
          *
        </Label>
      )}
      {showOptional && (
        <Text size="sm" color="sub600">
          (Optional)
        </Text>
      )}
      {tooltip && (
        <Tooltip label={tooltip}>
          <IconCircleInfo
            color="gray50v1"
            height={15}
            width={15}
            className={styles.tooltip}
          />
        </Tooltip>
      )}
    </label>
  );
};

export default InputLabel;
