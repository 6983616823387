import React from 'react';
import SelectField from '../../../../components/forms/SelectField';
import formStyles from '../../../../components/forms/Form.module.scss';
import { SelectOptionType } from '../../../../aurora/components/Select/Select';
import AddressSection from './AddressSection';
import ExpectedStartDate from './ExpectedStartDate';
import { FieldArray } from 'react-final-form-arrays';

interface Props {
  isArtycViewer: boolean;
  shipperOptions: SelectOptionType[];
}
const EditJourneyForm = ({ isArtycViewer, shipperOptions }: Props) => {
  return (
    <div className={formStyles.form}>
      <div className={formStyles.formRow}>
        {isArtycViewer ? (
          <>
            <SelectField
              name="device"
              options={shipperOptions}
              label="Shipper"
              showOptional
              clearable
            />
          </>
        ) : null}
        <ExpectedStartDate isArtycViewer={isArtycViewer} />
      </div>
      <FieldArray name="shipments">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              // for now assume the first leg is user and second leg is lab
              <AddressSection
                key={name}
                heading={index === 0 ? 'User Address' : 'Lab Address'}
                isArtycViewer={isArtycViewer}
                shipmentPrefix={name}
                isLab={index === 1}
                addressAccordion={isArtycViewer}
              />
            ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default EditJourneyForm;
