import { useContext } from 'react';
import FeatureFlagsContext, {
  FeatureFlagContextType,
} from '../context/FeatureFlagsProvider';

const useFeatureFlags = (): FeatureFlagContextType => {
  return useContext(FeatureFlagsContext);
};

export default useFeatureFlags;
