import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDataAction,
  EntityState,
  DataPayload,
  SortPayloadAction,
} from '../types';
import { AxiosInstance } from 'axios';
import { Auth } from '../auth/types';
import { UserEntity, UserFilters } from './types';
import { structuredFetch } from '../../api/api';

export interface UserState extends EntityState<UserEntity> {
  selectedFilters?: UserFilters;
}

const initialState: UserState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'firstName',
  sortDirection: 1,
  selectedFilters: {},
};

// right now we fetch all companies at once. will need to change when we have too many companies for one page
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, { payload }: PayloadAction<DataPayload<UserEntity>>) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    sortUsers: (state, { payload }: SortPayloadAction<UserEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        search: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCompanyIdsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        companyIds: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setRoleIdFilter: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        rolePid: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.sortColumn = initialState.sortColumn;
      state.sortDirection = initialState.sortDirection;
      state.currentPage = initialState.currentPage;
    },
  },
});

export const loadUsers: LoadDataAction<UserEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: UserState
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;
  const { entities, totalPages } = await structuredFetch<UserEntity>(
    axios,
    auth,
    'users',
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters
  );

  return setUsers({ entities, totalPages });
};

export const {
  setUsers,
  sortUsers,
  setSearchFilter,
  setCompanyIdsFilter,
  setRoleIdFilter,
  setCurrentPage,
  resetPage,
} = userSlice.actions;

export default userSlice.reducer;
