import React, { CSSProperties } from 'react';

const ShippingProfileFormRow: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const rowStyle: CSSProperties = {
    display: 'flex',
    gap: '16px',
    marginTop: '8px',
    marginBottom: '8px',
  };

  const wrappedChildren = React.Children.map(children, (child) => (
    <div style={{ flex: 1 }}>{child}</div>
  ));

  return <div style={rowStyle}>{wrappedChildren}</div>;
};

export default ShippingProfileFormRow;
