import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import FormInputContainer from '../common/FormInputContainer';

interface Props {
  title: string;
  defaultSwitch: boolean;
  onSwitch: (isOn: boolean) => void;
  input: ReactNode;
  priceSet: boolean;
}

const ComparisonCard = ({
  title,
  defaultSwitch,
  onSwitch,
  input,
  priceSet,
}: Props) => {
  const { industryComparisons, artycComparisons } = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );

  const numComparisons = industryComparisons.length + artycComparisons.length;
  // if already 3 selected, disabled from toggling on but not off
  const canToggle = (numComparisons < 3 || defaultSwitch) && priceSet;

  return (
    <FormInputContainer
      title={title}
      toggleSwitch={
        canToggle ? { defaultValue: defaultSwitch, onSwitch } : undefined
      }
      inputs={() => input}
    />
  );
};

export default ComparisonCard;
