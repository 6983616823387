import React from 'react';
import {
  ButtonGroup,
  ButtonProps,
} from '../../../aurora/components/ButtonGroup/ButtonGroup';
import { Duration, sub } from 'date-fns';

interface Props {
  onFilter: (idx: number, startDate?: Date, endDate?: Date) => void;
  activeIdx: number | null;
}
export const TimeRangeGroup = ({ onFilter, activeIdx }: Props) => {
  const onRangeClick = (idx: number, duration: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, duration);
    onFilter(idx, startDate, endDate);
  };
  const makeRange = (
    label: string,
    idx: number,
    duration: Duration
  ): ButtonProps => ({
    label,
    onClick: () => onRangeClick(idx, duration),
  });
  const timeRanges: ButtonProps[] = [
    {
      label: 'All time',
      onClick: () => {
        onFilter(0, undefined, undefined);
      },
    },
    makeRange('24 hours', 1, { hours: 24 }),
    makeRange('7 days', 2, { days: 7 }),
    makeRange('30 days', 3, { days: 30 }),
  ];

  return <ButtonGroup buttonProps={timeRanges} managedActiveIdx={activeIdx} />;
};
