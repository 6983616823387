import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../api/axios';
import { IconArrowLeft } from '../../../aurora/icons';
import { showToast } from '../../../aurora/components/Toast/Toast';
import styles from '../../../components/layout/Splash.module.scss';

interface Props {
  email: string;
}

const ForgotPasswordEmailConfirmation = ({ email }: Props) => {
  const FORGOT_PASSWORD_URL = '/auth/password-reset';
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let errMsg: string;

    try {
      const response = await axios.post(
        FORGOT_PASSWORD_URL,
        JSON.stringify({ email }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        showToast({
          type: 'success',
          title: 'Password Reset',
          text: 'Email Successfully Resent',
        });
      }
    } catch (err: any) {
      if (!err?.response) {
        errMsg = 'No Server Response';
      } else if (err.response?.status === 400) {
        errMsg = 'Missing Email';
      } else if (err.response?.status === 404) {
        errMsg = 'Email Not Found';
      } else {
        errMsg = 'Request Failed';
      }
      showToast({
        type: 'error',
        title: 'Password Reset Error',
        text: errMsg,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.resendRow}>
        Didn't receive the email?{' '}
        <span
          className={styles.blueLink}
          onClick={() => {
            if (!isSubmitting) {
              handleSubmit();
            }
          }}
        >
          Click to resend
        </span>
      </div>
      <Link to="/login" className={styles.iconLink}>
        <IconArrowLeft width={20} height={20} />
        Back to Login
      </Link>
    </div>
  );
};

export default ForgotPasswordEmailConfirmation;
