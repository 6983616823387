import React, {
  ChangeEvent,
  ComponentType,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { SxProp, useSx, useDripsyTheme } from 'dripsy';
import { IconProps } from '../../icons/IconBase';
import { BaseTheme } from '../../theme/baseTheme';
import styles from './TextInput.module.scss';
import InputLabel, { InputLabelProps } from '../form/InputLabel';
import InputHint, { InputHintProps } from '../form/InputHint';

export type TextInputProps = {
  variant: keyof BaseTheme['textInput'];
  onChange?: (text: string) => void;
  managedText?: string;
  defaultText?: string;
  placeholder?: string;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  sx?: SxProp;
  disabled?: boolean;
  className?: string;
  type?: string;
  name?: string;
} & InputLabelProps &
  InputHintProps;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      variant,
      onChange,
      managedText,
      defaultText,
      placeholder,
      iconLeft: IconLeft,
      iconRight: IconRight,
      sx: sxProp,
      disabled,
      label,
      tooltip,
      labelClassName,
      className,
      error,
      type,
      showRequired,
      showOptional,
      name,
      hint,
    },
    ref
  ) {
    const { theme } = useDripsyTheme();
    const textInput = theme.textInput as BaseTheme['textInput'];
    const variantSx = textInput[variant];

    const [text, setText] = useState(
      managedText !== undefined
        ? managedText
        : defaultText !== undefined
        ? defaultText
        : ''
    );
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const sx = useSx();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newText = event.target.value;
      setText(newText);
      onChange && onChange(newText);
    };

    useEffect(() => {
      if (managedText !== undefined) {
        setText(managedText);
      }
    }, [managedText]);

    const iconLeft = IconLeft ? (
      <div className={styles.iconLeft}>
        <IconLeft
          width={20}
          height={20}
          color={disabled ? 'gray300' : 'gray600'}
        />
      </div>
    ) : null;

    const iconRight = IconRight ? (
      <div className={styles.iconRight}>
        <IconRight width={20} height={20} />
      </div>
    ) : null;

    const textInputStyles: SxProp = {
      height: '40px',
      width: '100%',
      lineHeight: '24px',
      color: variantSx.color,
      borderRadius: '4px',
      padding: '10px 14px',
      fontSize: '14px',
      border: '1px solid',
      outline: variantSx.outline,
      backgroundColor: variantSx.backgroundColor,
      boxShadow: isFocused && !disabled ? variantSx.$active.boxShadow : 'none',
      borderColor:
        isFocused && !disabled
          ? variantSx.$active.borderColor
          : isHovered && !disabled
          ? variantSx.$hover.borderColor
          : variantSx.borderColor,
      paddingLeft: iconLeft ? '42px' : undefined,
      paddingRight: iconRight ? '42px' : undefined,
      ...sxProp,
    };

    const disabledStyles: SxProp = {
      cursor: 'not-allowed',
      backgroundColor: 'gray50',
      outline: 'none',
      color: 'gray300',
    };

    const errorStyles: SxProp = {
      borderColor: 'red500',
      outlineColor: 'red500',
    };

    const id =
      name !== undefined
        ? name
        : label
        ? label.toLowerCase().replace(/[^a-z0-g]/g, '-')
        : undefined;
    const placeholderColor = sx({ color: 'gray400' }).color;

    const textInputContent = (
      <>
        <InputLabel
          id={id}
          label={label}
          showOptional={showOptional}
          showRequired={showRequired}
          tooltip={tooltip}
          labelClassName={labelClassName}
        />
        <div className={styles.textInputWrapper}>
          {iconLeft}
          {iconRight}
          <input
            id={id}
            ref={ref}
            placeholder={placeholder}
            value={text}
            disabled={disabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              ...sx(textInputStyles),
              ...(disabled ? sx(disabledStyles) : {}),
              ...(error ? sx(errorStyles) : { outlineColor: '' }),
              '::placeholder': {
                color: disabled ? disabledStyles.color : placeholderColor,
              },
            }}
            className={className}
            type={type || 'text'}
            autoComplete={type === 'email' ? 'email' : 'off'}
            inputMode={type === 'email' ? 'email' : 'text'}
          />
        </div>
        <InputHint hint={hint} error={error} disabled={disabled} />
      </>
    );

    return <div className={className}>{textInputContent}</div>;
  }
);

export default TextInput;
