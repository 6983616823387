import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberInput from '../../../../../../aurora/components/NumberInput/NumberInput';
import { updateFormInputs } from '../../../../../../state/customerCostReports';
import {
  ArtycComparison,
  PricingModel,
} from '../../../../../../state/customerCostReports/types';
import { RootState } from '../../../../../../state/store';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import ComparisonCard from './ComparisonCard';
import styles from '../Form.module.scss';

type ArtycOption = Omit<ArtycComparison, 'priceDollars'> & {
  label: string;
  placeholder: number;
};

const artycOptions: ArtycOption[] = [
  {
    name: 'CaaS 2-Day',
    pricingModel: PricingModel.CaaS,
    placeholder: 125,
    label: 'Price per shipment',
  },
  {
    name: 'CaaS Overnight',
    pricingModel: PricingModel.CaaS,
    placeholder: 150,
    label: 'Price per shipment',
  },
  {
    name: 'CaaS Blended',
    pricingModel: PricingModel.CaaS,
    placeholder: 136,
    label: 'Price per shipment',
  },
  {
    name: 'Lease',
    pricingModel: PricingModel.Lease,
    placeholder: 250,
    label: 'Price per shipper',
  },
];

interface ArtycOptionCardProps {
  option: ArtycOption;
}
const ArtycOptionCard = ({ option }: ArtycOptionCardProps) => {
  const dispatch = useDispatch();
  const { artycComparisons } = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );

  const existingComparison: ArtycComparison | undefined =
    artycComparisons.filter((comparison) => comparison.name === option.name)[0];
  const [priceDollars, setPriceDollars] = useState<null | number>(
    existingComparison?.priceDollars || null
  );

  const onSwitch = (isOn: boolean, option: ArtycOption) => {
    if (isOn) {
      if (priceDollars === null) {
        throw new Error(
          "shouldn't be able to switch comparison on without price"
        );
      }
      const newComparisons: ArtycComparison[] = [
        ...artycComparisons,
        { name: option.name, pricingModel: option.pricingModel, priceDollars },
      ];

      dispatch(
        updateFormInputs({ path: 'artycComparisons', value: newComparisons })
      );
    } else {
      const newComparisons: ArtycComparison[] = artycComparisons.filter(
        (comparison) => comparison.name !== option.name
      );

      dispatch(
        updateFormInputs({ path: 'artycComparisons', value: newComparisons })
      );
    }
  };

  const onChange = (num: number | null) => {
    setPriceDollars(num);

    // also set in comparison
    if (existingComparison !== undefined) {
      if (num !== null) {
        const newComparisons = artycComparisons.map((comparison) =>
          comparison.name === option.name
            ? { ...comparison, priceDollars: num }
            : comparison
        );
        dispatch(
          updateFormInputs({ path: 'artycComparisons', value: newComparisons })
        );
        // removing price also removes the comparison
      } else {
        const newComparisons: ArtycComparison[] = artycComparisons.filter(
          (comparison) => comparison.name !== option.name
        );

        dispatch(
          updateFormInputs({ path: 'artycComparisons', value: newComparisons })
        );
      }
    }
  };

  const input = (
    <NumberInput
      placeholder={`$${option.placeholder.toString()}`}
      onChange={onChange}
      label={option.label}
      managedNum={priceDollars}
      labelClassName={styles.label}
      type="dollar"
    />
  );

  return (
    <ComparisonCard
      key={option.name}
      title={option.name}
      defaultSwitch={existingComparison !== undefined}
      onSwitch={(isOn) => onSwitch(isOn, option)}
      input={input}
      priceSet={priceDollars !== null}
    />
  );
};

const ArtycComparisons = () => {
  return (
    <CostCalculatorFormSection
      title="Artyc Options"
      subtitle="Comparison against an Artyc offering. Cost calculated based on the sales model. You need to enter a price before you can toggle an option on. Only three comparisons allowed between Artyc Options and Industry Estimates."
    >
      {artycOptions.map((option) => (
        <ArtycOptionCard key={option.name} option={option} />
      ))}
    </CostCalculatorFormSection>
  );
};

export default ArtycComparisons;
