import React, { useEffect, useState } from 'react';
import { SelectOptionType } from '../../../../aurora/components/Select/Select';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useSelector } from 'react-redux';
import { isCompanyUser, selectAuth } from '../../../../state/auth';
import { useField, useForm } from 'react-final-form';
import SelectField from '../../../../components/forms/SelectField';
import { required } from '../../../../components/forms/validators';

interface Props {
  isArtycViewer: boolean;
}
const ShippingProfileSelect = ({ isArtycViewer }: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const [shippingProfileOptions, setShippingProfileOptions] = useState<
    SelectOptionType[]
  >([]);
  const {
    input: { value: company },
  } = useField('company', { subscription: { value: true } });
  const form = useForm();

  useEffect(() => {
    if (auth !== null) {
      if (isCompanyUser(auth)) {
        form.change('company', auth.companyId);
        fetchProfiles(auth.companyId);
      }
    }
  }, [auth]);

  const resetProfile = () => {
    setShippingProfileOptions([]);
    form.change('shippingProfile', undefined);
  };

  // shipping profile depends on the company. unset if company changes + fetch new profiles
  const missingCompany = company === '' || company === undefined;
  useEffect(() => {
    if (missingCompany) {
      resetProfile();
    }

    if (isArtycViewer && !missingCompany) {
      fetchProfiles(company);
    }
  }, [company]);

  const fetchProfiles = async (companyId: string) => {
    resetProfile();

    const profiles = await ShippingProfilesApi.getShippingProfiles(
      axios,
      auth,
      [companyId]
    );
    const profileOptions = profiles.map((profile) => ({
      value: profile._id,
      label: profile.name,
    }));
    setShippingProfileOptions(profileOptions);
  };

  return (
    <SelectField
      name="shippingProfile"
      options={shippingProfileOptions}
      label="Shipping Profile"
      disabled={missingCompany}
      placeholder={missingCompany ? 'Select Company First' : 'Select'}
      validate={required}
    />
  );
};

export default ShippingProfileSelect;
