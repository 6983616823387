import { AxiosInstance } from 'axios';
import { genericDelete, genericFetch, genericPatch, genericPost } from './api';
import { Auth } from '../state/auth/types';
import { UserEntity } from '../state/users/types';

const ARTYC_USER_URL = '/artyc/users';
const COMPANY_USER_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/users`;

const UsersApi = {
  getUser: async (axios: AxiosInstance, auth: Auth | null, _id: string) => {
    return genericFetch<UserEntity>(
      axios,
      auth,
      `${ARTYC_USER_URL}/${_id}`,
      `${COMPANY_USER_URL(auth)}/${_id}`
    );
  },
  updateUser: async (
    axios: AxiosInstance,
    auth: Auth | null,
    _id: string,
    // TODO(ts): type data
    data: any
  ) => {
    return genericPatch<{ success: string }>(
      axios,
      auth,
      `${ARTYC_USER_URL}/${_id}`,
      `${COMPANY_USER_URL(auth)}/${_id}`,
      data
    );
  },
  inviteUser: async (
    axios: AxiosInstance,
    auth: Auth | null,
    // TODO(ts): type data
    data: any
  ) => {
    return genericPost(
      axios,
      auth,
      `${ARTYC_USER_URL}/invite`,
      `${COMPANY_USER_URL(auth)}/invite`,
      data
    );
  },
  deleteUser: async (axios: AxiosInstance, auth: Auth | null, _id: string) => {
    return genericDelete(
      axios,
      auth,
      `${ARTYC_USER_URL}/${_id}`,
      `${COMPANY_USER_URL(auth)}/${_id}`
    );
  },
};

export default UsersApi;
