import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconNumber: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M18 4L14 20M10 4L6 20M5 8H21M3 16H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
