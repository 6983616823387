import React from 'react';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import FormInputContainer from '../common/FormInputContainer';
import PathNumberInput from '../common/PathNumberInput';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteFromFormInputs,
  updateFormInputs,
} from '../../../../../../state/customerCostReports';
import { RootState } from '../../../../../../state/store';
import styles from '../Form.module.scss';

const PackagingCostsSection = () => {
  const dispatch = useDispatch();

  const initialData = useSelector(
    (state: RootState) =>
      state.customerCostReports.selectedReport?.estimateInputs
  ) as any;

  const gelPacksExists =
    initialData?.packagingInputs?.gelPackPriceDollars !== undefined ||
    initialData?.packagingInputs?.gelPackCount !== undefined;

  const dryIceExists =
    initialData?.packagingInputs?.dryIcePriceDollars !== undefined ||
    initialData?.packagingInputs?.dryIcePounds !== undefined;

  const isMultiUse = initialData?.packagingInputs?.usesPerBox !== undefined;

  return (
    <CostCalculatorFormSection
      title={'Packaging Cost'}
      subtitle={'Calculate the total cost of packaging.'}
    >
      <div style={{ display: 'flex', gap: '24px' }}>
        <FormInputContainer
          title="Cost Per Box"
          inputs={() => (
            <PathNumberInput
              path="estimateInputs.packagingInputs.costPerBoxDollars"
              label="Amount"
              showIncrement
              labelClassName={styles.label}
              type="dollar"
              placeholder="$0"
              minNum={0}
            />
          )}
        />
        <FormInputContainer
          title="Gel Packs"
          toggleSwitch={{
            defaultValue: gelPacksExists,
            onSwitch: (isOn) => {
              if (!isOn) {
                dispatch(
                  deleteFromFormInputs(
                    'estimateInputs.packagingInputs.gelPackPriceDollars'
                  )
                );
                dispatch(
                  deleteFromFormInputs(
                    'estimateInputs.packagingInputs.gelPackCount'
                  )
                );
              }
            },
          }}
          inputs={(disabled: boolean) => (
            <div style={{ display: 'flex', gap: '16px' }}>
              <PathNumberInput
                path="estimateInputs.packagingInputs.gelPackPriceDollars"
                label="Cost per gel pack"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="dollar"
                placeholder="$0"
                minNum={0}
              />
              <PathNumberInput
                path="estimateInputs.packagingInputs.gelPackCount"
                label="Packs per shipment"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="number"
                placeholder="0"
                minNum={0}
              />
            </div>
          )}
        />
      </div>
      <div style={{ display: 'flex', gap: '24px' }}>
        <FormInputContainer
          toggleSwitch={{
            defaultValue: isMultiUse,
            onSwitch: (isOn) => {
              dispatch(
                updateFormInputs({
                  path: 'estimateInputs.packagingInputs.isMultiUse',
                  value: isOn,
                })
              );
              if (!isOn) {
                dispatch(
                  deleteFromFormInputs(
                    'estimateInputs.packagingInputs.usesPerBox'
                  )
                );
              }
            },
          }}
          title="Multi-Use"
          inputs={(disabled: boolean) => (
            <PathNumberInput
              path="estimateInputs.packagingInputs.usesPerBox"
              label="Uses per box"
              showIncrement
              disabled={disabled}
              labelClassName={styles.label}
              type="number"
              placeholder="0"
              minNum={2}
            />
          )}
        />
        <FormInputContainer
          title="Dry Ice"
          toggleSwitch={{
            defaultValue: dryIceExists,
            onSwitch: (isOn) => {
              if (!isOn) {
                dispatch(
                  deleteFromFormInputs(
                    'estimateInputs.packagingInputs.dryIcePriceDollars'
                  )
                );
                dispatch(
                  deleteFromFormInputs(
                    'estimateInputs.packagingInputs.dryIcePounds'
                  )
                );
              }
            },
          }}
          inputs={(disabled: boolean) => (
            <div style={{ display: 'flex', gap: '16px' }}>
              <PathNumberInput
                path="estimateInputs.packagingInputs.dryIcePriceDollars"
                label="Cost per pound"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="dollar"
                placeholder="$0"
                minNum={0}
              />
              <PathNumberInput
                path="estimateInputs.packagingInputs.dryIcePounds"
                label="Pounds per shipment"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="number"
                placeholder="0"
                minNum={0}
              />
            </div>
          )}
        />
      </div>
    </CostCalculatorFormSection>
  );
};

export default PackagingCostsSection;
