import React from 'react';
import ArtycComparisons from './ArtycComparisons';
import IndustryComparisons from './IndustryComparisons';

const Comparisons = () => {
  return (
    <>
      <ArtycComparisons />
      <IndustryComparisons />
    </>
  );
};

export default Comparisons;
