import React, { useState, CSSProperties, ReactNode, useEffect } from 'react';
import Switch from '../../../aurora/components/Switch/Switch';
import { useSx } from 'dripsy';
import Select from '../../../aurora/components/Select/Select';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import TextArea from '../../../aurora/components/TextArea/TextArea';
import { Tooltip } from '../../../aurora/components/Tooltip/Tooltip';
import { IconCircleInfo } from '../../../aurora/icons';

interface SectionProps {
  title: string;
  toggleSwitch?: {
    defaultValue: boolean;
    onSwitchOff: () => void;
  };
  lastSection?: boolean;
  firstSection?: boolean;
  children: ReactNode;
  tooltip?: string;
}

const ShippingProfileFormSection: React.FC<SectionProps> = ({
  title,
  toggleSwitch,
  firstSection,
  lastSection,
  children,
  tooltip,
}) => {
  const [isSectionOn, setIsSectionOn] = useState<boolean>(
    toggleSwitch !== undefined ? toggleSwitch.defaultValue : true
  );

  useEffect(() => {
    if (toggleSwitch !== undefined) {
      setIsSectionOn(toggleSwitch.defaultValue);
    }
  }, [toggleSwitch]);

  const sx = useSx();

  const sectionStyle: CSSProperties = sx({
    borderBottomColor: 'neutral500',
    borderBottomStyle: lastSection ? 'none' : 'solid',
    borderBottomWidth: '1px',
    paddingBottom: '24px',
    paddingTop: firstSection ? '0' : '24px',
  });

  const headingStyle: CSSProperties = sx({
    color: isSectionOn ? 'gray600' : 'neutral700',
    fontSize: '15px',
    fontWeight: '600',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  });

  const titleStyle: CSSProperties = {
    display: 'flex',
    gap: '8px',
  };

  const modifyChildren = (children: ReactNode): ReactNode => {
    if (toggleSwitch) {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          let childProps = {};
          if (
            (child.type === Select ||
              child.type === TextInput ||
              child.type === TextArea) &&
            toggleSwitch
          ) {
            childProps = { disabled: !isSectionOn };
          }

          if (child.props.children) {
            return React.cloneElement(
              child,
              childProps,
              modifyChildren(child.props.children)
            );
          } else {
            return React.cloneElement(child, childProps);
          }
        }
        return child;
      });
    } else {
      return children;
    }
  };

  return (
    <div style={sectionStyle}>
      <div style={headingStyle}>
        <span style={titleStyle}>
          {title}
          {tooltip && (
            <Tooltip label={tooltip}>
              <IconCircleInfo color="gray50v1" height={15} width={15} />
            </Tooltip>
          )}
        </span>
        {toggleSwitch !== undefined && (
          <Switch
            isSwitchOn={isSectionOn}
            onChange={() => {
              setIsSectionOn(!isSectionOn);
              if (isSectionOn) {
                toggleSwitch.onSwitchOff();
              }
            }}
          />
        )}
      </div>
      {modifyChildren(children)}
    </div>
  );
};

export default ShippingProfileFormSection;
