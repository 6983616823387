import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconHome: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase {...props}>
    <Path
      d="M9 21.73V14.3312C9 13.7713 9 13.4913 9.10899 13.2774C9.20487 13.0893 9.35785 12.9363 9.54601 12.8405C9.75992 12.7315 10.0399 12.7315 10.6 12.7315H13.4C13.9601 12.7315 14.2401 12.7315 14.454 12.8405C14.6422 12.9363 14.7951 13.0893 14.891 13.2774C15 13.4913 15 13.7713 15 14.3312V21.73M11.0177 3.49707L4.23539 8.77131C3.78202 9.12387 3.55534 9.30015 3.39203 9.52092C3.24737 9.71647 3.1396 9.93677 3.07403 10.171C3 10.4354 3 10.7226 3 11.2968V18.5305C3 19.6505 3 20.2104 3.21799 20.6382C3.40973 21.0144 3.71569 21.3203 4.09202 21.512C4.51984 21.73 5.07989 21.73 6.2 21.73H17.8C18.9201 21.73 19.4802 21.73 19.908 21.512C20.2843 21.3203 20.5903 21.0144 20.782 20.6382C21 20.2104 21 19.6505 21 18.5305V11.2968C21 10.7226 21 10.4354 20.926 10.171C20.8604 9.93677 20.7526 9.71647 20.608 9.52092C20.4447 9.30015 20.218 9.12387 19.7646 8.77131L12.9823 3.49707C12.631 3.22386 12.4553 3.08726 12.2613 3.03475C12.0902 2.98842 11.9098 2.98842 11.7387 3.03475C11.5447 3.08726 11.369 3.22386 11.0177 3.49707Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
