import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconNotification: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 22 22" {...props}>
    <Path
      d="M7.85 18.7993C8.61653 19.6378 9.73804 20.1667 10.9875 20.1667C12.2369 20.1667 13.3584 19.6378 14.125 18.7993M15.1708 0.916656C17.6672 2.11443 19.5346 4.35078 20.1667 7.04015M17.2597 12.2715V9.05971C17.2597 5.7051 14.462 3.00132 10.9875 3.00132C7.51294 3.00132 4.67734 5.58983 4.67734 9.05971V12.2497C4.67734 12.7376 4.59858 13.2223 4.4441 13.6851L3.67179 15.9989C3.65064 16.0623 3.69786 16.1277 3.76474 16.1277H18.1652C18.2359 16.1277 18.2861 16.0612 18.2644 15.9962L17.4868 13.6667C17.3364 13.2159 17.2597 12.7451 17.2597 12.2715Z"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
    />
  </IconBase>
);
