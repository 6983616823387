import React from 'react';
import { SegmentSnapshotEntity } from '../../../../state/segments/types';
import { useSx } from 'dripsy';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Text } from '../../../../aurora/typography/Text/Text';

interface Props {
  snapshots: SegmentSnapshotEntity[];
  showGraph: () => void;
}

const DeviceDataSection = ({ snapshots, showGraph }: Props) => {
  const sx = useSx();
  const data = snapshots.map((snapshot) => snapshot.temps.sleeve);

  return (
    <div
      style={sx({
        cursor: 'pointer',
      })}
      onClick={showGraph}
    >
      <Text
        size="sm"
        sx={{ marginBottom: '16px', color: 'gray50v1', fontWeight: '500' }}
      >
        Sleeve Temperature
      </Text>
      <Sparklines data={data}>
        <SparklinesLine
          style={{ strokeWidth: 2, stroke: '#0a77ff', fill: 'none' }}
        />
      </Sparklines>
    </div>
  );
};

export default DeviceDataSection;
