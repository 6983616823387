import React from 'react';
import { useDispatch } from 'react-redux';
import { setSearchFilter } from '../../../state/devices';
import TableSearch from '../../../aurora/components/TableSearch/TableSearch';

const DeviceSearch = () => {
  const dispatch = useDispatch();

  return (
    <TableSearch
      placeholder="Search by serial #"
      onChange={(text) => {
        dispatch(setSearchFilter(text));
      }}
    />
  );
};

export default DeviceSearch;
