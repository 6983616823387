import React from 'react';
import {
  deleteFromFormInputs,
  updateFormInputs,
} from '../../../../../../state/customerCostReports';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import {
  FormInputs,
  FormInputPaths,
} from '../../../../../../state/customerCostReports/types';
import NumberInput, {
  NumberInputProps,
} from '../../../../../../aurora/components/NumberInput/NumberInput';
import { CostItem } from '../../../../../../state/customerCostReports/types';

const getValueByPath = (object: FormInputs, path: FormInputPaths): any => {
  return path
    .split('.')
    .reduce((acc: any, part: string) => acc?.[part], object);
};

type PathNumberInputProps = Omit<
  NumberInputProps,
  'onChange' | 'managedNum' | 'defaultNum'
> & { path: FormInputPaths; arrayItemName?: string; clearToZero?: boolean };

const PathNumberInput = (props: PathNumberInputProps) => {
  const { path, arrayItemName, clearToZero } = props;
  const dispatch = useDispatch();
  const formInputs = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );

  const handleArrayChange = (name: string, value: number | null) => {
    const existingArray: CostItem<string>[] =
      getValueByPath(formInputs, path) || [];

    const updatedArray = existingArray.reduce(
      (acc: CostItem<string>[], item) => {
        if (item.name === name) {
          if (value !== null) {
            acc.push({ ...item, costDollars: value });
          }
        } else {
          acc.push(item);
        }
        return acc;
      },
      []
    );

    if (!existingArray.some((item) => item.name === name) && value !== null) {
      updatedArray.push({ name, costDollars: value });
    }

    dispatch(updateFormInputs({ path, value: updatedArray }));
  };

  const handleChange = (value: number | null) => {
    if (arrayItemName) {
      handleArrayChange(arrayItemName, value);
    } else {
      if (value === null) {
        // in some cases, we want clearing to set it to 0 instead of null
        // otherwise when we reload, it'll populate with the default when we intended it to be empty
        if (clearToZero) {
          dispatch(updateFormInputs({ path, value: 0 }));
        } else {
          dispatch(deleteFromFormInputs(path));
        }
      } else {
        dispatch(updateFormInputs({ path, value }));
      }
    }
  };

  const getValueFromArrayByName = (array: CostItem<string>[], name: string) => {
    const item = array.find((item) => item.name === name);
    return item ? item.costDollars : null;
  };

  const value = arrayItemName
    ? getValueFromArrayByName(getValueByPath(formInputs, path), arrayItemName)
    : getValueByPath(formInputs, path) || null;

  return <NumberInput {...props} managedNum={value} onChange={handleChange} />;
};

export default PathNumberInput;
