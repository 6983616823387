import React, { useEffect, useState } from 'react';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import DevicesApi from '../../../../api/devicesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { Button } from '../../../../aurora/components/Button/Button';
import { Form } from 'react-final-form';
import { createFormToBody, CreateJourneyFormData } from './mapData';
import { selectAuth } from '../../../../state/auth';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import { loadJourneys } from '../../../../state/journeys';
import formStyles from '../../../../components/forms/Form.module.scss';
import CreateJourneyForm from './CreateJourneyForm';
import JourneysApi from '../../../../api/journeysApi';
import { DeviceEntity } from '../../../../state/devices/types';

interface Props {
  open: boolean;
  closeSlideout: () => void;
  isArtycViewer: boolean;
}
const CreateJourneySlideout = ({
  open,
  closeSlideout,
  isArtycViewer,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const journeysState = useSelector((state: RootState) => state.journeys);

  const [shippers, setShippers] = useState<DeviceEntity[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const shipperOptions = shippers.map((shipper) => ({
    value: shipper._id,
    label: shipper.serialNumber,
  }));

  const fetchData = async () => {
    if (isArtycViewer && open) {
      const shippersResp = await DevicesApi.getArtycDevices(axios, auth);
      setShippers(shippersResp.entities);
    }
  };

  useEffect(() => {
    fetchData();
  }, [open]);

  const onSubmit = async (data: CreateJourneyFormData) => {
    setSubmitting(true);

    try {
      const body = createFormToBody(data);
      await JourneysApi.createJourney(axios, auth, body);

      const action = await loadJourneys(axios, auth!, journeysState);
      dispatch(action);

      showToast({
        type: 'success',
        title: 'Success',
        text: 'Successfully created journey',
      });

      closeSlideout();
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again or contact support',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <Slideout
          open={open}
          onClose={closeSlideout}
          title="Create New Journey"
          footer={
            <div className={formStyles.formRow}>
              <Button
                variant="secondary"
                label="Cancel"
                onClick={closeSlideout}
                disabled={submitting}
              />
              <Button
                variant="primary"
                label="Create"
                onClick={handleSubmit}
                disabled={submitting}
              />
            </div>
          }
        >
          <CreateJourneyForm
            isArtycViewer={isArtycViewer}
            shipperOptions={shipperOptions}
          />
        </Slideout>
      )}
    />
  );
};

export default CreateJourneySlideout;
