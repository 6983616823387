import { SxProp } from 'dripsy';

export const popoverStyles: SxProp = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'neutral700',
  padding: '24px',
  backgroundColor: 'white',
  borderRadius: '12px',
  zIndex: '99',
};

export const tagStyles: SxProp = {
  borderRadius: '6px',
  cursor: 'default',
  gap: '4px',
  alignItems: 'center',
  justifyContent: 'center',
};

export const placeholderStyles: SxProp = {
  fontSize: '14px',
  fontWeight: '500',
  userSelect: 'none',
  paddingY: 2,
};

export const filterInputStyles: SxProp = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '6px',
  padding: '8px 14px',
  cursor: 'pointer',
  justifyContent: 'space-between',
  gap: '12px',
  display: 'flex',
};
