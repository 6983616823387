import { SelectOptionType } from '../../../aurora/components/Select/Select';

export const samplingRateOptions: SelectOptionType<number>[] = [
  { value: 5 * 60, label: '5 minutes' },
  { value: 15 * 60, label: '15 minutes' },
  { value: 30 * 60, label: '30 minutes' },
  { value: 60 * 60, label: '60 minutes' },
  { value: 90 * 60, label: '90 minutes' },
  { value: 120 * 60, label: '120 minutes' },
];

export const minTemperatureOptions: SelectOptionType<number>[] = [
  { value: 0, label: '0 °C' },
  { value: 1, label: '1 °C' },
  { value: 2, label: '2 °C' },
  { value: 3, label: '3 °C' },
  { value: 4, label: '4 °C' },
  { value: 5, label: '5 °C' },
  { value: 6, label: '6 °C' },
  { value: 7, label: '7 °C' },
  { value: 8, label: '8 °C' },
  { value: 9, label: '9 °C' },
  { value: 10, label: '10 °C' },
  { value: 11, label: '11 °C' },
  { value: 12, label: '12 °C' },
  { value: 13, label: '13 °C' },
  { value: 14, label: '14 °C' },
  { value: 15, label: '15 °C' },
  { value: 16, label: '16 °C' },
  { value: 17, label: '17 °C' },
  { value: 18, label: '18 °C' },
  { value: 19, label: '19 °C' },
  { value: 20, label: '20 °C' },
  { value: 21, label: '21 °C' },
  { value: 22, label: '22 °C' },
];

export const maxTemperatureOptions: SelectOptionType<number>[] = [
  { value: 0, label: '0 °C' },
  { value: 1, label: '1 °C' },
  { value: 2, label: '2 °C' },
  { value: 3, label: '3 °C' },
  { value: 4, label: '4 °C' },
  { value: 5, label: '5 °C' },
  { value: 6, label: '6 °C' },
  { value: 7, label: '7 °C' },
  { value: 8, label: '8 °C' },
  { value: 9, label: '9 °C' },
  { value: 10, label: '10 °C' },
  { value: 11, label: '11 °C' },
  { value: 12, label: '12 °C' },
  { value: 13, label: '13 °C' },
  { value: 14, label: '14 °C' },
  { value: 15, label: '15 °C' },
  { value: 16, label: '16 °C' },
  { value: 17, label: '17 °C' },
  { value: 18, label: '18 °C' },
  { value: 19, label: '19 °C' },
  { value: 20, label: '20 °C' },
  { value: 21, label: '21 °C' },
  { value: 22, label: '22 °C' },
  { value: 23, label: '23 °C' },
  { value: 24, label: '24 °C' },
  { value: 25, label: '25 °C' },
];

export const steadyStateExcursionWindowOptions: SelectOptionType<number>[] = [
  { value: 15 * 60, label: '15 minutes' },
  { value: 30 * 60, label: '30 minutes' },
  { value: 60 * 60, label: '60 minutes' },
  { value: 90 * 60, label: '90 minutes' },
  { value: 120 * 60, label: '120 minutes' },
];

export const cooldownExcursionWindowOptions: SelectOptionType<number>[] = [
  { value: 15 * 60, label: '15 minutes' },
  { value: 30 * 60, label: '30 minutes' },
  { value: 60 * 60, label: '60 minutes' },
  { value: 90 * 60, label: '90 minutes' },
  { value: 120 * 60, label: '120 minutes' },
];

export const criticalExcursionLowOptions: SelectOptionType<number>[] = [
  { value: 0, label: '0 °C' },
  { value: 1, label: '1 °C' },
  { value: 2, label: '2 °C' },
  { value: 3, label: '3 °C' },
  { value: 4, label: '4 °C' },
  { value: 5, label: '5 °C' },
  { value: 6, label: '6 °C' },
  { value: 7, label: '7 °C' },
  { value: 8, label: '8 °C' },
  { value: 9, label: '9 °C' },
  { value: 10, label: '10 °C' },
  { value: 11, label: '11 °C' },
  { value: 12, label: '12 °C' },
  { value: 13, label: '13 °C' },
  { value: 14, label: '14 °C' },
  { value: 15, label: '15 °C' },
  { value: 16, label: '16 °C' },
  { value: 17, label: '17 °C' },
  { value: 18, label: '18 °C' },
  { value: 19, label: '19 °C' },
  { value: 20, label: '20 °C' },
  { value: 21, label: '21 °C' },
  { value: 22, label: '22 °C' },
];

export const criticalExcursionHighOptions: SelectOptionType<number>[] = [
  { value: 0, label: '0 °C' },
  { value: 1, label: '1 °C' },
  { value: 2, label: '2 °C' },
  { value: 3, label: '3 °C' },
  { value: 4, label: '4 °C' },
  { value: 5, label: '5 °C' },
  { value: 6, label: '6 °C' },
  { value: 7, label: '7 °C' },
  { value: 8, label: '8 °C' },
  { value: 9, label: '9 °C' },
  { value: 10, label: '10 °C' },
  { value: 11, label: '11 °C' },
  { value: 12, label: '12 °C' },
  { value: 13, label: '13 °C' },
  { value: 14, label: '14 °C' },
  { value: 15, label: '15 °C' },
  { value: 16, label: '16 °C' },
  { value: 17, label: '17 °C' },
  { value: 18, label: '18 °C' },
  { value: 19, label: '19 °C' },
  { value: 20, label: '20 °C' },
  { value: 21, label: '21 °C' },
  { value: 22, label: '22 °C' },
];
