import React, { PropsWithChildren } from 'react';
import { useSx } from 'dripsy';
import baseTheme, { BaseTheme } from '../../theme/baseTheme';

interface Props {
  size?: keyof BaseTheme['text']['label'];
  color?: keyof BaseTheme['colors']['textColor'];
  className?: string;
  span?: boolean;
}

export const Label = ({
  children,
  size,
  color,
  className,
  span,
}: PropsWithChildren<Props>) => {
  const sxProp = useSx();
  let combinedStyles = {};

  if (size !== undefined) {
    const textSx = baseTheme['text']['label'][size];
    combinedStyles = { ...combinedStyles, ...textSx };
  }

  if (color !== undefined) {
    const colorSx = baseTheme['colors']['textColor'][color];
    combinedStyles = { ...combinedStyles, color: colorSx };
  }

  const sxStyles = sxProp(combinedStyles);

  const Component = span ? 'span' : 'div';

  return (
    <Component className={className} style={sxStyles}>
      {children}
    </Component>
  );
};
