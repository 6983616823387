import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pressable, SxProp, useSx } from 'dripsy';
import { Text } from '../../aurora/typography/Text/Text';
import baseTheme from '../../aurora/theme/baseTheme';
import { IconChevronRight } from '../../aurora/icons/IconChevronRight';

interface Props {
  sx?: SxProp;
}

type Breadcrumb = {
  label: string;
  path: string;
};

const NavBreadcrumbs = ({ sx: sxProp }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const sx = useSx();

  const textSx = baseTheme['text']['breadCrumb'];
  const breadCrumbColor = textSx['color'];

  const generateBreadcrumbs = (path: string): Breadcrumb[] => {
    const pathSegments = path.split('/').filter(Boolean);
    const prunedPathSegments = pathSegments.filter(
      (section) => section !== 'portal'
    );

    const breadcrumbs: Breadcrumb[] = [{ label: 'Home', path: '/' }];

    prunedPathSegments.forEach((section, idx) => {
      if (section === 'home') {
        return;
      }

      const crumbPath = `/${pathSegments.slice(0, idx + 2).join('/')}`;
      breadcrumbs.push({ label: urlToTitle(section), path: crumbPath });
    });

    return breadcrumbs;
  };

  const urlToTitle = (url: string) =>
    url
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');

  const breadCrumbs = generateBreadcrumbs(location.pathname);

  const styleNavBreadCrumbContainer = sx({
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    color: breadCrumbColor,
  });

  return (
    <div style={{ ...styleNavBreadCrumbContainer, ...sxProp }}>
      {breadCrumbs.map((breadcrumb, idx) => (
        <React.Fragment key={breadcrumb.label}>
          <Pressable onPress={() => navigate(breadcrumb.path)}>
            <Text size={'md'}>{breadcrumb.label}</Text>
          </Pressable>
          {idx < breadCrumbs.length - 1 && (
            <IconChevronRight height={20} width={20} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NavBreadcrumbs;
