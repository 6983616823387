import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDataAction,
  EntityState,
  DataPayload,
  SortPayloadAction,
} from '../types';
import { AxiosInstance } from 'axios';
import { CustomerCostReportEntity, FormInputPaths, FormInputs } from './types';
import { structuredFetch } from '../../api/api';
import { Auth } from '../auth/types';

export interface CustomerCostReportState
  extends EntityState<CustomerCostReportEntity> {
  selectedFilters?: {};
  selectedReport: CustomerCostReportEntity | null;
  formInputs: FormInputs;
  industryEstimates: CustomerCostReportEntity[];
}

export const customerCostReportInitialState: CustomerCostReportState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'updatedAt',
  sortDirection: -1,
  selectedFilters: {},
  selectedReport: null,
  industryEstimates: [],
  formInputs: {
    contactInfoInputs: {
      companyName: '',
    },
    estimateInputs: {
      packagingInputs: {},
      trackingInputs: {
        sensors: [],
      },
      warehousingInputs: {
        warehouseHourlyCostDollars: 44,
        cleaningRefurbMinutes: 10,
        kittingCostDollars: 10,
        kittingTimeMinutes: 10,
      },
      bloodDrawInputs: {
        methods: [],
      },
      shippingInputs: {
        trips: [],
      },
    },
    dynamicInputs: {
      numShipments: 1,
      numShippers: 1,
    },
    artycComparisons: [],
    industryComparisons: [],
    considerations: `Estimates are based on US shipping
Estimates are non-binding. Valid for 30 days upon receipt
20% deposit is required
Separate project management and IT setup charges may apply
Other Terms & Conditions will be given upon request`,
  },
};

const CustomerCostReportsSlice = createSlice({
  name: 'CustomerCostReports',
  initialState: customerCostReportInitialState,
  reducers: {
    setCustomerCostReports: (
      state,
      { payload }: PayloadAction<DataPayload<CustomerCostReportEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    setIndustryEstimates: (
      state,
      { payload }: PayloadAction<CustomerCostReportEntity[]>
    ) => {
      state.industryEstimates = payload;
    },
    updateIndustryEstimates: (
      state,
      { payload }: PayloadAction<CustomerCostReportEntity>
    ) => {
      const newEstimates = state.industryEstimates.map((estimate) =>
        estimate._id === payload._id ? payload : estimate
      );
      state.industryEstimates = newEstimates;
    },
    sortCustomerCostReports: (
      state,
      { payload }: SortPayloadAction<CustomerCostReportEntity>
    ) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = customerCostReportInitialState.selectedFilters;
      state.sortColumn = customerCostReportInitialState.sortColumn;
      state.sortDirection = customerCostReportInitialState.sortDirection;
      state.currentPage = customerCostReportInitialState.currentPage;
    },
    setFormInputs: (state, action: PayloadAction<FormInputs>) => {
      state.formInputs = action.payload;
    },
    // TODO(cost-calc): write tests for this
    updateFormInputs: (
      state,
      action: PayloadAction<{
        path: FormInputPaths;
        value: any;
      }>
    ) => {
      const { path, value } = action.payload;
      let current: any = state.formInputs;

      const pathArr = path.split('.');
      for (let i = 0; i < pathArr.length - 1; i++) {
        const key = pathArr[i];

        if (!current[key]) {
          current[key] = {};
        }

        current = current[key];
      }

      const lastKey = pathArr[pathArr.length - 1];
      current[lastKey] = value;
    },
    // TODO(cost-calc): write tests for this
    deleteFromFormInputs: (state, action: PayloadAction<FormInputPaths>) => {
      const path = action.payload.split('.');
      let current: any = state.formInputs as FormInputs;

      for (let i = 0; i < path.length - 1; i++) {
        if (current[path[i]] === undefined) {
          return;
        }
        current = current[path[i]];
      }

      const lastKey = path[path.length - 1];
      if (current[lastKey] !== undefined) {
        delete current[lastKey];
      }
    },
    setSelectedReport: (
      state,
      action: PayloadAction<CustomerCostReportEntity | null>
    ) => {
      state.selectedReport = action.payload;
    },
  },
});

export const loadCustomerCostReports: LoadDataAction<
  CustomerCostReportEntity
> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: EntityState<CustomerCostReportEntity>
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;

  const { entities, totalPages } =
    await structuredFetch<CustomerCostReportEntity>(
      axios,
      auth,
      'customer-cost-reports',
      resultsPerPage,
      currentPage,
      sortColumn,
      sortDirection,
      selectedFilters
    );

  return setCustomerCostReports({ entities, totalPages });
};

export const {
  setCustomerCostReports,
  sortCustomerCostReports,
  setCurrentPage,
  setFormInputs,
  updateFormInputs,
  deleteFromFormInputs,
  resetPage,
  setSelectedReport,
  setIndustryEstimates,
  updateIndustryEstimates,
} = CustomerCostReportsSlice.actions;

export default CustomerCostReportsSlice.reducer;
