import React, { PropsWithChildren, useState } from 'react';
import Dropdown from '../../../../../aurora/components/Filter/Dropdown';
import { Label } from '../../../../../aurora/typography/Label/Label';

const GraphDropdown = ({ children }: PropsWithChildren<{}>) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      variant={'filter'}
      text={
        <Label size="sm" color="sub600">
          Customize
        </Label>
      }
      popoverContent={children}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default GraphDropdown;
