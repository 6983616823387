import axios from 'axios';

const isLocalEnvironment = ['development', 'staging'].includes(
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV
);
const BASE_URL = isLocalEnvironment ? 'http://localhost:3500' : '';

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
