import React, { ComponentType, useRef } from 'react';
import { Pressable, Flex, Text, useDripsyTheme, SxProp, useSx } from 'dripsy';
import { BaseTheme } from '../../theme/baseTheme';
import { IconProps } from '../../icons/IconBase';
import { useHover } from 'react-native-web-hooks';
import { IconLoading } from '../../icons';
import styles from './Button.module.scss';

interface Props {
  variant: keyof BaseTheme['buttons'];
  label?: string;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  disabled?: boolean;
  round?: boolean;
  onClick?: () => void;
  sx?: SxProp;
  onClickIcon?: () => void;
  disableHover?: boolean;
  loading?: boolean;
  className?: string;
}
export const Button = ({
  variant,
  label,
  iconLeft,
  iconRight,
  disabled,
  round,
  onClick,
  sx,
  onClickIcon,
  disableHover,
  loading,
  className,
}: Props) => {
  const { theme } = useDripsyTheme();
  const buttons = theme.buttons as BaseTheme['buttons'];
  const variantSx = buttons[variant];
  const sxProp = useSx();

  const isDisabled = disabled || loading;
  const buttonRef = useRef(null);
  const isHovered = useHover(buttonRef);
  const renderHovered = !isDisabled && isHovered && !disableHover;
  // disabled from loading, we want to keep original colors
  const textColor = disabled
    ? 'neutral700'
    : renderHovered
    ? variantSx.$hover.color
    : variantSx.color;

  const renderIcon = (Icon: ComponentType<IconProps>) => {
    const icon = <Icon width={16} height={16} color={textColor} />;
    return onClickIcon === undefined ? (
      icon
    ) : (
      <Pressable onPress={onClickIcon} disabled={isDisabled}>
        {icon}
      </Pressable>
    );
  };

  const buttonClasses = `${styles.button} ${round ? styles.round : ''} ${
    disabled ? styles.disabled : ''
  } ${loading ? styles.loading : ''} ${className}`;

  const sxStyles = sxProp({
    ...variantSx,
    ...(renderHovered && variantSx.$hover),
    ...sx,
  });

  if (className) {
    return (
      <button
        ref={buttonRef}
        className={buttonClasses}
        onClick={onClick}
        disabled={isDisabled}
        style={sxStyles}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            color: textColor,
          }}
        >
          {iconLeft ? renderIcon(iconLeft) : null}
          {loading ? (
            <IconLoading width={16} height={16} color={textColor} />
          ) : label ? (
            <Text
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: 'inherit',
              }}
            >
              {label}
            </Text>
          ) : null}
          {iconRight ? renderIcon(iconRight) : null}
        </div>
      </button>
    );
  }

  const buttonContent = (
    <Pressable
      ref={buttonRef}
      variant={`buttons.${variant}`}
      sx={{
        cursor: 'pointer',
        borderWidth: 1,
        borderColor: variantSx.borderColor,
        paddingVertical: round ? '6px' : '8px',
        paddingHorizontal: round ? '6px' : '12px',
        borderRadius: round ? '50%' : '6px',
        justifyContent: 'center',
        ...(renderHovered && variantSx.$hover),
        ...(isDisabled && {
          cursor: 'not-allowed',
        }),
        ...(disabled && {
          // disabled from loading, we want to keep original colors
          backgroundColor: 'neutral100',
          borderColor: 'neutral100',
        }),
        flexGrow: 1,
        ...sx,
      }}
      disabled={isDisabled}
      onPress={onClick}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px',
          color: textColor,
        }}
      >
        {iconLeft ? renderIcon(iconLeft) : null}
        {loading ? (
          <IconLoading width={16} height={16} color={textColor} />
        ) : label ? (
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: 'inherit',
            }}
          >
            {label}
          </Text>
        ) : null}
        {iconRight ? renderIcon(iconRight) : null}
      </Flex>
    </Pressable>
  );

  return buttonContent;
};
