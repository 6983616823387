import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { selectAuth } from '../state/auth';
import { useSelector } from 'react-redux';
import { UserEntity } from '../state/users/types';
import UsersApi from '../api/usersApi';
import UserForm from './UserForm';

interface Props {
  onClose: () => void;
}
const EditSelf = ({ onClose }: Props) => {
  const auth = useSelector(selectAuth);
  const axiosPrivate = useAxiosPrivate();
  const [userData, setUserData] = useState<UserEntity | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await UsersApi.getUser(axiosPrivate, auth, auth!._id);
        setUserData(response);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (userData === null) {
    return <div>Not found, please try again</div>;
  }

  return (
    <UserForm
      forUser={userData}
      onSubmit={(data) =>
        UsersApi.updateUser(axiosPrivate, auth, userData._id, data)
      }
      successMessage="User updated successfully"
      submitText="Save Changes"
      onClose={onClose}
    />
  );
};

export default EditSelf;
