import React, { forwardRef, useEffect, useState } from 'react';
import { SxProp, View, useSx, useDripsyTheme } from 'dripsy';
import { IconMinus, IconPlus } from '../../icons';
import { Text } from '../../typography/Text/Text';

export interface NumberInputProps {
  type?: 'number' | 'dollar' | 'percent';
  onChange?: (num: number | null) => void;
  managedNum?: number | null;
  defaultNum?: number;
  placeholder?: string;
  sx?: SxProp;
  disabled?: boolean;
  showIncrement?: boolean;
  label?: string;
  labelSx?: SxProp;
  labelClassName?: string;
  minNum?: number;
  name?: string;
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    {
      onChange,
      managedNum,
      defaultNum,
      placeholder,
      sx: sxProp,
      disabled,
      showIncrement,
      label,
      labelSx,
      labelClassName,
      minNum,
      type,
      name,
    },
    ref
  ) {
    const { theme } = useDripsyTheme();
    const inputSx = theme.textInput.form;

    const parseNum = (text: string) => {
      const numText =
        type === 'percent'
          ? text.replace('%', '')
          : type === 'dollar'
          ? text.replace('$', '')
          : text;

      if (numText === '') {
        return null;
      }

      return parseFloat(numText);
    };
    const wrapNum = (num: number | null) => {
      if (num === null) {
        return '';
      }

      return type === 'percent'
        ? `${num}%`
        : type === 'dollar'
        ? `$${num}`
        : `${num}`;
    };

    const [text, setText] = useState(
      managedNum !== undefined
        ? wrapNum(managedNum)
        : defaultNum !== undefined
        ? wrapNum(defaultNum)
        : ''
    );

    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const sx = useSx();

    const handleChange = (newText: string) => {
      const num = parseNum(newText);
      if (minNum !== undefined && num !== null && num < minNum) {
        return;
      }

      setText(newText);
      if (onChange !== undefined) {
        if (num === null) {
          onChange(null);
        } else if (!isNaN(num)) {
          onChange(num);
        }
      }
    };

    useEffect(() => {
      if (managedNum !== undefined) {
        if (managedNum === null) {
          setText('');
        } else {
          setText(wrapNum(managedNum));
        }
      }
    }, [managedNum]);

    const disabledColor = 'neutral700';
    const placeholderColor = sx({ color: 'gray50v1' }).color;
    const iconColor = disabled
      ? disabledColor
      : text === ''
      ? placeholderColor
      : inputSx.color;
    const iconStyles: SxProp = {
      position: 'absolute',
      top: '10px',
      cursor: disabled ? 'not-allowed' : text === '' ? undefined : 'pointer',
    };
    const bump = (direction: 1 | -1) => {
      if (!disabled) {
        const num = parseNum(text);
        if (num !== null && !isNaN(num)) {
          const newNum = num + direction;
          const numText = wrapNum(newNum);
          handleChange(numText);
        }
      }
    };
    const iconLeft = showIncrement ? (
      <div style={sx({ ...iconStyles, left: '14px' })} onClick={() => bump(-1)}>
        <IconMinus width={20} height={20} color={iconColor} />
      </div>
    ) : null;
    const iconRight = showIncrement ? (
      <div style={sx({ ...iconStyles, right: '14px' })} onClick={() => bump(1)}>
        <IconPlus width={18} height={18} color={iconColor} />
      </div>
    ) : null;

    const textInputStyles: SxProp = {
      textAlign: 'center',
      height: '40px',
      width: '100%',
      lineHeight: '24px',
      color: inputSx.color,
      borderRadius: '8px',
      padding: '10px 14px',
      fontSize: '14px',
      border: '1px solid',
      outline: inputSx.outline,
      backgroundColor: inputSx.backgroundColor,
      boxShadow: isFocused && !disabled ? inputSx.$active.boxShadow : 'none',
      borderColor:
        isFocused && !disabled
          ? inputSx.$active.borderColor
          : isHovered && !disabled
          ? inputSx.$hover.borderColor
          : inputSx.borderColor,
      ...sxProp,
    };

    const disabledStyles: SxProp = {
      cursor: 'not-allowed',
      backgroundColor: 'neutral100',
      outline: 'none',
      color: disabledColor,
    };

    const id =
      name !== undefined
        ? name
        : label
        ? label.toLowerCase().replace(/[^a-z0-g]/g, '-')
        : undefined;

    return (
      <View sx={{ zIndex: '1' }}>
        {label && (
          <label
            htmlFor={id}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              paddingBottom: '6px',
            }}
          >
            <Text
              size="sm"
              sx={{
                color: disabled ? disabledColor : 'gray600v1',
                fontWeight: '500',
                letterSpacing: '-0.1px',
                lineHeight: '20px',
                ...labelSx,
              }}
              className={labelClassName}
            >
              {label}
            </Text>
          </label>
        )}
        <View sx={{ position: 'relative' }}>
          {iconLeft}
          <input
            id={id}
            name={name}
            ref={ref}
            placeholder={placeholder}
            value={text}
            disabled={disabled}
            onChange={(event) => handleChange(event.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            // @ts-ignore
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              ...sx(textInputStyles),
              ...(disabled ? sx(disabledStyles) : {}),
              '::placeholder': {
                color: disabled ? disabledColor : placeholderColor,
              },
            }}
          />
          {iconRight}
        </View>
      </View>
    );
  }
);

export default NumberInput;
