import React from 'react';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../state/auth';
import { RootState } from '../../../state/store';
import MobileChecklistFilter from './MobileChecklistFilter';

interface CompanyFilterProps {
  setCompanyIdsAction: (companyIds: string[] | undefined) => void;
  filteredCompanyIds: string[] | undefined;
}

const MobileCompanyFilter = ({
  setCompanyIdsAction,
  filteredCompanyIds,
}: CompanyFilterProps) => {
  const auth = useSelector(selectAuth);
  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;
  const isArtycUser = isArtyc(auth);

  return (
    <MobileChecklistFilter
      sections={[
        {
          onApplyFilters: (companyIds) => setCompanyIdsAction(companyIds),
          onClear: () => setCompanyIdsAction(undefined),
          title: 'Companies',
          selectedIds: filteredCompanyIds || [],
          hasSearch: true,
          searchPlaceholder: 'Search by company name',
          options: companies.map((company) => ({
            id: company._id,
            label: company.companyName,
          })),
          show: isArtycUser,
        },
      ]}
    />
  );
};

export default MobileCompanyFilter;
