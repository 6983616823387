import React, { useState } from 'react';
import PageHeader from '../../components/layout/PageHeader';
import ShippingProfilesContainer from './components/ShippingProfilesContainer';
import { Text } from '../../aurora/typography/Text/Text';
import {
  ButtonGroup,
  ButtonProps,
} from '../../aurora/components/ButtonGroup/ButtonGroup';
import CompanySelect from '../../components/filters/CompanySelect/CompanySelect';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../state/auth';
import styles from './shippingProfilesPage.module.scss';

const ShippingProfilesPage = () => {
  const [selectedType, setSelectedType] = useState<
    'all' | 'custom' | 'standard'
  >('all');
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >(undefined);
  const auth = useSelector(selectAuth);

  const types: ButtonProps[] = [
    {
      label: 'All',
      onClick: () => setSelectedType('all'),
    },
    {
      label: 'Custom',
      onClick: () => setSelectedType('custom'),
    },
    {
      label: 'Standard',
      onClick: () => setSelectedType('standard'),
    },
  ];

  return (
    <>
      <PageHeader headingText="Shipping Profiles" />
      <Text size="md" sx={{ color: 'gray50v1', marginBottom: '32px' }}>
        Manage and customize temperature settings for your shipments.
      </Text>
      <div className={styles.filters}>
        <ButtonGroup buttonProps={types} initialActiveIdx={0} />
        <CompanySelect
          onSetCompanyId={(companyId) => setSelectedCompanyId(companyId)}
          selectedCompanyId={selectedCompanyId}
        />
      </div>
      {selectedCompanyId === undefined && isArtyc(auth) ? (
        <Text size="lg" sx={{ marginTop: '24px' }}>
          Please select a company to view profiles for
        </Text>
      ) : (
        <ShippingProfilesContainer
          selectedCompanyId={selectedCompanyId}
          selectedType={selectedType}
        />
      )}
    </>
  );
};

export default ShippingProfilesPage;
