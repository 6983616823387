import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconLetter: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 25 21" {...props}>
    <Path
      d="M2.15 2.15L11.0879 9.10173C11.9185 9.74772 13.0815 9.74771 13.9121 9.10173L22.85 2.15M4.68 19.4H20.32C21.6081 19.4 22.2522 19.4 22.7442 19.1493C23.177 18.9288 23.5288 18.577 23.7493 18.1442C24 17.6522 24 17.0081 24 15.72V4.68C24 3.39188 24 2.74782 23.7493 2.25582C23.5288 1.82305 23.177 1.47119 22.7442 1.25069C22.2522 1 21.6081 1 20.32 1H4.68C3.39188 1 2.74782 1 2.25582 1.25069C1.82305 1.47119 1.47119 1.82305 1.25069 2.25582C1 2.74782 1 3.39188 1 4.68V15.72C1 17.0081 1 17.6522 1.25069 18.1442C1.47119 18.577 1.82305 18.9288 2.25582 19.1493C2.74782 19.4 3.39188 19.4 4.68 19.4Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);
