import React, { useEffect, useState } from 'react';
import { EventEntity } from '../../../state/events/types';
import EventSlideout from './slideout/EventSlideout';
import { SegmentEntity } from '../../../state/segments/types';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../state/auth';
import SegmentsApi from '../../../api/segmentsApi';

interface Props {
  event: EventEntity | undefined;
  showGraph: () => void;
  open: boolean;
  closeSlideout: () => void;
}
const EventSegmentSlideout = ({
  event,
  showGraph,
  open,
  closeSlideout,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [segment, setSegment] = useState<SegmentEntity | undefined>(undefined);

  const loadData = async () => {
    try {
      setLoading(true);
      setError(false);

      const segmentResp = await SegmentsApi.getSegment(
        axiosPrivate,
        auth,
        event!.segment
      );
      setSegment(segmentResp);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event) {
      loadData();
    }
  }, [event]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || (event && segment === null)) {
    return <div>An error occurred, please close and open again</div>;
  }

  return (
    <EventSlideout
      segment={segment}
      showGraph={showGraph}
      selectedEventId={event ? event._id : undefined}
      closeSlideout={closeSlideout}
      open={open}
      isArtycViewer={isArtyc(auth)}
    />
  );
};

export default EventSegmentSlideout;
