import React from 'react';
import { Link } from 'react-router-dom';
import { IconLogo, IconHamburger } from '../../../aurora/icons';
import styles from './NavBarHeader.module.scss';

interface NavBarHeaderProps {
  toggleSlideout?: () => void;
}

const NavBarHeader = ({ toggleSlideout }: NavBarHeaderProps) => {
  return (
    <div className={styles.navBarHeader}>
      <Link className={styles.logoLink} to="/portal/home">
        <IconLogo width={142} height={36} color={'primary600'} />
      </Link>
      <div onClick={toggleSlideout} className={styles.slideoutToggle}>
        <IconHamburger width={24} height={24} />
      </div>
    </div>
  );
};

export default NavBarHeader;
