import React from 'react';
import CostCalculatorSummary, {
  CustomerCostDollarsProps,
} from '../summary/CostCalculatorSummary';

interface Props {
  name: string;
  priceDollars: number;
  customerCostDollars: CustomerCostDollarsProps;
  numShippers: number;
  numShipments: number;
}
const ArtycLeaseSummary = ({
  name,
  priceDollars,
  customerCostDollars,
  numShipments,
  numShippers,
}: Props) => {
  /*
  lease total cost = (lease cost per shipper * number of shippers) + ((their blood draw cost + warehousing cost + shipping cost) * number of shipments)
  our shipper replaces their tracking and packaging, but they still need to pay for their own shipping and warehousing since they're doing their own operations
  lease cost per shipment = lease total cost / number of shipments
  so both number of shippers and shipments will change the lease cost. more shipments means cost per shipment goes down since shipper is a fixed cost
  */
  const toSubtractTotal =
    ((customerCostDollars.tracking || 0) +
      (customerCostDollars.packaging || 0)) *
    numShipments;
  const toAddTotal = priceDollars * numShippers;
  const newTotal = customerCostDollars.total - toSubtractTotal + toAddTotal;
  const newTotalPerShipment = newTotal / numShipments;

  const leaseCost: CustomerCostDollarsProps = {
    ...customerCostDollars,
    tracking: 0,
    packaging: priceDollars,
    total: newTotal,
    totalPerShipment: newTotalPerShipment,
  };

  return (
    <CostCalculatorSummary
      header={name}
      packagingTitle="Price per Shipper"
      customerCostDollars={leaseCost}
    />
  );
};

export default ArtycLeaseSummary;
