import React from 'react';
import { Tooltip } from '../../../aurora/components/Tooltip/Tooltip';
import { IconCircleAlert } from '../../../aurora/icons';
import { SegmentEntity } from '../../../state/segments/types';
import styles from './IntendedDestination.module.scss';

interface Props {
  segment: SegmentEntity;
}
const IntendedDestination = ({ segment }: Props) => {
  if (
    segment.destinations === undefined ||
    segment.destinationConfirmations === undefined ||
    segment.destinations.length === 0 ||
    segment.destinationConfirmations.length === 0
  ) {
    return null;
  }

  // right now we assume there's only one destination
  const destination = segment.destinations[0];
  const destinationConfirmation = segment.destinationConfirmations[0];

  if (destination !== destinationConfirmation) {
    return (
      <div className={styles.field}>
        {destination}{' '}
        <Tooltip label="Confirmed destination does not match intended destination">
          <IconCircleAlert
            width={20}
            height={20}
            color={'white'}
            fill={'#FF8447'}
          />
        </Tooltip>
      </div>
    );
  }

  return <div>{destination}</div>;
};

export default IntendedDestination;
