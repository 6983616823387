export const cToF = (celsius: number) => {
  return (celsius * 9) / 5 + 32;
};

export const fToC = (fahrenheit: number) => {
  return ((fahrenheit - 32) * 5) / 9;
};

export const toDecimalPlaces = (num: number, decimalPlaces: number) => {
  return parseFloat(num.toFixed(decimalPlaces));
};
