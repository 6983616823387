// TODO(cost-calc): write tests, particularly around cases of optional values

import { BloodDrawMethod, CostItem } from '../types';

export const calcCustomerPackagingCostDollars = (
  costPerBoxDollars?: number,
  usesPerBox?: number,
  gelPackPriceDollars?: number,
  gelPackCount?: number,
  dryIcePriceDollars?: number,
  dryIcePounds?: number
) => {
  const boxCost = (costPerBoxDollars || 0) / (usesPerBox || 1);
  const gelPackCost = (gelPackPriceDollars || 0) * (gelPackCount || 0);
  const dryIceCost = (dryIcePriceDollars || 0) * (dryIcePounds || 0);

  return boxCost + gelPackCost + dryIceCost;
};

export const calcCustomerTrackingCostDollars = (
  sensors: CostItem<string>[]
) => {
  return sensors.reduce((sum, sensor) => sum + sensor.costDollars, 0);
};

// TODO(cost-calc): should there be an energy cost?
export const calcCustomerWarehouseCostDollars = (
  warehouseHourlyCostDollars?: number,
  cleaningRefurbMinutes?: number,
  kittingCostDollars?: number,
  kittingTimeMinutes?: number
) => {
  return (
    (warehouseHourlyCostDollars || 0) *
      (((cleaningRefurbMinutes || 0) + (kittingTimeMinutes || 0)) / 60.0) +
    (kittingCostDollars || 0)
  );
};

export const calcCustomerBloodDrawCostDollars = (
  methods: CostItem<BloodDrawMethod>[]
) => {
  return methods.reduce((sum, method) => sum + method.costDollars, 0);
};

export const calcCustomerShippingCostDollars = (trips: CostItem<string>[]) => {
  return trips.reduce((sum, trip) => sum + trip.costDollars, 0);
};

export const calcCustomerCostDollars = (
  packagingCostDollars: number,
  trackingCostDollars: number,
  warehouseCostDollars: number,
  bloodDrawCostDollars: number,
  shippingCostDollars: number,
  numShipments: number
) => {
  return (
    (packagingCostDollars +
      trackingCostDollars +
      warehouseCostDollars +
      bloodDrawCostDollars +
      shippingCostDollars) *
    numShipments
  );
};
