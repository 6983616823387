import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconShipment: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M10 5C10 3.34315 8.65685 2 7 2C5.34315 2 4 3.34315 4 5C4 6.65685 5.34315 8 7 8C8.65685 8 10 6.65685 10 5ZM10 5H16.5C18.433 5 20 6.567 20 8.5C20 10.433 18.433 12 16.5 12H7.5C5.567 12 4 13.567 4 15.5C4 17.433 5.567 19 7.5 19H19M17 22L19.8427 19.3536C20.0524 19.1583 20.0524 18.8417 19.8427 18.6464L17 16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
