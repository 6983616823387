import React, { useState } from 'react';
import { DateType } from './DateRangeCalendar';
import Filter, { PopoverContentProps } from '../Filter/Filter';
import { formatDate } from '../../../utils/dateUtil';
import DateCalendar from './DateCalendar';
import { InputLabelProps } from '../form/InputLabel';
import { InputHintProps } from '../form/InputHint';
import { BaseTheme } from '../../theme/baseTheme';

export type DatePickerProps = {
  variant?: keyof BaseTheme['select'];
  minDate?: Date;
  onSetDate?: (date: DateType) => void;
  managedDate?: DateType;
  excludeButtons?: boolean;
} & InputLabelProps &
  InputHintProps;

export const DatePicker = ({
  variant,
  minDate,
  onSetDate,
  managedDate,
  label,
  tooltip,
  error,
  showRequired,
  showOptional,
  hint,
  excludeButtons = false,
}: DatePickerProps) => {
  // maintain state within this component so that managedRange can work properly
  const [date, setDate] = useState<DateType>(undefined);
  const usedDate = managedDate || date;

  const toFiltersSummary = (date: DateType) => formatDate(date);
  const onSubmit = (date: DateType) => {
    setDate(date);
    onSetDate && onSetDate(date);
  };
  const onClear = () => {
    onSubmit(undefined);
  };

  const calendarPopover = ({
    onSubmit,
    onCancel,
  }: PopoverContentProps<DateType>) => {
    return (
      <DateCalendar
        initialDate={usedDate}
        onClose={onCancel}
        onSetDate={onSubmit}
        minDate={minDate}
        excludeButtons={excludeButtons}
      />
    );
  };

  return (
    <Filter
      variant={variant}
      placeholder="Select Date"
      showPlaceholderFiltered={false}
      popoverContent={calendarPopover}
      onClear={onClear}
      onSubmit={onSubmit}
      toFiltersSummary={toFiltersSummary}
      label={label}
      tooltip={tooltip}
      error={error}
      showRequired={showRequired}
      showOptional={showOptional}
      hint={hint}
      initialFilter={usedDate}
    />
  );
};
