import React from 'react';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import FormInputContainer from '../common/FormInputContainer';
import PathNumberInput from '../common/PathNumberInput';
import {
  CostItem,
  BloodDrawMethod,
} from '../../../../../../state/customerCostReports/types';
import { updateFormInputs } from '../../../../../../state/customerCostReports';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import styles from '../Form.module.scss';

const BloodDrawCostsSection = () => {
  const dispatch = useDispatch();
  const formInputs = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );
  const initialData = useSelector(
    (state: RootState) =>
      state.customerCostReports.selectedReport?.estimateInputs
  ) as any;

  const getToggleSwitchProps = (methodType: BloodDrawMethod) => ({
    defaultValue: (initialData?.bloodDrawInputs?.methods || []).some(
      (method: CostItem<BloodDrawMethod>) => method.name === methodType
    ),
    onSwitchOff: () => {
      const updatedMethods =
        formInputs.estimateInputs.bloodDrawInputs.methods.filter(
          (method: CostItem<BloodDrawMethod>) => method.name !== methodType
        );
      dispatch(
        updateFormInputs({
          path: 'estimateInputs.bloodDrawInputs.methods',
          value: updatedMethods,
        })
      );
    },
  });

  return (
    <CostCalculatorFormSection
      title={'Blood Draw Costs'}
      subtitle={'Calculate the costs associated with blood sample collection.'}
    >
      <div style={{ display: 'flex', gap: '24px' }}>
        <FormInputContainer
          title="Collection Device"
          toggleSwitch={getToggleSwitchProps(BloodDrawMethod.CollectionDevice)}
          inputs={(disabled: boolean) => (
            <PathNumberInput
              path="estimateInputs.bloodDrawInputs.methods"
              label="Cost per collection"
              showIncrement
              disabled={disabled}
              labelClassName={styles.label}
              type="dollar"
              arrayItemName={BloodDrawMethod.CollectionDevice}
              placeholder="$0"
              minNum={0}
            />
          )}
        />
        <FormInputContainer
          title="Phlebotomist"
          toggleSwitch={getToggleSwitchProps(BloodDrawMethod.Phlebotomist)}
          inputs={(disabled: boolean) => (
            <PathNumberInput
              path="estimateInputs.bloodDrawInputs.methods"
              label="Cost per collection"
              showIncrement
              disabled={disabled}
              labelClassName={styles.label}
              type="dollar"
              arrayItemName={BloodDrawMethod.Phlebotomist}
              placeholder="$0"
              minNum={0}
            />
          )}
        />
      </div>
    </CostCalculatorFormSection>
  );
};

export default BloodDrawCostsSection;
