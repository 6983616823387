import React from 'react';
import { Field } from 'react-final-form';
import Select, { SelectProps } from '../../aurora/components/Select/Select';
import { FieldValidator } from 'final-form';

type SelectFieldProps = Omit<SelectProps, 'variant'> & {
  name: string;
  validate?: FieldValidator<string>;
};
const SelectField = (props: SelectFieldProps) => {
  return (
    <Field
      name={props.name}
      validate={props.validate}
      render={({ input, meta }) => {
        const hasError = meta.touched && meta.error !== undefined;

        return (
          <Select
            variant="form"
            {...input}
            {...props}
            managedValue={input.value}
            onChange={(option) =>
              option === null
                ? input.onChange('')
                : input.onChange(option.value)
            }
            hint={hasError ? meta.error : undefined}
            error={hasError}
          />
        );
      }}
    />
  );
};

export default SelectField;
