import React from 'react';
import Splash from '../../components/layout/Splash';
import LoginForm from './components/LoginForm';
import { IconLogo } from '../../aurora/icons';

const LoginPage = () => (
  <Splash
    card="Log in to your account"
    subtitle={'Welcome back! Please enter your details.'}
    icon={<IconLogo width={116} height={32} />}
    iconType="logo"
  >
    <LoginForm />
  </Splash>
);

export default LoginPage;
