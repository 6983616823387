import React from 'react';
import {
  SegmentEntity,
  SegmentSnapshotEntity,
} from '../../../../../state/segments/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartDataset,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { secondsToDuration } from '../../../../../utils/dateUtil';
import { htmlLegendPlugin } from './Legend';
import { SegmentToSnapshotsMap } from '../../../../../state/segments';
import { convertTemp, mkChartOptions } from './chartOptions';
import { genAdjustedColorHex } from './colorUtils';

interface Props {
  segments: SegmentEntity[];
  snapshotsMap: SegmentToSnapshotsMap;
  tempUnit: 'C' | 'F';
  isArtycViewer: boolean;
  tempThresholdsC?: number[];
  minTempAxisC?: number;
  maxTempAxisC?: number;
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin
);

const SegmentsGraph = ({
  segments,
  snapshotsMap,
  tempUnit,
  isArtycViewer,
  tempThresholdsC,
  minTempAxisC,
  maxTempAxisC,
}: Props) => {
  const options = mkChartOptions(
    'duration',
    tempUnit,
    tempThresholdsC,
    [],
    minTempAxisC,
    maxTempAxisC
  );

  const totalSnapshots = Object.values(snapshotsMap)
    .map((snapshots) => snapshots.length)
    .reduce((sum, curr) => sum + curr, 0);
  const amountReduction = Math.ceil(totalSnapshots / 2500);

  const timestampSet = new Set<number>();
  Object.values(snapshotsMap).forEach((snapshots) => {
    snapshots.forEach((snapshot, i) => {
      if (amountReduction > 1 && i % amountReduction !== 0) {
        return;
      }
      timestampSet.add(snapshot.secSinceStart);
    });
  });
  // x axis is sorted + merged version of all the segments' snapshots' timestamps
  const sortedTimestamps = Array.from(timestampSet).sort((a, b) => a - b);
  const xAxis = sortedTimestamps.map((seconds) =>
    secondsToDuration(seconds, true)
  );

  const datasets: ChartDataset<'line'>[] = [];
  segments.forEach((segment) => {
    const segmentSnapshots = snapshotsMap[segment._id];
    if (segmentSnapshots === undefined || segmentSnapshots.length === 0) {
      return;
    }

    const snapshots: SegmentSnapshotEntity[] = [];
    let timestampIdx = 0;
    let snapshotIdx = 0;

    while (
      snapshotIdx < segmentSnapshots.length &&
      timestampIdx < sortedTimestamps.length
    ) {
      const currTimestamp = sortedTimestamps[timestampIdx];
      const currSnapshot = segmentSnapshots[snapshotIdx];

      // x axis skipped this snapshot, move snapshots ahead
      if (currSnapshot.secSinceStart < currTimestamp) {
        snapshotIdx = snapshotIdx + 1;
        // x axis has another timestamp before this one, fill in with same data and move timestamps ahead
      } else if (currSnapshot.secSinceStart > currTimestamp) {
        snapshots.push(currSnapshot);
        timestampIdx = timestampIdx + 1;
        // x axis is at this snapshot, proceed both
      } else {
        snapshots.push(currSnapshot);
        timestampIdx = timestampIdx + 1;
        snapshotIdx = snapshotIdx + 1;
      }
    }

    const pid = segment.pid;
    datasets.push(
      ...[
        {
          label: `${pid} ${isArtycViewer ? 'Evap' : 'External'} (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            convertTemp(tempUnit)(snapshot.temps.evap)
          ),
          borderColor: genAdjustedColorHex('#7c31c6'),
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 0,
        },
        {
          label: `${pid} ${
            isArtycViewer ? 'Sleeve' : 'Internal'
          } (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            convertTemp(tempUnit)(snapshot.temps.sleeve)
          ),
          borderColor: genAdjustedColorHex('#0c77ff'),
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 0,
        },
        ...(isArtycViewer
          ? [
              {
                label: `${pid} Heat Sink (°${tempUnit})`,
                data: snapshots.map((snapshot) =>
                  convertTemp(tempUnit)(snapshot.temps.hs)
                ),
                borderColor: genAdjustedColorHex('#eea23e'),
                yAxisID: 'temperature',
                pointRadius: 0,
                pointHoverRadius: 0,
              },
              {
                label: `${pid} Battery (%)`,
                data: snapshots.map((snapshot) => snapshot.soc),
                borderColor: genAdjustedColorHex('#5a6473'),
                yAxisID: 'temperature',
                pointRadius: 0,
                pointHoverRadius: 0,
              },
              {
                label: `${pid} Output (V)`,
                data: snapshots.map((snapshot) => parseFloat(snapshot.outputV)),
                borderColor: genAdjustedColorHex('#29a63b'),
                yAxisID: 'voltage',
                pointRadius: 0,
                pointHoverRadius: 0,
              },
            ]
          : []),
      ]
    );
  });

  const data: ChartData<'line'> = {
    labels: xAxis,
    datasets,
  };

  return (
    <>
      <div
        id="legend-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '12px',
        }}
      ></div>
      <Line options={options} data={data} plugins={[htmlLegendPlugin]} />
    </>
  );
};

export default SegmentsGraph;
