import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DeviceEntity, DeviceState } from './types';
import {
  LoadDataAction,
  EntityState,
  SortPayloadAction,
  DataPayload,
} from '../types';
import { structuredFetch } from '../../api/api';
import { AxiosInstance } from 'axios';
import { Auth } from '../auth/types';
import { DeviceType } from './types';
import { isArtyc, isArtycAdmin } from '../auth';
import DevicesApi from '../../api/devicesApi';

const initialState: DeviceState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'serialNumber',
  sortDirection: -1,
  selectedFilters: {},
  deviceTypeList: null,
};

const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices: (
      state,
      { payload }: PayloadAction<DataPayload<DeviceEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        search: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setDeviceTypeList: (state, { payload }: PayloadAction<DeviceType[]>) => {
      state.deviceTypeList = payload;
    },
    setDeviceTypesFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        deviceTypes: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCompanyIdsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        companyIds: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    sortDevices: (state, { payload }: SortPayloadAction<DeviceEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.sortColumn = initialState.sortColumn;
      state.sortDirection = initialState.sortDirection;
      state.currentPage = initialState.currentPage;
    },
  },
});

export const createLoadDevices = (
  showEnvironments: boolean
): LoadDataAction<DeviceEntity> => {
  return async (
    axios: AxiosInstance,
    auth: Auth,
    state: EntityState<DeviceEntity>
  ) => {
    const {
      resultsPerPage,
      currentPage,
      sortColumn,
      sortDirection,
      selectedFilters,
    } = state;

    const fetchSelfHealth = isArtyc(auth);
    const useAllEnvs = isArtycAdmin(auth) && showEnvironments;

    const filters = {
      ...selectedFilters,
      ...(useAllEnvs ? { useAllEnvs } : {}),
    };

    const { entities, totalPages } = await structuredFetch<DeviceEntity>(
      axios,
      auth,
      'devices',
      resultsPerPage,
      currentPage,
      sortColumn,
      sortDirection,
      filters
    );

    let updatedEntities = entities;
    if (fetchSelfHealth && entities.length > 0) {
      const { map: selfHealthTestsMap } =
        await DevicesApi.getLatestSelfHealthTests(
          axios,
          entities.map((device) => device._id)
        );

      updatedEntities = entities.map((device) => ({
        ...device,
        latestSelfHealthTest: selfHealthTestsMap[device._id],
      }));
    }

    return setDevices({ entities: updatedEntities, totalPages });
  };
};

export const {
  setDevices,
  setDeviceTypeList,
  setDeviceTypesFilter,
  setCompanyIdsFilter,
  sortDevices,
  setSearchFilter,
  setCurrentPage,
  resetPage,
} = deviceSlice.actions;

export default deviceSlice.reducer;
