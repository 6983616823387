import { customerCostReportInitialState } from '..';
import { CustomerCostReportEntity, FormInputs } from '../types';

// loop through and populate the section with what's in the report,
// falling back on the default value for it from the state if not filled in
const populateSection = (
  reportSection: object | undefined,
  formInputSection: keyof FormInputs
): any => {
  const mergeDeep = (obj1: any, obj2: any): any => {
    const result: any = {};

    Object.keys(obj2).forEach((key) => {
      result[key] = obj2[key];
    });

    Object.keys(obj1).forEach((key) => {
      if (
        obj1[key] &&
        typeof obj1[key] === 'object' &&
        !Array.isArray(obj1[key])
      ) {
        if (!obj2[key]) {
          result[key] = obj1[key];
        } else {
          result[key] = mergeDeep(obj1[key], obj2[key]);
        }
      } else {
        result[key] = obj1[key];
      }
    });

    return result;
  };
  return mergeDeep(
    reportSection || {},
    customerCostReportInitialState.formInputs[formInputSection]
  );
};

// TODO(cost-calc): test
export const reportToFormInputs = (
  report: CustomerCostReportEntity
): FormInputs => {
  return {
    contactInfoInputs: {
      companyName: report.companyName,
      contactFirstName: report.contactFirstName,
      contactLastName: report.contactLastName,
      contactEmail: report.contactEmail,
    },
    estimateInputs: populateSection(report.estimateInputs, 'estimateInputs'),
    dynamicInputs: populateSection(report.dynamicInputs, 'dynamicInputs'),
    artycComparisons: report.artycComparisons,
    industryComparisons: report.industryComparisons,
    considerations:
      report.considerations ||
      customerCostReportInitialState.formInputs.considerations,
  };
};
