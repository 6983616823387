import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { isArtyc, selectAuth } from '../../../state/auth';
import styles from './CompanyFilter.module.scss';
import CompanyPopover from './CompanyPopover';
import Filter, {
  PopoverContentProps,
} from '../../../aurora/components/Filter/Filter';

interface CompanyFilterProps {
  setCompanyIdsAction: (companyIds: string[] | undefined) => void;
  filteredCompanyIds: string[] | undefined;
}

const CompanyFilter = ({
  setCompanyIdsAction,
  filteredCompanyIds = [],
}: CompanyFilterProps) => {
  const auth = useSelector(selectAuth);
  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;
  const isArtycUser = isArtyc(auth);

  const onClear = () => {
    if (filteredCompanyIds.length !== 0) {
      setCompanyIdsAction(undefined);
    }
  };

  const onFilter = (companyIds: string[]) => setCompanyIdsAction(companyIds);

  const toFiltersSummary = (companyIds: string[]) =>
    companyIds.length.toString();

  if (!isArtycUser) {
    return null;
  }

  const popover = ({
    onSubmit,
    onCancel,
    onClear,
  }: PopoverContentProps<string[]>) => (
    <CompanyPopover
      onSubmit={onSubmit}
      onCancel={onCancel}
      onClear={onClear}
      companies={companies}
      filteredCompanyIds={filteredCompanyIds}
    />
  );

  return (
    <Filter
      placeholder="Company"
      popoverContent={popover}
      onSubmit={onFilter}
      onClear={onClear}
      toFiltersSummary={toFiltersSummary}
      className={styles.companyFilter}
    />
  );
};

export default CompanyFilter;
