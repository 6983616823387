import React, { PropsWithChildren, ReactNode } from 'react';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';
import { Label } from '../../../../aurora/typography/Label/Label';
import styles from './InfoCard.module.scss';

interface Props {
  title: string;
  headerAction?: ReactNode;
  accordion?: boolean;
}
const InfoCard = ({
  title,
  headerAction,
  accordion,
  children,
}: PropsWithChildren<Props>) => {
  if (accordion) {
    return (
      <Accordion
        sections={[{ header: title, body: children }]}
        className={styles.accordion}
      />
    );
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <Label size="sm" color="strong900">
          {title}
        </Label>
        {headerAction}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default InfoCard;
