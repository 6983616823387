import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCube: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase {...props}>
    <Path
      d="M3.33984 16.7114V14.5V7.28872C3.33984 7.11009 3.43514 6.94502 3.58984 6.8557L11.7501 2.14438C11.9048 2.05506 12.0954 2.05506 12.2501 2.14438L20.4104 6.8557C20.5651 6.94502 20.6604 7.11008 20.6604 7.28872V16.7114C20.6604 16.89 20.5651 17.0551 20.4104 17.1444L12.2501 21.8557C12.0954 21.945 11.9048 21.945 11.7501 21.8557L3.58984 17.1444C3.43514 17.0551 3.33984 16.89 3.33984 16.7114Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
