// what we put into the access token
export interface UserInfo {
  // pid of the role
  role: string;
  firstName?: string;
  companyId: string;
  _id: string;
}

export type Auth = UserInfo & { accessToken: string };

export interface Role {
  name: string;
  pid: string;
}

export const ROLES: { [role: string]: Role } = {
  ARTYC_ADMIN: {
    name: 'Artyc Admin',
    pid: '13b3c08c-aa46-4779-aa40-94b92681cd78',
  },
  ARTYC_EMPLOYEE: {
    name: 'Artyc Employee',
    pid: '05da77d5-d489-405b-813c-b0754cd0ecdf',
  },
  COMPANY_ADMIN: {
    name: 'Company Admin',
    pid: 'f603928e-effa-4cf4-8e76-358b41d68534',
  },
  COMPANY_EMPLOYEE: {
    name: 'Company Employee',
    pid: 'ad4d463e-af60-44b9-a648-fe6e47cf5461',
  },
};
