import React from 'react';
import TextField from '../../../../components/forms/TextField';
import SelectField from '../../../../components/forms/SelectField';
import ShippingProfileSelect from './ShippingProfileSelect';
import { required } from '../../../../components/forms/validators';
import formStyles from '../../../../components/forms/Form.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { SelectOptionType } from '../../../../aurora/components/Select/Select';
import AddressSection from './AddressSection';
import { companyOptions } from './formOptions';
import ExpectedStartDate from './ExpectedStartDate';

interface Props {
  isArtycViewer: boolean;
  shipperOptions: SelectOptionType[];
}
const CreateJourneyForm = ({ isArtycViewer, shipperOptions }: Props) => {
  const companies = useSelector((state: RootState) => state.companies.data);

  return (
    <div className={formStyles.form}>
      <TextField
        name="poNumber"
        label="P.O Number"
        showOptional
        tooltip="The purchase order number associated with your sales order for this journey"
      />
      <TextField
        name="customId"
        label="Custom ID"
        showOptional
        tooltip="Enter a unique identifier specific to your internal tracking needs. Could be a patient ID, a project ID, etc"
      />
      {isArtycViewer ? (
        <>
          <SelectField
            name="company"
            options={companyOptions(companies)}
            label="Company"
            validate={required}
            clearable
          />
          <SelectField
            name="device"
            options={shipperOptions}
            label="Shipper"
            showOptional
            clearable
          />
        </>
      ) : null}
      <ShippingProfileSelect isArtycViewer={isArtycViewer} />
      <ExpectedStartDate isArtycViewer={isArtycViewer} />
      <AddressSection
        heading="User Address"
        isArtycViewer={isArtycViewer}
        shipmentPrefix="user"
      />
      <AddressSection
        heading="Lab Address"
        isArtycViewer={isArtycViewer}
        shipmentPrefix="lab"
        isLab
      />
    </div>
  );
};

export default CreateJourneyForm;
