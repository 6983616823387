import React from 'react';
import { DeviceEntity } from '../../../state/devices/types';
import Slideout from '../../../aurora/components/Slideout/Slideout';
import DeviceInfoCard from './DeviceInfoCard';

interface Props {
  device: DeviceEntity | undefined;
  open: boolean;
  closeSlideout: () => void;
}
const DeviceSlideout = ({ device, open, closeSlideout }: Props) => {
  return (
    <Slideout open={open} onClose={closeSlideout} title="Shipper Information">
      <DeviceInfoCard device={device} />
    </Slideout>
  );
};

export default DeviceSlideout;
